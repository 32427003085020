import { useParams } from "react-router-dom"
import { css } from "@emotion/react"
import { useGeneralPhoneOrderTermsAndConditions, usePhoneOrder } from "./client"
import { ClientSideLocalize } from "../../../packages/localization/client-side/Dictionary"
import { Localized } from "../../../packages/localization/Localized"
import { MarkdownView } from "./ui/components/base/MarkdownView"
import { useRedoitTheme } from "./ui/theme"

const styles = `
@page {
    /* General print page margin */
    margin: 20mm;
}

@media print {
    body {
        font-size: 12px;
    }

    h1 {
        font-size: 2em;
    }
    
    h2 {
        margin-top: 1.6em;
        font-size: 1.6em;
    }
    
    h3 {
        font-size: 1.4em;
    }
}

@media screen {
    body {
        margin: 20px; /* Set margin for screen */
        max-width: 800px;
        font-size: 16px;
    }
}
    h1 {
        font-size: 2em;
        margin-top: 32px;
    }

    h2 {
        margin-top: 24px;
        font-size: 2em;
    }
`

export function TermsAndConditions() {
    const orderId = useParams().id
    const { helpers } = useRedoitTheme()
    const { responsiveHeadingCss } = helpers

    const order = usePhoneOrder((orderId as any) ?? null)
    if (!order.data) return null
    const {
        user,
        model,
        colorDisplayName,
        storageDisplayName,
        gradeDisplayName,
        invoiceDateOfMonth,
        shippingAddress,
        shippingSlot,
        rentalPeriods,
        rentalPeriod,
    } = order.data

    return (
        <div
            css={{
                h1: css(responsiveHeadingCss(1), { marginBottom: ".4em", marginTop: ".4em" }),
                h2: css(responsiveHeadingCss(2), { marginBottom: ".4em", marginTop: ".4em" }),
                h3: css(responsiveHeadingCss(3), { marginBottom: ".4em", marginTop: ".4em" }),
                h4: css(responsiveHeadingCss(4), { marginBottom: ".4em", marginTop: ".4em" }),
            }}
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <style>{styles}</style>
            <div style={{ maxWidth: 800 }}>
                <div style={{ pageBreakAfter: "always", marginBottom: 64 }}>
                    <img src="/static/redoit-black.svg" style={{ width: 200, marginTop: 64 }} />
                    <h2>Ordreskjema</h2>
                    <h3 style={{ fontWeight: 600 }}>Kontaktinformasjon</h3>

                    <Row label="Navn" value={`${user.givenName} ${user.familyName}`} />
                    <Row label="Telefonnummer" value={user.phoneNumber?.valueOf()} />
                    <Row label="E-post" value={user.email?.valueOf()} />
                    <Row
                        label="Adresse"
                        value={`${user.address?.street}, ${user.address?.zip} ${user.address?.city}`}
                    />

                    <h3 style={{ fontWeight: 600 }}>Beskrivelse av Utstyret</h3>
                    <Row label="Modell" value={model.name} />
                    <Row label="Farge" value={colorDisplayName} />
                    <Row label="Lagring" value={storageDisplayName} />
                    <Row label="Tilstandsgrad" value={gradeDisplayName} />

                    <h3 style={{ fontWeight: 600 }}>Pris og varighet</h3>
                    <Row
                        label="Månedsbeløp"
                        value={`kr ${rentalPeriods[rentalPeriods.length - 1]?.monthlyPrice.itemsTotal.majorUnits}`}
                    />
                    <Row label="Faktureringsdato" value={`Månedlig den ${invoiceDateOfMonth}.`} />
                    <Row label="Bindingstid" value={`${rentalPeriod.months} måneder`} />

                    <h3 style={{ fontWeight: 600 }}>Levering av Utstyret</h3>
                    {/** If PostalShipping is selected, we don't know the shipping date when this document is created. */}
                    {shippingSlot ? (
                        <Row label="Leveringsdato" value={shippingSlot.startDate.valueOf()} />
                    ) : null}
                    <Row
                        label="Leveringsadresse"
                        value={`${shippingAddress.street}, ${shippingAddress.zip} ${shippingAddress.city}`}
                    />
                </div>

                <GeneralTermsAndConditions />
            </div>
        </div>
    )
}

export function ProtectionPlanTermsAndConditions() {
    const r = useGeneralPhoneOrderTermsAndConditions()
    if (!r.data) return null

    return (
        <div style={{ maxWidth: 800 }}>
            <style>{styles}</style>
            <MarkdownView value={r.data.insurance.content} />
        </div>
    )
}

export function GeneralTermsAndConditions() {
    const r = useGeneralPhoneOrderTermsAndConditions()
    if (!r.data) return null

    return (
        <div style={{ maxWidth: 800 }}>
            <style>{styles}</style>
            <MarkdownView value={r.data.phoneOrder.content} />
            <div style={{ pageBreakAfter: "always" }} />
            <MarkdownView value={r.data.insurance.content} />
        </div>
    )
}

function Row({ label, value }: { label: string; value: string | Localized<string> | undefined }) {
    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-strt" }}>
            <div style={{ fontWeight: "bold", marginRight: 16, width: 200 }}>{label}: </div>
            <div>{typeof value === "object" ? ClientSideLocalize(value) : value}</div>
        </div>
    )
}
