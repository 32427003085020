import { Color, redoitTheme } from "../theme"

const lightTextColorBackgrounds: Color[] = [
    "gray350",
    "gray400",
    "gray500",
    "forest",
    "brand",
    "brandDark",
    "vippsOrange",
    "sky",
    "sand",
    "warning",
    "error",
]

const { colors } = redoitTheme

export function getTextColorForBackgroundColor(backgroundColor: Color): Color {
    if (lightTextColorBackgrounds.includes(backgroundColor)) return "grayWhite"
    return "gray500"
}

export { Color, colors }
