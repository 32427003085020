import { useCallback, useRef, useState, MouseEvent } from "react"
import { motion, LayoutGroup } from "framer-motion"
import { css } from "@emotion/react"
import { File, FileToUrl, Image, Markdown, NonNegativeInteger } from "../../../../../../reactor"
import { Component } from "../../../../../../packages/editing/Component"
import { server } from "../../../../../../server"
import { Flex } from "../base/Flex"
import { Icon } from "../visual/Icon"
import { responsiveBorderRadius, responsiveCss, scaleValue } from "../../helpers/css"
import { defaultTransition, getTransition, springAnimations } from "../../constants/animation"
import { Text } from "../typography/Text"
import { MarkdownView } from "../base/MarkdownView"
import { useRedoitTheme } from "../../theme"

/**
 * Renders a card with an image or illustration that can present more info without taking the
 * user to a new context, by presenting the passed text in an overlay when the bottom part
 * with a + icon is pressed.
 */
export function WithOverlayCard(props: {
    image?: Image
    title: string
    text: Markdown
    readMoreAriaPrefix: string
    number?: NonNegativeInteger
    runningAnimation?: boolean
}) {
    const { colors } = useRedoitTheme()
    const [isOpen, setIsOpen] = useState(false)
    const mouseDownX = useRef<number>(0)
    const mouseUpX = useRef<number>(0)
    const handleMouseDown = useCallback((e: MouseEvent<HTMLDivElement>) => {
        mouseDownX.current = e.clientX
    }, [])
    const handleMouseUp = useCallback((e: MouseEvent<HTMLDivElement>) => {
        mouseUpX.current = e.clientX
    }, [])

    const toggleOpen = useCallback(() => {
        // Don't open card if the click was a drag event (meaning cursor was moved between
        // mouse down and up events). May want to add some move tolerance.
        if (mouseDownX.current === mouseUpX.current) {
            setIsOpen(!isOpen)
        }
        mouseDownX.current = 0
        mouseUpX.current = 0
    }, [isOpen])

    return (
        <LayoutGroup>
            <motion.div
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                style={{ overflow: "hidden" }}
                initial={{ paddingLeft: "0px", paddingRight: "0px" }}
                animate={isOpen ? "open" : "closed"}
                variants={{
                    open: { paddingLeft: "var(--spacing)", paddingRight: "var(--spacing)" },
                    closed: { paddingLeft: "0px", paddingRight: "0px" },
                }}
                transition={springAnimations["200"]}
                css={css(
                    {
                        "--top-height": "236px",
                        "--bottom-height": "94px",
                        "--spacing": "20px",
                        "--height": "330px",
                        minWidth: 240,
                        width: "100%",
                        height: "var(--height)",
                        position: "relative",
                    },
                    responsiveCss("min", "md", {
                        minWidth: 264,
                        width: "100%",
                        "--top-height": "284px",
                        "--bottom-height": "94px",
                        "--height": "378px",
                    }),
                    responsiveCss("min", "xl", {
                        "--top-height": "326px",
                        "--bottom-height": "98px",
                        "--spacing": "21px",
                        "--height": "424px",
                    })
                )}
                {...(isOpen
                    ? {}
                    : {
                          onClick: toggleOpen,
                          role: "button",
                          "aria-label": `${props.readMoreAriaPrefix} ${props.title}`,
                      })}
            >
                <Flex
                    direction="column"
                    backgroundColor="gray100"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="md"
                    css={css({
                        position: "relative",
                        height: "var(--top-height)",
                        overflow: "hidden",
                    })}
                >
                    {props.number ? (
                        <Flex
                            backgroundColor="grayWhite"
                            alignItems="center"
                            justifyContent="center"
                            css={css(
                                {
                                    position: "absolute",
                                    top: "var(--spacing)",
                                    left: "var(--spacing)",
                                    borderRadius: 24,
                                    height: 36,
                                    width: 36,
                                },
                                responsiveCss("min", "md", {
                                    height: scaleValue(48),
                                    width: scaleValue(48),
                                })
                            )}
                        >
                            <Text variant="heading" level={4}>
                                {props.number.valueOf()}
                            </Text>
                        </Flex>
                    ) : null}
                    {props.image ? (
                        <img
                            draggable="false"
                            src={FileToUrl(props.image as any as File)}
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                        />
                    ) : null}
                </Flex>
                <motion.div
                    animate={isOpen ? "open" : "closed"}
                    variants={{
                        open: { height: `calc(100% - ${scaleValue(20)}` },
                        closed: {
                            height: "var(--bottom-height)",
                        },
                    }}
                    transition={springAnimations["200"]}
                    onClick={isOpen ? toggleOpen : undefined}
                    css={css(
                        {
                            backgroundColor: colors.gray500,
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            cursor: "pointer",
                            bottom: 0,
                            left: 0,
                            right: 0,
                        },
                        responsiveBorderRadius("md")
                    )}
                >
                    <div
                        css={css({
                            padding: "var(--spacing)",
                            height: "var(--bottom-height)",
                        })}
                    >
                        <Flex
                            color="grayWhite"
                            justifyContent="space-between"
                            alignItems={isOpen ? "flex-start" : "center"}
                            flex={`0 0 ${isOpen ? "auto" : 60}px`}
                            css={css({ height: isOpen ? "auto" : "100%" })}
                        >
                            <Text variant="heading" level={["fixed", "lg", 4]} lineClamp={3}>
                                {props.title}
                            </Text>
                            <Icon
                                icon="plus"
                                margin={{ left: 12 }}
                                style={{
                                    flex: "0 0 auto",
                                    transform: `rotate(${isOpen ? 45 : 0}deg)`,
                                    transition: defaultTransition,
                                }}
                            />
                        </Flex>
                        <Flex
                            css={css({
                                paddingTop: "var(--spacing)",
                                opacity: isOpen ? 0.8 : 0,
                                transition: getTransition(["opacity"], 200),
                            })}
                        >
                            <Text
                                variant="body"
                                size={["fixed", "lg", "md"]}
                                color="grayWhite"
                                as="div"
                            >
                                <MarkdownView value={props.text} />
                            </Text>
                        </Flex>
                    </div>
                </motion.div>
            </motion.div>
        </LayoutGroup>
    )
}

Component(WithOverlayCard, {
    name: "WithOverlayCard",
    gallery: {
        path: "Cards/WithOverlayCard",
        items: [
            {
                title: "Card with overlay",
                variants: [
                    {
                        props: {
                            number: NonNegativeInteger(1),
                            title: "Velg hvilken telefon du vil leie",
                            text: Markdown(
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                            ),
                            image: `${server()}/static/redoit/how-it-works-card-illustration-1.svg` as any as Image,
                            readMoreAriaPrefix: `Read more about`,
                            runningAnimation: true,
                        },
                    },
                    {
                        props: {
                            number: NonNegativeInteger(2),
                            title: "Velg hvilken telefon du vil leie",
                            text: Markdown(
                                "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                            ),
                            image: `${server()}/static/redoit/how-it-works-card-illustration-2.svg` as any as Image,
                            readMoreAriaPrefix: `Read more about`,
                        },
                    },
                    {
                        props: {
                            number: NonNegativeInteger(3),
                            title: "Velg hvilken telefon du vil leie",
                            text: Markdown(
                                "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                            ),
                            image: `${server()}/static/redoit/how-it-works-card-illustration-3.svg` as any as Image,
                            readMoreAriaPrefix: `Read more about`,
                        },
                    },
                ],
            },
        ],
    },
})
