import { CSSProperties, ReactNode } from "react"
import { css } from "@emotion/react"
import {
    BodySizeGeneric,
    ColorGeneric,
    FixedVariantGeneric,
    ResponsiveVariantGeneric,
    SpacingGeneric,
    ThemeAny,
    useTheme,
    ScreenSizeGeneric,
} from "../../theme"
import { resetStyles, underline } from "../../helpers/css"

export function createBody<T extends ThemeAny>() {
    return function Body(props: {
        color?: ColorGeneric<T>

        size:
            | ResponsiveVariantGeneric<ScreenSizeGeneric<T>, BodySizeGeneric<T>>
            | FixedVariantGeneric<ScreenSizeGeneric<T>, BodySizeGeneric<T>>

        /**
         * Specify margin.
         */
        margin?: SpacingGeneric<T>

        /**
         * Add underline.
         */
        underline?: boolean

        /**
         * Any custom styling.
         *
         * @reflection any
         */
        style?: CSSProperties

        /**
         * Set an opacity for this text.
         */
        opacity?: number

        /**
         * Truncate overflowing text. Forces nowrap and adds ellipsis.
         */
        truncate?: boolean

        /**
         * @reflection any
         */
        children: ReactNode
    }) {
        const { colors, helpers } = useTheme<T>()
        const { responsiveBodyCss } = helpers
        return (
            <p
                css={css(
                    resetStyles("p"),
                    { "& a": underline },
                    responsiveBodyCss(props.size),
                    props.color && {
                        color: colors[props.color],
                    },
                    props.truncate && {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                    },
                    props.margin && helpers.spacingToCss("margin", props.margin),
                    props.underline && underline,
                    typeof props.opacity === "number" && { opacity: props.opacity }
                )}
                style={{
                    ...(props.style || {}),
                }}
            >
                {props.children}
            </p>
        )
    }
}
