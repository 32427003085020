import { createContext, CSSProperties, ReactNode, useContext, useEffect, useState } from "react"
import { css, CSSObject, SerializedStyles } from "@emotion/react"

/**
 * Defines the set of keywords used to specify the direction or condition for limiting a
 * media query based on the viewport size.
 */
const limitTypes = ["min", "max", "only"] as const
export type LimitType = (typeof limitTypes)[number]

/**
 * A media query for when to apply variant V.
 */
export type VariantMediaQueryGeneric<ScreenSize extends string, V extends string> = [
    LimitType,
    ScreenSize,
    V,
]

/**
 * A fixed or responsive value, usually in combination with a style property.
 * E.g. `borderWidth: 1, ['min', 'md', 2]` which means base border width should be 1,
 * and then for md size screens and up it should be 2.
 */
export type ResponsiveStyleValueGeneric<ScreenSize extends string, V = number | string> =
    | V
    | [V, ...[LimitType, ScreenSize, V][]]
    | [LimitType, ScreenSize, V][]

/**
 * Record of styles for one or more screen sizes.
 */
type ResponsiveStylesGeneric<ScreenSize extends string> = Partial<Record<ScreenSize, CSSObject>>

/**
 * A set of styles for responsive variants, headings or body text styles, where each size is
 * considered a variant.
 */
type ResponsiveVariantStylesGeneric<ScreenSize extends string, V extends string> = Partial<
    Record<V, ResponsiveStylesGeneric<ScreenSize>>
>

// Helper types for props or functions that supports values with optional media queries.

/**
 * Type helper for responsive properties that takes variant values. Accepts both a single
 * variant which is sufficient for most use cases, but also an array with a base variant for
 * smallest screen size, and media queries for when to apply other variants.
 * E.g. `['sm', ['min', 'md', 'xs']]` which would render the small variant on the smallest screens
 * and then switch to the xs variant from screen size md.
 */
export type ResponsiveVariantGeneric<ScreenSize extends string, V extends string> =
    | V
    | [V, ...VariantMediaQueryGeneric<ScreenSize, V>[]]
    | VariantMediaQueryGeneric<ScreenSize, V>[]

/**
 * Type helper for fixed variant styles for a given screen size. E.g. `['fixed', 'sm', 'lg']`
 * would render the sm screen size version of the lg variant on _all screen sizes_.
 */
export type FixedVariantGeneric<ScreenSize extends string, V extends string> = [
    "fixed",
    ScreenSize,
    V,
]

// Types for records of non responsive values and variants like colors and button variants.

/**
 * Record of styles for one or more variants. A variant can be buttons of different styles or
 * a heading or body text size.
 */
type VariantStyles<V extends string> = Record<V, CSSObject>

/**
 * A record of keys and style values, e.g. colors.
 */
type KeyValues<K extends string> = Record<K, string | number>

/**
 * A media query.
 */
export type MediaQueryGeneric<T extends ThemeAny> = [LimitType, ScreenSizeGeneric<T>]

/**
 * Responsive styles object. Top level keys are referring to the screen size from which the styles
 * should be applied. Base styles that should apply to all screen sizes are defined on the smallest
 * screen size.
 *
 * Example code for increasing from a base font size of 16px to 20px on md size screens:
 * `{ sm: { fontFamily: "Plus Jakarta Sans", fontSize: "16px" }, md: { fontSize: "20px" } }`
 */
export type ResponsiveStylesObject<ScreenSize extends string> = Partial<
    Record<ScreenSize, CSSObject>
>

/**
 * Responsive values. Top level keys are referring to the screen size the value should be applied to.
 * As with the responsive styles objects, the value for the smallest screen is used as a base.
 *
 * Example code for increasing size frowm 16px to 20px on md size screens:
 * `{ sm: "16px", md: "20px" }`
 */
export type ResponsiveValues<ScreenSize extends string, V> = Partial<Record<ScreenSize, V>>

/**
 * Heading levels are restricted to 1-6.
 */
type AllowedHeadingLevel = "1" | "2" | "3" | "4" | "5" | "6"

/**
 * Type for theme config. When defining sizes, any string can be used. All config properties are
 * required to be set, even though a design system may not utilize them, for TypeScript/IntelliSense
 * to work as desired.
 */
type ThemeConfig<
    ScreenSize extends string,
    Color extends string,
    HeadingLevel extends AllowedHeadingLevel,
    BodySize extends string,
    TextVariant extends string,
    TextVariantSize extends string,
    BorderRadius extends string,
    ButtonSize extends string,
    ButtonVariant extends string,
    IconName extends string,
    IconSize extends number,
    SpacingSize extends string,
> = {
    /**
     * The screen sizes and breakpoints to be used in this theme.
     */
    screenSizes: Record<ScreenSize, number>

    /**
     * The colors available in this theme. Structured as an object with color name and
     * color code (hex or other).
     */
    colors: Record<Color, string>

    /**
     * The heading levels available in this theme. Structured as responsive styles object for each
     * heading level.
     */
    headingLevels: Record<HeadingLevel, ResponsiveStylesObject<ScreenSize>>

    /**
     * The sizes of body text available in this theme. Structured as a responsive styles object
     * for each size.
     */
    bodySizes: Record<BodySize, ResponsiveStylesObject<ScreenSize>>

    /**
     * The other text variants available in the theme in addition to headings and body text.
     * Each text variant are structured as responsive styles object
     */
    textVariants: Record<
        TextVariant,
        Partial<Record<TextVariantSize, ResponsiveStylesObject<ScreenSize>>>
    >

    /**
     * The border radiuses in the design system. Provided as responsive values,
     * either numbers or strings.
     */
    borderRadiuses: Record<BorderRadius, ResponsiveValues<ScreenSize, number | string>>

    /**
     * The button sizes in the design system. Structured as responsive styles object for each
     * button size.
     */
    buttonSizes: Record<ButtonSize, ResponsiveStylesObject<ScreenSize>>

    /**
     * The button variants in the design system. Structured as responsive styles object for each
     * button variant.
     */
    buttonVariants: Record<ButtonVariant, CSSObject>

    /**
     * The icons in the design system. Provided as svg paths elements only, not the main
     * SVG element. The paths should be based on a 24x24 px svg.
     */
    icons: Record<IconName, ReactNode>

    /**
     * The allowed sizes to render icons as. Provided as an array of numbers.
     */
    iconSizes: IconSize[]

    /**
     * The spacing sizes in the design system. Structured as responsive values for each
     * spacing size.
     */
    spacingSizes: Record<SpacingSize, ResponsiveValues<ScreenSize, number | string>>
}

export type Theme<
    ScreenSize extends string = never,
    Color extends string = never,
    TextVariant extends string = never,
    TextVariantSize extends string = never,
    BodySize extends string = never,
    BorderRadius extends string = never,
    ButtonSize extends string = never,
    ButtonVariant extends string = never,
    HeadingLevel extends AllowedHeadingLevel = never,
    IconName extends string = never,
    IconSize extends number = never,
    SpacingSize extends string = never,
    Spacing =
        | number
        | string
        | SpacingSize
        | Partial<Record<Axis | Side, number | string | SpacingSize>>,
> = ThemeConfig<
    ScreenSize,
    Color,
    HeadingLevel,
    BodySize,
    TextVariant,
    TextVariantSize,
    BorderRadius,
    ButtonSize,
    ButtonVariant,
    IconName,
    IconSize,
    SpacingSize
> & {
    responsiveCss: (
        limitType: LimitType,
        screenSize: ScreenSize,
        s: CSSObject | SerializedStyles
    ) => SerializedStyles | undefined
    helpers: {
        mediaQuery: (limitType: LimitType, screenSize: ScreenSize) => string
        useMediaQuery: (limitType: LimitType, screenSize: ScreenSize) => boolean
        fixedVariantCss: <V extends string>(
            variants: ResponsiveVariantStylesGeneric<ScreenSize, V>,
            variant: FixedVariantGeneric<ScreenSize, V>
        ) => SerializedStyles | undefined
        responsiveCss: (
            limitType: LimitType,
            screenSize: ScreenSize,
            s: CSSObject | SerializedStyles
        ) => SerializedStyles | undefined
        responsiveVariantsCss: <V extends string>(
            variants: ResponsiveVariantStylesGeneric<ScreenSize, V>,
            variant: ResponsiveVariantGeneric<ScreenSize, V>
        ) => SerializedStyles | undefined
        responsiveVariantToCss: (variant: ResponsiveStylesGeneric<ScreenSize>) => SerializedStyles
        responsiveHidden: (mq: [LimitType, ScreenSize]) => SerializedStyles
        responsivePropCss: <V extends string>(
            values: KeyValues<V>,
            prop: string,
            responsiveValues: ResponsiveVariantGeneric<ScreenSize, V>
        ) => SerializedStyles | undefined
        responsiveValueCss: (
            prop: string,
            responsiveValues: ResponsiveStyleValueGeneric<ScreenSize>
        ) => SerializedStyles | undefined
        responsiveSpacing: (
            variant: Spacing,
            type: string | string[]
        ) => SerializedStyles | undefined
        responsiveBorderRadius: (variant: BorderRadius) => SerializedStyles | undefined
        responsiveHeadingLevel: (level: HeadingLevel) => SerializedStyles | undefined
        responsiveHeadingCss: (
            size:
                | ResponsiveVariantGeneric<ScreenSize, HeadingLevel>
                | FixedVariantGeneric<ScreenSize, HeadingLevel>
        ) => SerializedStyles | undefined
        responsiveBodyCss: (
            size:
                | ResponsiveVariantGeneric<ScreenSize, BodySize>
                | FixedVariantGeneric<ScreenSize, BodySize>
        ) => SerializedStyles | undefined
        spacingToCss: (type: "margin" | "padding", spacing: Spacing) => SerializedStyles | undefined
        variantCss: <V extends string>(
            variants: VariantStyles<V>,
            variant:
                | VariantMediaQueryGeneric<ScreenSize, V>
                | ResponsiveVariantGeneric<ScreenSize, V>
        ) => SerializedStyles | undefined
        textCss: (
            variant: TextVariant,
            size:
                | ResponsiveVariantGeneric<ScreenSize, TextVariantSize>
                | FixedVariantGeneric<ScreenSize, TextVariantSize>
        ) => SerializedStyles | undefined
    }
}

/**
 * Used as a "base" to extend from for functions with Theme as a generic type argument.
 */
export type ThemeAny = Theme<any, any, any, any, any, any, any, any, any, any, any, any>

/**
 * Generic types for the theme properties.
 */
export type BorderRadiusGeneric<T extends ThemeAny> = Extract<keyof T["borderRadiuses"], string>
export type BodySizeGeneric<T extends ThemeAny> = Extract<keyof T["bodySizes"], string>
// Heading levels are changed from strings (which need to be provided as to the theme system to be
// valid object keys) to numbers, so to all props and functions they are provided as numbers.
export type HeadingLevelGeneric<T extends ThemeAny> = keyof T["headingLevels"] extends string
    ? Extract<keyof T["headingLevels"], string> extends infer S
        ? S extends `${infer N extends number}`
            ? N
            : never
        : never
    : never
export type ScreenSizeGeneric<T extends ThemeAny> = Extract<keyof T["screenSizes"], string>
export type ButtonVariantGeneric<T extends ThemeAny> = keyof T["buttonVariants"] & string
export type ButtonSizeGeneric<T extends ThemeAny> = keyof T["buttonSizes"] & string
export type TextVariantGeneric<T extends ThemeAny> = Extract<keyof T["textVariants"], string>
/**
 * TODO: This needs to be changed, to fix the issue of available sizes for different text variants
 * being merged.
 */
export type TextVariantSizeGeneric<
    T extends ThemeAny,
    V extends TextVariantGeneric<T>,
> = keyof T["textVariants"][V] & string
export type ColorGeneric<T extends ThemeAny> = Extract<keyof T["colors"], string>
export type IconNameGeneric<T extends ThemeAny> = keyof T["icons"] & string
/**
 * Icon sizes can be an array of either numbers or strings. If no values are provided, it is
 * inferred as `never`.
 */
export type IconSizeGeneric<T extends ThemeAny> = T["iconSizes"] extends (infer U)[]
    ? U extends number | string
        ? U
        : never
    : never
export type SpacingSizeGeneric<T extends ThemeAny> = keyof T["spacingSizes"] & string
type Axis = "x" | "y"
type Side = "top" | "right" | "bottom" | "left"
export type SpacingGeneric<T extends ThemeAny> =
    | number
    | string
    | SpacingSizeGeneric<T>
    | Partial<Record<Axis | Side, number | string | SpacingSizeGeneric<T>>>

/**
 * Creates a new theme. See ThemeConfig type docs for details.
 */
export function createTheme<
    ScreenSize extends string = never,
    Color extends string = never,
    TextVariant extends string = never,
    TextVariantSize extends string = never,
    BodySize extends string = never,
    BorderRadius extends string = never,
    ButtonSize extends string = never,
    ButtonVariant extends string = never,
    HeadingLevel extends AllowedHeadingLevel = never,
    IconName extends string = never,
    IconSize extends number = never,
    SpacingSize extends string = never,
>(
    config: ThemeConfig<
        ScreenSize,
        Color,
        HeadingLevel,
        BodySize,
        TextVariant,
        TextVariantSize,
        BorderRadius,
        ButtonSize,
        ButtonVariant,
        IconName,
        IconSize,
        SpacingSize
    >
) {
    /**
     * A media query.
     */
    type MediaQuery = [LimitType, ScreenSize]

    /**
     * A media query for when to apply variant V.
     */
    type VariantMediaQuery<V extends string> = [LimitType, ScreenSize, V]

    // Helper types for props or functions that supports values with optional media queries.

    /**
     * Type helper for responsive properties that takes variant values. Accepts both a single
     * variant which is sufficient for most use cases, but also an array with a base variant for
     * smallest screen size, and media queries for when to apply other variants.
     * E.g. `['sm', ['min', 'md', 'xs']]` which would render the small variant on the smallest screens
     * and then switch to the xs variant from screen size md.
     */
    type ResponsiveVariant<V extends string> =
        | V
        | [V, ...VariantMediaQuery<V>[]]
        | VariantMediaQuery<V>[]

    /**
     * Type helper for fixed variant styles for a given screen size. E.g. `['fixed', 'sm', 'lg']`
     * would render the sm screen size version of the lg variant on _all screen sizes_.
     */
    type FixedVariant<V extends string> = ["fixed", ScreenSize, V]

    type ResponsiveStyleValue = ResponsiveStyleValueGeneric<ScreenSize>

    // Types for records of responsive styles, variants and values.

    /**
     * Record of styles for one or more screen sizes.
     */
    type ResponsiveStyles = ResponsiveStylesGeneric<ScreenSize>

    /**
     * A set of styles for responsive variants, headings or body text styles, where each size is
     * considered a variant.
     */
    type ResponsiveVariantStyles<V extends string> = ResponsiveVariantStylesGeneric<ScreenSize, V>

    const { screenSizes, spacingSizes, headingLevels, borderRadiuses } = config

    /**
     * Helper function that returns a media query for a given screen size and limit type.
     *
     * @param limitType The type of limit to apply
     * @param screenSize The screen size to apply the styles to
     * @returns A media query string
     */
    function mediaQuery(limitType: LimitType, screenSize: ScreenSize): string {
        switch (limitType) {
            case "min":
                return `@media (min-width: ${screenSizes[screenSize]}px)`
            case "max": {
                const sizesKeysArr = Object.keys(screenSizes) as ScreenSize[]
                const sizeIndex = sizesKeysArr.indexOf(screenSize)

                if (sizeIndex + 1 === sizesKeysArr.length) {
                    return "@media"
                }

                const nextSize = sizesKeysArr[sizeIndex + 1]

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!nextSize) {
                    return "@media"
                }

                return `@media (max-width: ${screenSizes[nextSize] - 1}px)`
            }
            case "only": {
                const sizesKeysArr = Object.keys(screenSizes) as ScreenSize[]
                const sizeIndex = sizesKeysArr.indexOf(screenSize)

                if (sizeIndex + 1 === sizesKeysArr.length) {
                    return "@media"
                }

                const nextSize = sizesKeysArr[sizeIndex + 1]

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!nextSize) {
                    return `@media (min-width: ${screenSizes[screenSize]}px)`
                }

                return `@media (min-width: ${screenSizes[screenSize]}px and max-width: ${screenSizes[nextSize] - 1}px)`
            }
            default:
                return ""
        }
    }

    /**
     * A hook that returns a boolean indicating whether the screen matches or not.
     *
     * @param limitType The type of limit to apply
     * @param screenSize The screen size to apply the styles to
     * @returns A media query string
     */
    function useMediaQuery(limitType: LimitType, screenSize: ScreenSize) {
        const [matches, setMatches] = useState(false)
        const query = mediaQuery(limitType, screenSize).replace("@media", "")

        useEffect(() => {
            const media = window.matchMedia(query)
            if (media.matches !== matches) {
                setMatches(media.matches)
            }

            const listener = () => {
                setMatches(media.matches)
            }

            media.addEventListener("change", listener)

            return () => {
                media.removeEventListener("change", listener)
            }
        }, [matches, query])

        return matches
    }

    /**
     * Helper function that returns a media query and styles for a given screen size.
     *
     * @param limitType The type of limit to apply
     * @param screenSize The screen size to apply the styles to
     * @param styles The styles to apply to the given screen size limit
     * @returns `SerializedStyles` to be used in an Emotion `css` prop
     */
    function responsiveCss(
        limitType: LimitType,
        screenSize: ScreenSize,
        s: CSSObject | SerializedStyles
    ): SerializedStyles {
        return css({ [mediaQuery(limitType, screenSize)]: s })
    }

    function isVariantInVariants<V extends string>(
        variant: VariantMediaQuery<V> | ResponsiveVariant<V>,
        variants: VariantStyles<V>
    ): variant is V {
        return Object.keys(variants).includes(variant as any)
    }

    function isVariantMediaQuery<T extends string>(
        variant: VariantMediaQuery<T> | ResponsiveVariant<T>
    ): variant is VariantMediaQuery<T> {
        return (
            variant instanceof Array &&
            variant.length === 3 &&
            typeof variant[0] === "string" &&
            limitTypes.includes(variant[0] as any) &&
            typeof variant[1] === "string" &&
            (typeof variant[2] === "string" || typeof variant[2] === "number")
        )
    }

    /**
     * Returns responsive CSS for a responsive variant that does not have a responsive dimension.
     */
    function variantCss<T extends string>(
        variants: VariantStyles<T>,
        variant: VariantMediaQuery<T> | ResponsiveVariant<T>
    ): SerializedStyles | undefined {
        if (
            isVariantInVariants(variant, variants) ||
            typeof variant === "string" ||
            typeof variant === "number"
        ) {
            return css(variants[variant])
        } else if (isVariantMediaQuery(variant)) {
            const variantMediaQuery = variant
            const [limitType, screenSize, v] = variantMediaQuery
            const style = variants[v]
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (style) {
                return css(responsiveCss(limitType, screenSize, style))
            }
        } else if (variant instanceof Array) {
            return css(variant.map((v) => variantCss<T>(variants, v)))
        }

        return
    }

    // Helper function to get the screen sizes based on min/max and the provided size
    function getScreenSizes(limitType: LimitType, screenSize: ScreenSize): ScreenSize[] {
        const order = Object.keys(screenSizes)
        const index = order.indexOf(screenSize)
        if (limitType === "min") {
            return order.slice(index) as ScreenSize[]
        } else {
            return order.slice(0, index + 1) as ScreenSize[]
        }
    }

    function getStyleForScreenSize(screenSize: ScreenSize, screenSizeStyles: ResponsiveStyles) {
        return getScreenSizes("max", screenSize).reduce((acc, curr) => {
            return Object.assign(acc, screenSizeStyles[curr] ?? {})
        }, {})
    }

    function getCssForScreenSize(screenSize: ScreenSize, screenSizeStyles: ResponsiveStyles) {
        const sss = getScreenSizes("max", screenSize)
        return css(sss.map((ss) => screenSizeStyles[ss]))
    }

    /**
     * Type guard for checking if a string is a screen size.
     */
    function isScreenSize(screenSize: string): screenSize is ScreenSize {
        return Object.keys(screenSizes).includes(screenSize)
    }

    /**
     * Returns responsive CSS for a set of ResponsiveStyles.
     */
    function responsiveVariantToCss(variant: ResponsiveStyles): SerializedStyles {
        const ss: SerializedStyles[] = []
        for (const screenSize in variant) {
            if (variant[screenSize]) {
                ss.push(responsiveCss("min", screenSize, variant[screenSize]))
            }
        }
        return css(ss)
    }

    function isMediaQuery<T extends string>(
        v: T | VariantMediaQuery<T> | VariantMediaQuery<T>[]
    ): v is VariantMediaQuery<T>[] {
        if (v instanceof Array === false) return false

        const [limitType, screenSize, variant] = v
        if (!limitTypes.includes(limitType as any)) return false
        if (!isScreenSize(screenSize as any)) return false
        if (typeof variant !== "string" && typeof variant !== "number") return false

        return true
    }

    function fixedVariantCss<T extends string>(
        variants: ResponsiveVariantStyles<T>,
        variant: FixedVariant<T>
    ) {
        const baseScreenSize = Object.keys(screenSizes)[0] as ScreenSize
        const [, screenSize, variantKey] = variant

        if (
            !baseScreenSize ||
            // Allow variantKey of type number as well, to support heading levels being numbers.
            (typeof variantKey !== "string" && typeof variantKey !== "number") ||
            typeof screenSize !== "string" ||
            !variants[variantKey]
        ) {
            return
        }

        return getCssForScreenSize(screenSize, variants[variantKey])
    }

    /**
     * Returns responsive CSS for a responsive variant that has a responsive dimension.
     */
    function responsiveVariantsCss<V extends string>(
        variants: ResponsiveVariantStyles<V>,
        variant: ResponsiveVariant<V>
    ): SerializedStyles | undefined {
        function isVariant(v: ResponsiveVariant<V> | VariantMediaQuery<V>): v is V {
            return typeof v === "string" || typeof v === "number"
        }

        if (isVariant(variant) && !!variants[variant]) {
            return responsiveVariantToCss(variants[variant])
        } else if (variant instanceof Array) {
            const [baseVariant, ...rest] = variant.filter(isVariant)

            // Specifying more than one variant without media query does not make sense.
            if (rest.length) {
                // eslint-disable-next-line no-console
                console.warn("Should not use more than one string.", variant)
            }

            const overrides = variant.filter((v) => isMediaQuery<V>(v))

            // Check if used a combination of min and max which is not advisable.
            if (overrides.find((o) => o[0] === "min") && overrides.find((o) => o[0] === "max")) {
                // eslint-disable-next-line no-console
                console.warn(
                    "Combining min and max is not recommended. Try a base + min overrides instead for example."
                )
            }

            if (!(isVariant(baseVariant) && variants[baseVariant])) return

            const baseSizeStyles = { ...variants[baseVariant] }

            overrides.forEach((o) => {
                if (typeof o === "string" || typeof o === "number") return
                const [limitType, screenSize, v] = o
                const screenSizesToOverride = getScreenSizes(limitType, screenSize)
                screenSizesToOverride.forEach((ss) => {
                    const responsiveStyles = variants[v]
                    if (!responsiveStyles) return
                    baseSizeStyles[ss] = getStyleForScreenSize(ss, responsiveStyles)
                })
            })

            const ss: SerializedStyles[] = []
            for (const screenSize in baseSizeStyles as Record<ScreenSize, CSSProperties>) {
                if (baseSizeStyles[screenSize]) {
                    ss.push(responsiveCss("min", screenSize, baseSizeStyles[screenSize]))
                }
            }
            return css(ss)
        }

        return
    }

    function responsivePropCss<V extends string>(
        values: KeyValues<V>,
        prop: string,
        responsiveValues: ResponsiveVariant<V>
    ): SerializedStyles {
        if (typeof responsiveValues === "string") {
            return css({ [prop]: values[responsiveValues] })
        }
        return css(
            responsiveValues.map((rv) => {
                if (typeof rv === "string") {
                    return { [prop]: values[rv] }
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                } else if (rv) {
                    const [limitType, screenSize, value] = rv
                    return responsiveCss(limitType, screenSize, { [prop]: values[value] })
                }
            })
        )
    }

    function responsiveValueCss(
        prop: string,
        responsiveValues: ResponsiveStyleValue
    ): SerializedStyles {
        if (typeof responsiveValues === "string" || typeof responsiveValues === "number") {
            return css({ [prop]: responsiveValues })
        }
        return css(
            responsiveValues.map((rv) => {
                if (typeof rv === "string" || typeof rv === "number") {
                    return { [prop]: rv }
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                } else if (rv) {
                    const [limitType, screenSize, value] = rv
                    return responsiveCss(limitType, screenSize, { [prop]: value })
                }
            })
        )
    }

    function responsiveSpacing(variant: SpacingSize, type: string | string[]) {
        if (typeof spacingSizes[variant] === "object") {
            return css(
                Object.entries(spacingSizes[variant] as Record<ScreenSize, number>).map(
                    ([screenSize, val]) =>
                        responsiveCss(
                            "min",
                            screenSize as ScreenSize,
                            (type instanceof Array
                                ? Object.fromEntries(type.map((t) => [t, val]))
                                : {
                                      [type]: typeof val === "number" ? `${val}px` : val,
                                  }) as CSSObject
                        )
                )
            )
        }
    }

    /**
     * Helper function that returns a media query with responsive CSS for a given heading level, based
     * on sizes defined in the `headingLevels` theme config.
     *
     * @param level The border radius variant
     * @returns `SerializedStyles` to be used in an Emotion `css` prop
     */
    function responsiveHeadingLevel(level: HeadingLevel) {
        if (typeof headingLevels[level] === "object") {
            return css(
                Object.entries(headingLevels[level] as Record<ScreenSize, CSSObject>).map(
                    ([screenSize, headingStyle]) =>
                        responsiveCss("min", screenSize as ScreenSize, headingStyle as CSSObject)
                )
            )
        }
    }

    /**
     * Determines whether a variant is fixed, i.e. ["fixed", "sm", "md"] – always rendered as
     * medium variant on small screen.
     */
    function isFixedVariant<T extends string>(variant: any): variant is FixedVariant<T> {
        return Array.isArray(variant) && variant.length === 3 && variant[0] === "fixed"
    }

    function responsiveHeadingCss(
        headingLevel: ResponsiveVariant<HeadingLevel> | FixedVariant<HeadingLevel>
    ): SerializedStyles | undefined {
        if (isFixedVariant(headingLevel)) {
            return fixedVariantCss(headingLevels, headingLevel)
        }
        return responsiveVariantsCss(headingLevels, headingLevel)
    }

    function responsiveHidden(mq: MediaQuery) {
        return responsiveCss(...mq, { display: "none" })
    }

    /**
     * Specify spacing by either a number for same spacing in all directions, in x/y direction,
     * or specific spacing for top/right/bottom/left.
     */
    type Spacing =
        | number
        | string
        | SpacingSize
        | Partial<Record<Axis | Side, number | string | SpacingSize>>

    function axisOrSideToProps(axisOrSide: Axis | Side, type: string): string | string[] {
        const axis = {
            x: [`${type}Left`, `${type}Right`],
            y: [`${type}Top`, `${type}Bottom`],
            top: `${type}Top`,
            bottom: `${type}Bottom`,
            left: `${type}Left`,
            right: `${type}Right`,
        }
        return axis[axisOrSide]
    }

    function isSpacingSize(val: string | number): val is SpacingSize {
        if (typeof val === "number") return false
        return Object.keys(spacingSizes).includes(val)
    }

    /**
     * Helper function to convert a Spacing prop to CSS.
     *
     * @param type The type of spacing to return rules for, either margin or padding.
     * @param spacing A valid spacing object or single value. Supports both pixels and SpacingSize.
     *
     * @returns An Emotion SerializedStyles object
     */
    function spacingToCss(type: "margin" | "padding", spacing: Spacing) {
        function spacingVal(axisOrSide: Axis | Side, val: number | string) {
            if (isSpacingSize(val)) {
                return responsiveSpacing(val, axisOrSideToProps(axisOrSide, type))
            }
        }

        return css([
            typeof spacing === "number" || typeof spacing === "string"
                ? isSpacingSize(spacing)
                    ? responsiveSpacing(spacing, type)
                    : { [type]: spacing }
                : [
                      spacing.x &&
                          (spacingVal("x", spacing.x) ?? {
                              [`${type}Left`]: spacing.x,
                              [`${type}Right`]: spacing.x,
                          }),
                      spacing.y &&
                          (spacingVal("y", spacing.y) ?? {
                              [`${type}Top`]: spacing.y,
                              [`${type}Bottom`]: spacing.y,
                          }),
                      spacing.top &&
                          (spacingVal("top", spacing.top) ?? {
                              [`${type}Top`]: spacing.top,
                          }),
                      spacing.right &&
                          (spacingVal("right", spacing.right) ?? {
                              [`${type}Right`]: spacing.right,
                          }),
                      spacing.bottom &&
                          (spacingVal("bottom", spacing.bottom) ?? {
                              [`${type}Bottom`]: spacing.bottom,
                          }),
                      spacing.left &&
                          (spacingVal("left", spacing.left) ?? { [`${type}Left`]: spacing.left }),
                  ],
        ])
    }

    function responsiveBodyCss(
        size: ResponsiveVariant<BodySize> | FixedVariant<BodySize>
    ): SerializedStyles | undefined {
        if (isFixedVariant(size)) {
            return fixedVariantCss(config.bodySizes, size)
        }
        return responsiveVariantsCss(config.bodySizes, size)
    }

    /**
     * Helper function that returns a media query with responsive styles for a given border radius
     * variant for different screen sizes as specified in the border radius variants constant.
     *
     * @param variant The border radius variant
     * @returns `SerializedStyles` to be used in an Emotion `css` prop
     */
    function responsiveBorderRadius(variant: BorderRadius) {
        const borderRadiusVariant = borderRadiuses[variant]
        // Not always truthy, there might be 0 border radiuses.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (borderRadiusVariant && typeof borderRadiusVariant === "object") {
            return css(
                Object.entries(borderRadiuses[variant] as Record<ScreenSize, number | string>).map(
                    ([screenSize, borderRadius]) =>
                        responsiveCss("min", screenSize as ScreenSize, {
                            borderRadius: borderRadius as number | string,
                        })
                )
            )
        }
    }

    function textCss(
        variant: TextVariant,
        size: ResponsiveVariant<TextVariantSize> | FixedVariant<TextVariantSize>
    ) {
        const textVariant = config.textVariants[variant]

        return isFixedVariant(size)
            ? fixedVariantCss(textVariant, size)
            : responsiveVariantsCss(textVariant, size)
    }

    const helpers = {
        fixedVariantCss,
        mediaQuery,
        responsiveCss,
        responsiveVariantsCss,
        responsiveVariantToCss,
        responsiveHidden,
        responsivePropCss,
        responsiveValueCss,
        responsiveSpacing,
        responsiveBorderRadius,
        responsiveHeadingLevel,
        responsiveHeadingCss,
        responsiveBodyCss,
        spacingToCss,
        useMediaQuery,
        variantCss,
        textCss,
    }

    return {
        ...config,
        responsiveCss,
        helpers,
    }
}

/**
 * `ThemeGeneric` is the return type from `useTheme`, ensuring type safety across both
 * generic and themed components. It leverages type helpers that accept a specific theme
 * as a type argument, providing strong typing for properties like screen sizes, colors,
 * text variants, and more within the theme.
 */
export type ThemeGeneric<
    T extends ThemeAny,
    ScreenSize extends string = ScreenSizeGeneric<T>,
    Color extends string = ColorGeneric<T>,
    TextVariant extends string = TextVariantGeneric<T>,
    TextVariantSize extends string = TextVariantSizeGeneric<T, TextVariantGeneric<T>>,
    BodySize extends string = BodySizeGeneric<T>,
    BorderRadius extends string = BorderRadiusGeneric<T>,
    ButtonSize extends string = ButtonSizeGeneric<T>,
    ButtonVariant extends string = ButtonVariantGeneric<T>,
    HeadingLevel extends AllowedHeadingLevel = HeadingLevelGeneric<T>,
    IconName extends string = IconNameGeneric<T>,
    IconSize extends number = IconSizeGeneric<T>,
    SpacingSize extends string = SpacingSizeGeneric<T>,
    Spacing = SpacingGeneric<T>,
> = Theme<
    ScreenSize,
    Color,
    TextVariant,
    TextVariantSize,
    BodySize,
    BorderRadius,
    ButtonSize,
    ButtonVariant,
    HeadingLevel,
    IconName,
    IconSize,
    SpacingSize,
    Spacing
>

/**
 * `ThemeContext` is a context object used to provide and consume the current theme
 * throughout the application. It holds the theme value, which can be accessed by any
 * component wrapped inside the `ThemeProvider`.
 */
const ThemeContext = createContext<ThemeAny | null>(null)

/**
 * `ThemeProvider` is a component that provides the current theme to all child components
 * via `ThemeContext`. It accepts a `theme` prop, which is the theme object, and renders
 * its children with access to the theme using the `useTheme` hook.
 */
export const ThemeProvider = (props: {
    /**
     * The theme object that will be provided to the components within the `ThemeProvider`.
     */
    theme: ThemeAny
    children: ReactNode
}) => {
    return <ThemeContext.Provider value={props.theme}>{props.children}</ThemeContext.Provider>
}

/**
 * `useTheme` allows components to access the current theme from `ThemeContext`.
 * It returns the theme as a strongly-typed `ThemeGeneric<T>`, based on the provided theme type `T`.
 * If the hook is used outside of a `ThemeProvider`, it throws an error.
 */
export function useTheme<T extends ThemeAny>(): ThemeGeneric<T> {
    const theme = useContext(ThemeContext)

    if (!theme) {
        throw new Error("useTheme must be used within a ThemeProvider")
    }
    return theme
}
