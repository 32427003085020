import { ComponentProps } from "react"
import { createIcon } from "../../../../../../packages/web-ui/components/visual/Icon"
import { RedoitTheme } from "../../theme"

const ThemedIcon = createIcon<RedoitTheme>()

export function Icon({ color, ...rest }: ComponentProps<typeof ThemedIcon>) {
    return <ThemedIcon color={rest.icon === "trustpilot" ? "_trustpilot" : color} {...rest} />
}

export type { IconName } from "../../theme"
