import { css } from "@emotion/react"
import { useCallback, useRef, ChangeEvent, ReactNode } from "react"
import { Component } from "../../../../../../packages/editing/Component"
import { Box } from "../base/Box"
import { Flex } from "../base/Flex"
import { Markdown } from "../../../../../../reactor"
import { Text } from "../typography/Text"
import { MarkdownView } from "../base/MarkdownView"
import { RedoitSpacing } from "../../theme"

const boxStyle = css({
    flexGrow: 0,
    flexShrink: 0,
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 6,
    height: 24,
    width: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
})

export function Checkbox(props: {
    /**
     * Whether the checkbox is checked or not.
     */
    checked?: boolean

    /**
     * A label to show next to the checkbox.
     * @reflection any
     */
    label?: string | Markdown | ReactNode

    /**
     * Name for the checkbox, relevant if part of a form.
     */
    name?: string

    /**
     * Whether this element is available for interaction or not.
     */
    disabled?: boolean

    /**
     * @reflection any
     */
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void

    padding?: RedoitSpacing
}) {
    const inputRef = useRef<HTMLInputElement>(null)

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (typeof props.onChange === "function") props.onChange(e)
        },
        [props]
    )

    return (
        <Flex
            as="label"
            alignItems="center"
            style={{
                cursor: props.disabled ? "default" : "pointer",
                position: "relative",
            }}
            padding={props.padding}
        >
            <Box
                backgroundColor={
                    props.disabled
                        ? props.checked
                            ? "gray200"
                            : "gray100"
                        : props.checked
                          ? "brand"
                          : "grayWhite"
                }
                borderColor={props.disabled ? "gray200" : props.checked ? "brand" : "gray200"}
                css={boxStyle}
            >
                {props.checked && <Checkmark />}
            </Box>

            {props.label && (
                <Text variant="body" size="md" margin={{ left: 16 }}>
                    {typeof props.label === "string" ? (
                        <MarkdownView
                            value={props.label}
                            renderers={{
                                paragraph: (p) => <div>{p.children}</div>,
                            }}
                        />
                    ) : (
                        props.label
                    )}
                </Text>
            )}
            <input
                type="checkbox"
                ref={inputRef}
                onChange={handleChange}
                disabled={props.disabled}
                style={{
                    opacity: 0,
                    cursor: "inherit",
                    position: "absolute",
                    zIndex: -1,
                }}
            />
        </Flex>
    )
}

Component(Checkbox, {
    name: "Checkbox",
    gallery: {
        path: "Controllers/Checkbox",
        items: [
            {
                title: "Enabled",
                variants: [
                    {
                        label: "Interactive",
                        props: (state = { checked: false }, setState) => ({
                            checked: state.checked,
                            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                setState({ checked: e.target.checked })
                            },
                        }),
                    },
                    { label: "Checked", props: { checked: true } },
                ],
            },
            {
                title: "Disabled",
                variants: [
                    { label: "Unchecked", props: { checked: false, disabled: true } },
                    { label: "Checked", props: { checked: true, disabled: true } },
                ],
            },
            {
                title: "With label",
                variants: [
                    {
                        props: (state = { checked: false }, setState) => ({
                            checked: state.checked,
                            label: "Jeg vil gjerne vite mer om bærekraft og Redoits tilbud. [Les vilkår](https://google.com)",
                            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                setState({ checked: e.target.checked })
                            },
                        }),
                    },
                ],
            },
        ],
    },
})

const Checkmark = (props: React.SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={12} fill="none" {...props}>
        <path
            fill="#fff"
            d="M5.457 8.293 2.164 5 .75 6.414l4.707 4.707 9.707-9.707L13.75 0 5.457 8.293Z"
            style={{
                fill: "#fff",
                fillOpacity: 1,
            }}
        />
    </svg>
)
