import { CSSProperties, ComponentPropsWithoutRef, ReactNode, RefObject } from "react"
import { css } from "@emotion/react"
import {
    ColorGeneric,
    FixedVariantGeneric,
    HeadingLevelGeneric,
    ResponsiveVariantGeneric,
    SpacingGeneric,
    TextVariantGeneric,
    TextVariantSizeGeneric,
    ScreenSizeGeneric,
    ThemeAny,
    useTheme,
} from "../../theme"
import { underline } from "../../helpers/css"

export type TextElementTypeMap = {
    div: HTMLDivElement
    p: HTMLParagraphElement
    span: HTMLSpanElement
    h1: HTMLHeadingElement
    h2: HTMLHeadingElement
    h3: HTMLHeadingElement
    h4: HTMLHeadingElement
    h5: HTMLHeadingElement
    h6: HTMLHeadingElement
    a: HTMLAnchorElement
}

type Element = keyof TextElementTypeMap

export type TextProps<T extends ThemeAny, E extends Element = "div"> = {
    /**
     * Specify which HTML element the Text should be rendered as.
     * By default it is rendered as a div.
     */
    as?: E

    /**
     * Specify a text color from the color palette.
     */
    color?: ColorGeneric<T>

    style?: CSSProperties

    lineHeight?: string | number

    fontSize?: number

    /**
     * Specify margin.
     */
    margin?: SpacingGeneric<T>

    // TODO: Find better way to support heading in addition to the theme text variants.
    variant: TextVariantGeneric<T> | "heading"

    level?:
        | FixedVariantGeneric<ScreenSizeGeneric<T>, HeadingLevelGeneric<T>>
        | ResponsiveVariantGeneric<ScreenSizeGeneric<T>, HeadingLevelGeneric<T>>

    // TODO: Available sizes for the different variants are being mixed up,
    // types need to be improved.
    size?:
        | FixedVariantGeneric<
              ScreenSizeGeneric<T>,
              TextVariantSizeGeneric<T, TextVariantGeneric<T>>
          >
        | ResponsiveVariantGeneric<
              ScreenSizeGeneric<T>,
              TextVariantSizeGeneric<T, TextVariantGeneric<T>>
          >

    /**
     * Add underline.
     */
    underline?: boolean

    /**
     * Maximum number of lines to render. Exceeding this number will render an ellipsis and hide
     * the overflowing lines.
     */
    lineClamp?: number

    /**
     * Truncate overflowing text. Forces nowrap and adds ellipsis.
     */
    truncate?: boolean

    /**
     * Pass a ref that will be attached to the rendered Text element.
     */
    elementRef?: RefObject<TextElementTypeMap[E]>

    onClick?: () => void

    /**
     * @reflection any
     */
    children?: ReactNode
} & ComponentPropsWithoutRef<E>

export function createText<T extends ThemeAny>() {
    return function Text<E extends Element = "div">(props: TextProps<T, E>) {
        const { helpers, colors } = useTheme<T>()
        const { spacingToCss, responsiveHeadingCss, textCss } = helpers

        const El = props.as ?? "div"

        return (
            <El
                ref={props.elementRef as any}
                onClick={props.onClick}
                style={{
                    fontSize: props.fontSize,
                    ...(props.style || {}),
                }}
                css={css(
                    { "& a": underline },
                    props.color && { color: colors[props.color] },
                    props.lineHeight && { lineHeight: props.lineHeight },
                    !!props.variant &&
                        (props.variant === "heading"
                            ? !!props.level && responsiveHeadingCss(props.level)
                            : props.variant && props.size
                              ? textCss(props.variant, props.size)
                              : undefined),
                    typeof props.lineClamp !== "undefined" && {
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: `${props.lineClamp}`,
                        lineClamp: `${props.lineClamp}`,
                        textOverflow: "ellipsis",
                        WebkitBoxOrient: "vertical",
                    },
                    props.truncate && {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                    },
                    props.margin && spacingToCss("margin", props.margin),
                    props.underline && underline
                )}
                className={props.className}
            >
                {props.children}
            </El>
        )
    }
}
