import { CSSProperties, MouseEventHandler } from "react"
import { css } from "@emotion/react"
import {
    ColorGeneric,
    IconNameGeneric,
    IconSizeGeneric,
    MediaQueryGeneric,
    ScreenSizeGeneric,
    ResponsiveStyleValueGeneric,
    SpacingGeneric,
    ThemeAny,
    useTheme,
} from "../../theme"

export function createIcon<T extends ThemeAny>() {
    type IconName = IconNameGeneric<T>

    return function Icon(props: {
        icon: IconName
        color?: ColorGeneric<T>

        size?: ResponsiveStyleValueGeneric<ScreenSizeGeneric<T>, IconSizeGeneric<T>>

        margin?: SpacingGeneric<T>

        /**
         * @reflection any
         */
        style?: CSSProperties

        /**
         * Don't display the icon
         */
        hidden?: boolean | MediaQueryGeneric<T>

        /**
         * @reflection any
         */
        onClick?: MouseEventHandler<SVGElement>

        /**
         * Forward any styles from `css` prop.
         */
        className?: string
    }) {
        const theme = useTheme<T>()
        const fillColor = props.color ? theme.colors[props.color] : "currentColor"

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className={props.className}
                css={css(
                    props.hidden instanceof Array && theme.helpers.responsiveHidden(props.hidden),
                    !!props.margin && theme.helpers.spacingToCss("margin", props.margin),
                    props.onClick && { cursor: "pointer" },
                    props.size
                        ? [
                              theme.helpers.responsiveValueCss("height", props.size),
                              theme.helpers.responsiveValueCss("width", props.size),
                          ]
                        : { height: 24, width: 24 }
                )}
                style={{
                    color: fillColor,
                    flexShrink: 0,
                    display: props.hidden === true ? "none" : undefined,
                    ...(props.style || {}),
                }}
                onClick={props.onClick ? props.onClick : undefined}
            >
                {theme.icons[props.icon]}
            </svg>
        )
    }
}
