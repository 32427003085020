import { css } from "@emotion/react"
import { scaleValue } from "../helpers/css"
import { Component } from "../../../../../packages/editing/Component"
import { Flex } from "./base/Flex"
import { Icon } from "./visual/Icon"
import { Text } from "../components/typography/Text"
import { Color, pageWidthCss, ScreenSize, useRedoitTheme } from "../theme"

type Theme = "light" | "dark"
const themes: Record<
    Theme,
    { backgroundColor: Color; textColor: Color; iconColor: Color; borderColor: Color }
> = {
    light: {
        backgroundColor: "gray100",
        textColor: "gray500",
        iconColor: "forest",
        borderColor: "gray200",
    },
    dark: {
        backgroundColor: "gray500",
        textColor: "grayWhite",
        iconColor: "highlight",
        borderColor: "gray400",
    },
}

export function UniqueSellingPoints(props: {
    theme?: Theme
    stackBreakpoint?: ScreenSize
    fullWidth?: boolean
    items: string[]
}) {
    const theme = props.theme ?? "light"
    const { helpers, colors } = useRedoitTheme()
    const { responsiveCss } = helpers

    const stackBreakpoint = props.stackBreakpoint ?? "md"

    return (
        <Flex
            borderRadius={props.fullWidth ? undefined : "lg"}
            justifyContent="center"
            css={css(
                props.fullWidth && {
                    width: "100vw",
                    marginLeft: "calc(50% - 50vw)",
                },
                { backgroundColor: colors[themes[theme].backgroundColor] },
                theme === "light" &&
                    responsiveCss("max", stackBreakpoint, {
                        backgroundColor: "transparent",
                    }),
                theme === "dark" &&
                    responsiveCss("max", stackBreakpoint, {
                        paddingTop: scaleValue(24),
                        paddingBottom: scaleValue(24),
                    })
            )}
        >
            <Flex
                justifyContent="space-evenly"
                css={css(
                    {
                        flexDirection: "row",
                        padding: scaleValue(18),
                    },
                    responsiveCss("max", stackBreakpoint, {
                        flexDirection: "column",
                        padding: 0,
                    }),
                    props.fullWidth ? pageWidthCss() : { width: "100%" }
                )}
            >
                {props.items.map((item) => (
                    <Flex
                        key={item}
                        margin={{ x: 12 }}
                        css={responsiveCss("max", stackBreakpoint, {
                            borderTop: `1px solid ${colors[themes[theme].borderColor]}`,
                            margin: 0,
                            paddingTop: 16,
                            paddingBottom: 16,
                            "&:first-of-type": {
                                borderTop: "none",
                                paddingTop: 0,
                            },
                            "&:last-of-type": {
                                paddingBottom: 0,
                            },
                        })}
                        alignItems="center"
                    >
                        <div>
                            <Icon icon="check" color={themes[theme].iconColor} />
                        </div>
                        <div
                            css={css(
                                {
                                    marginLeft: 10,
                                    marginTop: 0,
                                    color: colors[themes[theme].textColor],
                                },
                                responsiveCss("max", stackBreakpoint, {
                                    marginLeft: 8,
                                })
                            )}
                        >
                            <Text variant="body" size="md">
                                <div style={{ fontWeight: "normal" }}>{item}</div>
                            </Text>
                        </div>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    )
}

Component(UniqueSellingPoints, {
    name: "UniqueSellingPoints",
    gallery: {
        items: [
            {
                variants: [
                    {
                        props: {
                            items: [
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                "Donec eget fermentum lorem. Phasellus vulputate tortor a augue commodo efficitur.",
                                "Vestibulum efficitur tortor ante, quis varius tortor blandit in.",
                            ],
                        },
                    },
                ],
            },
        ],
    },
})
