import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { Link } from "react-router-dom"
import { MotionConfig, motion, useAnimate } from "framer-motion"
import { Component } from "../../../../../../packages/editing/Component"
import { Box } from "../base/Box"
import { Flex } from "../base/Flex"
import { springAnimations } from "../../constants/animation"
import { boxShadow } from "../../constants/shadow"
import { LogoR } from "../visual/LogoR"
import { TrustpilotButton } from "../buttons/TrustpilotButton"
import { Text } from "../typography/Text"
import { css } from "@emotion/react"
import { LocaleSelect } from "../controllers/LocaleSelect"
import { Body } from "../typography/Body"
import { xlScaleFactor } from "../../constants/sizes"
import { scaleValue } from "../../helpers/css"
import { MenuCloseIcon } from "../visual/MenuCloseIcon"
import { Locale, LocaleKey } from "../../../../../../packages/localization/Locale"
import { Localized } from "../../../../../../packages/localization/Localized"
import { Url } from "../../../../../../reactor"
import { IconName, useRedoitTheme } from "../../theme"

export function NavigationModal(props: {
    items: { id: string; href?: Url; text: string; icon?: IconName; onClick?: () => void }[]
    trustpilot?: { score: number; text: string; url?: Url }
    renderUserButton?: () => ReactNode
    isLoggedIn?: boolean
    locales?: Locale[]
    currentLocale: LocaleKey
    onLocaleChange?: (locale: LocaleKey) => void
}) {
    const { colors, helpers, screenSizes } = useRedoitTheme()

    const { responsiveCss } = helpers
    const [isOpen, setIsOpen] = useState(false)
    const [openAnimationComplete, setOpenAnimationComplete] = useState(false)
    const [modalContentRef, animateModalContent] = useAnimate()
    const [modalRef, animateModal] = useAnimate()

    const triggerButtonRef = useRef<HTMLDivElement>(null)

    const getModalWidth = useCallback(() => {
        if (triggerButtonRef.current) {
            const rect = triggerButtonRef.current.getBoundingClientRect()
            const viewportWidth = document.body.clientWidth
            if (viewportWidth < screenSizes.sm) {
                const marginRight = viewportWidth - rect.right
                const modalWidth = viewportWidth - marginRight * 2
                return modalWidth
            }
        }
        return modalContentRef.current?.clientWidth || 0
    }, [screenSizes.sm, modalContentRef])

    const toggleOpen = useCallback(() => {
        if (!isOpen) {
            const modalWidth = getModalWidth()
            void animateModal(
                modalRef.current,
                {
                    boxShadow,
                    backgroundColor: colors.grayWhite,
                    height: modalContentRef.current?.clientHeight || 0,
                    width: modalWidth,
                },
                {
                    onComplete: () => {
                        setOpenAnimationComplete(true)
                        void animateModalContent(modalContentRef.current, { opacity: 1 })
                    },
                }
            )
        } else {
            void animateModal(
                modalRef.current,
                {
                    opacity: 0,
                },
                {
                    onComplete: () => {
                        setOpenAnimationComplete(false)
                        void animateModalContent(
                            modalContentRef.current,
                            { opacity: 0 },
                            { duration: 0 }
                        )
                        void animateModal(
                            modalRef.current,
                            {
                                boxShadow: "none",
                                backgroundColor: colors.gray100,
                                height: 48 * xlScaleFactor,
                                width: 48 * xlScaleFactor,
                                opacity: 1,
                            },
                            { duration: 0 }
                        )
                    },
                }
            )
        }
        setIsOpen(!isOpen)
    }, [
        isOpen,
        setIsOpen,
        animateModalContent,
        animateModal,
        modalRef,
        modalContentRef,
        getModalWidth,
    ])

    const handleResize = useCallback(() => {
        if (isOpen && openAnimationComplete) {
            void animateModal(modalRef.current, { width: getModalWidth() }, { duration: 0 })
        }
    }, [getModalWidth, isOpen, animateModal, modalRef, openAnimationComplete])

    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(document.body)
        return () => {
            resizeObserver.disconnect()
        }
    }, [getModalWidth, handleResize])

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (isOpen) {
                document.body.style.overflow = "hidden"
            } else {
                document.body.style.overflow = ""
            }
        }
    }, [isOpen])

    return (
        <>
            <div
                ref={triggerButtonRef}
                style={{
                    position: "relative",
                    height: 48 * xlScaleFactor,
                    width: 48 * xlScaleFactor,
                }}
            >
                {/* The only purpose of this menu button is to have something to show while the modal is fading out. */}
                <div
                    style={{
                        height: 48 * xlScaleFactor,
                        width: 48 * xlScaleFactor,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        zIndex: 1,
                        position: "relative",
                        backgroundColor: colors.gray100,
                        borderRadius: 24 * xlScaleFactor,
                    }}
                >
                    <MenuCloseIcon state="menu" />
                </div>
                <MotionConfig transition={springAnimations["200"]}>
                    <div
                        ref={modalRef}
                        style={{
                            boxShadow: "none",
                            backgroundColor: colors.gray100,
                            height: 48 * xlScaleFactor,
                            width: 48 * xlScaleFactor,
                            opacity: 1,
                            overflow: "hidden",
                            display: "flex",
                            position: "absolute",
                            borderRadius: 24 * xlScaleFactor,
                            top: 0,
                            right: 0,
                            zIndex: 10,
                        }}
                    >
                        <div style={{ position: "absolute", top: 0, right: 0 }}>
                            <div
                                style={{
                                    height: 48 * xlScaleFactor,
                                    width: 48 * xlScaleFactor,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    zIndex: 15,
                                    position: "relative",
                                }}
                                onClick={toggleOpen}
                            >
                                <MenuCloseIcon state={isOpen ? "close" : "menu"} duration="200ms" />
                            </div>
                        </div>
                        <Flex
                            direction="column"
                            style={{
                                zIndex: 10,
                                position: "absolute",
                            }}
                            css={responsiveCss("max", "xs", { width: "100%" })}
                        >
                            <div
                                ref={modalContentRef}
                                style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    padding: scaleValue(24),
                                    opacity: 0,
                                }}
                            >
                                <Flex
                                    justifyContent="space-between"
                                    margin={{ bottom: scaleValue(20) }}
                                >
                                    <Flex alignItems="center">
                                        <motion.div
                                            animate={
                                                openAnimationComplete
                                                    ? { transform: "translateX(0px)" }
                                                    : {
                                                          transform: "translateX(-5px)",
                                                      }
                                            }
                                        >
                                            <Link to="/" onClick={toggleOpen}>
                                                <LogoR width={40} style={{ marginRight: 8 }} />
                                            </Link>
                                        </motion.div>
                                        {props.trustpilot ? (
                                            <motion.div
                                                animate={
                                                    openAnimationComplete
                                                        ? { transform: "translateX(0px)" }
                                                        : {
                                                              transform: "translateX(-10px)",
                                                          }
                                                }
                                            >
                                                <TrustpilotButton
                                                    href={props.trustpilot.url}
                                                    score={props.trustpilot.score.valueOf()}
                                                    onClick={toggleOpen}
                                                />
                                            </motion.div>
                                        ) : null}
                                    </Flex>
                                </Flex>
                                <div>
                                    {props.items
                                        .slice(props.isLoggedIn ? 0 : 1, props.items.length)
                                        .map((item, i) => (
                                            <motion.a
                                                key={item.id}
                                                animate={
                                                    openAnimationComplete
                                                        ? { transform: `translateX(0px)` }
                                                        : {
                                                              transform: `translateX(-${(props.items.length - i) * 5}px)`,
                                                          }
                                                }
                                                onClick={() => {
                                                    if (!item.href && item.onClick) {
                                                        item.onClick()
                                                    }
                                                    toggleOpen()
                                                }}
                                                href={item.href?.valueOf()}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: "nowrap",
                                                }}
                                                css={css({ height: scaleValue(64) })}
                                            >
                                                <Text variant="heading" level={["fixed", "xs", 2]}>
                                                    {item.text}
                                                </Text>
                                            </motion.a>
                                        ))}
                                </div>
                                <Box
                                    motion={{
                                        animate: openAnimationComplete
                                            ? { transform: "translateX(0px)" }
                                            : {
                                                  transform: "translateX(-5px)",
                                              },
                                    }}
                                    padding={{ y: scaleValue(20) }}
                                    css={css({
                                        borderTop: `1px solid ${colors.gray200}`,
                                        borderBottom: `1px solid ${colors.gray200}`,
                                        display: "grid",
                                        gridAutoFlow: "column",
                                        gridAutoColumns: "minmax(0, 1fr)",
                                        gap: scaleValue(20),
                                    })}
                                >
                                    {!props.isLoggedIn &&
                                        !!props.renderUserButton &&
                                        props.renderUserButton()}
                                    <LocaleSelect
                                        locales={props.locales || []}
                                        currentLocale={props.currentLocale}
                                        onLocaleChange={(l) => {
                                            toggleOpen()
                                            props.onLocaleChange?.(l)
                                        }}
                                    />
                                </Box>
                                <Body
                                    color="gray500"
                                    size="md"
                                    margin={{ top: scaleValue(20) }}
                                    style={{ opacity: 0.6, whiteSpace: "nowrap" }}
                                >
                                    © {new Date().getFullYear()} Redoit • All rights reserved
                                </Body>
                            </div>
                        </Flex>
                    </div>
                </MotionConfig>
                {isOpen &&
                    createPortal(
                        <motion.div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: "100%",
                                width: "100%",
                                zIndex: 0,
                            }}
                            animate={{ backgroundColor: "rgba(0, 0, 0, 0.32)" }}
                        ></motion.div>,
                        document.body
                    )}
            </div>
        </>
    )
}

Component(NavigationModal, {
    name: "NavigationModal",
    gallery: {
        items: [
            {
                variants: [
                    {
                        props: {
                            items: [
                                { id: "1", text: "Skipped", href: Url("") },
                                { id: "2", text: "Hvordan det fungerer", href: Url("#") },
                                { id: "3", text: "Vanlige spørsmål", href: Url("#") },
                                { id: "4", text: "Kontakt oss", href: Url("#") },
                            ],
                            trustpilot: { score: 5, text: "Trustpilot" },
                            currentLocale: LocaleKey("no"),
                            onLocaleChange: (l: LocaleKey) => {
                                // eslint-disable-next-line no-console
                                console.log("Changed locale to", l)
                            },
                            locales: [
                                {
                                    flag: "🇬🇧",
                                    key: LocaleKey("en"),
                                    displayName: "English" as any as Localized<string>,
                                    fallback: LocaleKey("no"),
                                },
                                {
                                    flag: "🇳🇴",
                                    key: LocaleKey("no"),
                                    displayName: "Norwegian" as any as Localized<string>,
                                },
                            ],
                        },
                        render: (cmp) => (
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>{cmp}</div>
                        ),
                    },
                ],
            },
        ],
    },
})
