import { css } from "@emotion/react"
import { Uuid } from "../../../../reactor"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { UniqueSellingPoints as USPComponent } from "../ui/components/UniqueSellingPoints"
import { responsiveCss, scaleValue } from "../ui/helpers/css"
import { ScreenSize } from "../ui/theme"

function UniqueSellingPoints(section: {
    /**
     * @default light
     */
    theme: "light" | "dark"

    /**
     * Whether the bar with USPs should exceed to the edges of the browser window.
     */
    fullWidth?: boolean

    /**
     * Set a breakpoint for when the section should not be displayed.
     */
    hideBreakpoint?: ScreenSize

    /**
     * Set a breakpoint for when the section content should stack.
     */
    stackBreakpoint?: ScreenSize

    /**
     * @expand
     */
    uniqueSellingPoints: {
        readonly id: Uuid<"UniqueSellingPoint">

        /**
         * The text for the USP.
         * @title
         */
        text: Localized<string>
    }[]
}) {
    const localize = useLocalize()
    return (
        <div
            css={css(
                section.fullWidth
                    ? undefined
                    : { paddingTop: scaleValue(28), paddingBottom: scaleValue(12) },
                section.fullWidth
                    ? undefined
                    : responsiveCss("min", "md", {
                          paddingTop: scaleValue(28),
                          paddingBottom: scaleValue(28),
                      }),
                section.hideBreakpoint &&
                    responsiveCss("max", section.hideBreakpoint, { display: "none" })
            )}
        >
            <USPComponent
                fullWidth={section.fullWidth}
                theme={section.theme}
                stackBreakpoint={section.stackBreakpoint}
                items={section.uniqueSellingPoints.map((item) => localize(item.text))}
            />
        </div>
    )
}

Section("UniqueSellingPoints", UniqueSellingPoints)
