import { SVGProps } from "react"
import { Color, useRedoitTheme } from "../../theme"

export function LogoR({
    color,
    ...rest
}: { color?: Color } & SVGProps<SVGSVGElement>): JSX.Element {
    const { colors } = useRedoitTheme()

    const fillColor = color ? colors[color] : "#000"

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 47"
            width={50}
            height={47}
            fill="none"
            {...rest}
        >
            <g fill={fillColor}>
                <path
                    fillRule="evenodd"
                    d="m11.933 44.499-.09-.043c-3.692-1.78-6.565-4.503-8.506-8.101l-.032-.06-.03-.062C1.497 32.666.734 28.4.734 23.648c0-4.846.756-9.18 2.54-12.76l.03-.062.032-.06c1.95-3.616 4.853-6.305 8.598-7.968C15.678 1.071 20.163.321 25.191.321c4.994 0 9.432.739 13.085 2.477 3.828 1.649 6.773 4.34 8.628 8.028 1.927 3.603 2.745 7.962 2.745 12.822 0 4.766-.826 9.058-2.745 12.647-1.853 3.687-4.788 6.423-8.596 8.188l-.034.016-.034.015c-3.63 1.603-8.051 2.286-13.049 2.286-5.007 0-9.457-.686-13.169-2.263l-.09-.038Zm31.282-10.133c1.517-2.811 2.275-6.384 2.275-10.718 0-4.45-.758-8.081-2.275-10.892-1.4-2.811-3.616-4.861-6.649-6.15-2.916-1.405-6.707-2.108-11.374-2.108-4.666 0-8.515.703-11.548 2.108-2.916 1.289-5.133 3.339-6.65 6.15-1.399 2.81-2.099 6.442-2.099 10.892 0 4.334.7 7.907 2.1 10.718 1.516 2.81 3.733 4.919 6.649 6.325 3.033 1.288 6.882 1.932 11.548 1.932 4.667 0 8.458-.644 11.374-1.932 3.033-1.406 5.25-3.514 6.65-6.325Z"
                    clipRule="evenodd"
                    style={{
                        fill: fillColor,
                        fillOpacity: 1,
                    }}
                />
                <path
                    d="M13.872 10.69h13.889c1.76 0 3.297.326 4.613.979 1.332.653 2.354 1.584 3.068 2.794.713 1.194 1.07 2.595 1.07 4.202 0 1.338-.285 2.508-.856 3.51a5.707 5.707 0 0 1-2.378 2.293c-1.03.541-2.236.812-3.615.812v-.239c1.601 0 2.838.207 3.71.62.888.415 1.49.948 1.807 1.6.317.653.476 1.402.476 2.245v6.925h-4.28V29.96c0-.828-.151-1.48-.453-1.958-.285-.478-.729-.82-1.331-1.027-.603-.207-1.395-.31-2.378-.31h-3.401l.523-.908 4.519 5.636h-4.139l-5.136-6.448 5.113-6.447h4.162l-4.519 5.636-.523-.956h3.757c1.443 0 2.584-.405 3.425-1.217.856-.812 1.284-1.903 1.284-3.272 0-1.4-.468-2.491-1.403-3.271-.92-.796-2.212-1.194-3.877-1.194h-8.846v22.207h-4.28V10.69Z"
                    style={{
                        fill: fillColor,
                        fillOpacity: 1,
                    }}
                />
            </g>
        </svg>
    )
}
