import { useCallback, MouseEvent, ReactNode } from "react"
import { css } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { Box } from "../base/Box"
import { Flex } from "../base/Flex"
import { Text } from "../typography/Text"
import { MarkdownView } from "../base/MarkdownView"
import { Markdown } from "../../../../../../reactor"
import { RedoitSpacing, useRedoitTheme } from "../../theme"

const boxStyle = css({
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 24,
    height: 24,
    width: 24,
    display: "flex",
})

export function RadioButton(props: {
    /**
     * Whether the checkbox is checked or not.
     */
    checked: boolean

    /**
     * Name for the radio button, relevant if part of a form.
     */
    name?: string

    /**
     * Whether this element is available for interaction or not.
     */
    disabled?: boolean

    /**
     * A label to show next to the radio button.
     * @reflection any
     */
    label?: string | Markdown | ReactNode

    padding?: RedoitSpacing

    /**
     * @reflection any
     */
    onClick?: (e: MouseEvent) => void
}) {
    const { colors } = useRedoitTheme()
    const handleClick = useCallback(
        (e: MouseEvent) => {
            if (typeof props.onClick === "function") props.onClick(e)
        },
        [props.checked]
    )

    return (
        <Flex
            as="label"
            alignItems="center"
            style={{
                cursor: props.disabled ? "default" : "pointer",
                position: "relative",
            }}
            padding={props.padding}
        >
            <Box
                onClick={handleClick}
                backgroundColor={
                    props.disabled
                        ? props.checked
                            ? "gray200"
                            : "gray100"
                        : props.checked
                          ? "brand"
                          : "grayWhite"
                }
                borderColor={props.disabled ? "gray200" : props.checked ? "brand" : "gray200"}
                css={css(boxStyle, { cursor: props.disabled ? "default" : "pointer" })}
            >
                <input
                    type="radio"
                    disabled={props.disabled}
                    style={{ opacity: 0, position: "absolute", cursor: "inherit" }}
                />
                {props.checked && (
                    <div
                        css={css({
                            height: "100%",
                            width: "100%",
                            borderRadius: 24,
                            border: `2px solid ${colors.grayWhite}`,
                        })}
                    />
                )}
            </Box>
            {props.label && (
                <Text variant="body" size="md" margin={{ left: 16 }}>
                    {typeof props.label === "string" ? (
                        <MarkdownView
                            value={props.label}
                            renderers={{
                                paragraph: (p) => <div>{p.children}</div>,
                            }}
                        />
                    ) : (
                        props.label
                    )}
                </Text>
            )}
        </Flex>
    )
}

Component(RadioButton, {
    name: "RadioButton",
    gallery: {
        path: "Controllers/RadioButton",
        items: [
            {
                title: "Enabled",
                variants: [
                    {
                        label: "Interactive",
                        props: (state = { checked: false }, setState) => ({
                            checked: state.checked,
                            onClick: () => {
                                setState({ checked: !state.checked })
                            },
                        }),
                    },
                    { label: "Checked", props: { checked: true } },
                ],
            },
            {
                title: "Disabled",
                variants: [
                    { label: "Unchecked", props: { checked: false, disabled: true } },
                    { label: "Checked", props: { checked: true, disabled: true } },
                ],
            },
        ],
    },
})
