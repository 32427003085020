import { ReactNode, Ref } from "react"
import { css } from "@emotion/react"
import { Text } from "../typography/Text"
import { Flex } from "../base/Flex"
import { Color, FixedVariant, useRedoitTheme } from "../../theme"
import { xlScaleFactor } from "../../constants/sizes"

const sizes = {
    md: {
        xs: {
            height: 30,
            paddingLeft: 12,
            paddingRight: 12,
        },
        lg: {
            height: 32,
            paddingLeft: 14,
            paddingRight: 14,
        },
        xl: {
            height: 36 * xlScaleFactor,
            paddingLeft: 16 * xlScaleFactor,
            paddingRight: 16 * xlScaleFactor,
        },
    },
}

export const LabelNotification = function LabelNotification(props: {
    color: Color
    children: ReactNode
    size?: FixedVariant<"md">
    elementRef?: Ref<HTMLDivElement>
}) {
    const { helpers } = useRedoitTheme()
    const { fixedVariantCss, responsiveVariantsCss } = helpers

    return (
        <Flex
            elementRef={props.elementRef}
            alignItems="center"
            backgroundColor={props.color}
            color="grayWhite"
            borderRadius="md"
            css={css(
                {
                    display: "inline-flex",
                },
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                props.size instanceof Array && props.size[0] === "fixed"
                    ? fixedVariantCss(sizes, props.size)
                    : responsiveVariantsCss(sizes, "md")
            )}
        >
            <Text variant="label" size={props.size ?? "md"}>
                {props.children}
            </Text>
        </Flex>
    )
}
