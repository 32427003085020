import { CSSProperties } from "react"
import { css, jsx } from "@emotion/react"
import { createBox, BoxProps } from "./Box"
import { ResponsiveStyleValueGeneric, ScreenSizeGeneric, ThemeAny, useTheme } from "../../theme"

type ElementTypeMap = {
    a: HTMLAnchorElement
    button: HTMLButtonElement
    div: HTMLDivElement
    label: HTMLLabelElement
    nav: HTMLElement
    section: HTMLElement
}

type Element = keyof ElementTypeMap

type FlexProps<T extends ThemeAny, E extends Element = "div"> = BoxProps<T, E> & {
    direction?: CSSProperties["flexDirection"]
    flex?: CSSProperties["flex"]
    basis?: CSSProperties["flexBasis"]
    grow?: CSSProperties["flexGrow"]
    shrink?: CSSProperties["flexShrink"]
    alignItems?: CSSProperties["alignItems"]
    justifyContent?: CSSProperties["justifyContent"]
    alignSelf?: CSSProperties["alignSelf"]
    gap?: ResponsiveStyleValueGeneric<ScreenSizeGeneric<T>>
}

const Box = createBox<ThemeAny>()

export function createFlex<T extends ThemeAny = never>() {
    return function Flex<E extends Element = "div">({
        direction,
        flex,
        basis,
        grow,
        shrink,
        alignItems,
        justifyContent,
        alignSelf,
        gap,
        children,
        ...rest
    }: FlexProps<T, E>) {
        const theme = useTheme<T>()

        return jsx(Box, {
            ...rest,
            css: css(
                {
                    display: "flex",
                    flex,
                    flexBasis: basis,
                    flexGrow: grow,
                    flexShrink: shrink,
                    flexDirection: direction,
                    alignItems,
                    justifyContent,
                    alignSelf,
                },
                gap && theme.helpers.responsiveValueCss("gap", gap)
            ),
            children,
        })
    }
}
