import { css } from "@emotion/react"
import { FontWeight, RedoitTheme } from "../../theme"
import {
    createText,
    TextElementTypeMap,
    TextProps,
} from "../../../../../../packages/web-ui/components/typography/Text"

const RedoitText = createText<RedoitTheme>()

type Element = keyof TextElementTypeMap

export function Text<E extends Element = "div">({
    fontWeight,
    children,
    ...rest
}: TextProps<RedoitTheme, E> & {
    fontWeight?: FontWeight
}) {
    return (
        <RedoitText css={!!fontWeight && css({ "&&": { fontWeight } })} {...rest}>
            {children}
        </RedoitText>
    )
}
