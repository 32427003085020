import {
    ReactNode,
    useCallback,
    MouseEvent,
    ComponentPropsWithoutRef,
    createRef,
    RefObject,
} from "react"
import { css, jsx } from "@emotion/react"
import {
    ButtonSizeGeneric,
    ButtonVariantGeneric,
    ResponsiveVariantGeneric,
    ScreenSizeGeneric,
    SpacingGeneric,
    ThemeAny,
    useTheme,
} from "../../theme"

export type ButtonElementTypeMap = {
    a: HTMLAnchorElement
    button: HTMLButtonElement
    div: HTMLDivElement
}

type Element = keyof ButtonElementTypeMap

export type ButtonProps<T extends ThemeAny, E extends Element> = {
    as?: E
    variant: ButtonVariantGeneric<T>
    size: ResponsiveVariantGeneric<ScreenSizeGeneric<T>, ButtonSizeGeneric<T>>

    /**
     * Whether the button is disabled or not.
     */
    disabled?: boolean

    /**
     * Pass a ref that will be attached to the button.
     */
    elementRef?: RefObject<ButtonElementTypeMap[E]>

    /**
     * Click handler for the button.
     * @reflection any
     */
    onClick?: (e: MouseEvent) => void

    /**
     * Add spacing to other elements.
     */
    margin?: SpacingGeneric<T>

    /**
     * Set to true if button should take up all width of parent element.
     */
    fullwidth?: boolean

    /**
     * @reflection any
     */
    children?: ReactNode

    /**
     * Only supported for gallery to add additional styles to present states like hover and focus
     * without actually hovering or focusing. Looking for other way to achieve this, so support
     * for this prop can be dropped at any time.
     *
     * @deprecated
     */
    className?: string
} & Omit<ComponentPropsWithoutRef<E>, "ref">

export function createButton<T extends ThemeAny>() {
    return function Button<E extends Element = "button">({
        as,
        variant,
        size,
        onClick,
        margin,
        fullwidth,
        children,
        elementRef,
        className,
        ...rest
    }: ButtonProps<T, E>) {
        const { helpers, buttonSizes, buttonVariants } = useTheme<T>()
        const { variantCss, responsiveVariantsCss, spacingToCss } = helpers

        const buttonCss = css([
            variantCss(buttonVariants, variant),
            margin && spacingToCss("margin", margin),
            responsiveVariantsCss(buttonSizes, size),
            {
                width: fullwidth ? "100%" : "auto",
            },
        ])

        const ref = elementRef ?? createRef<ButtonElementTypeMap[E]>()

        const handleClick = useCallback(
            (e: MouseEvent) => {
                onClick?.(e)

                ref.current?.blur()
            },
            [onClick, ref]
        )

        const commonProps = {
            css: buttonCss,
            onClick: handleClick,
            className: className ? className : "",
            disabled: rest.disabled,
            ...rest,
        }

        // TODO: Render as <Link to={props.href} /> if as === "a". Only problem is ref type error.
        const El = as ?? "button"

        return jsx(
            El,
            {
                ...rest,
                ...commonProps,
                ref,
            },
            children
        )
    }
}
