import {
    ForwardedRef,
    ReactNode,
    RefObject,
    createRef,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react"
import { server } from "../../../../../../server"
import { Image, Markdown } from "../../../../../../reactor"
import { Component } from "../../../../../../packages/editing/Component"
import { WithOverlayCard } from "../cards/WithOverlay"
import { css } from "@emotion/react"

export type CarouselBasePosition = {
    atStart: boolean
    atEnd: boolean
}

type CarouselBaseProps<T> = {
    items: T[]
    onPositionChange?: (position: CarouselBasePosition) => void
    renderContainer: (
        children: ReactNode,
        scrollContainerRef: RefObject<HTMLDivElement>
    ) => ReactNode
    renderItem: (item: T, index: number) => ReactNode
}

export type CarouselBaseRef = {
    next: () => void
    prev: () => void
}

/**
 * Base carousel that takes a set of cards.
 */
export const CardsCarousel = forwardRef(function CardsCarousel<T>(
    props: CarouselBaseProps<T>,
    ref: ForwardedRef<CarouselBaseRef>
) {
    const dragContainerRef = useRef<HTMLDivElement>(null)
    const cardsContainerRef = useRef<HTMLDivElement>(null)
    const cardsRef = useRef<RefObject<HTMLDivElement>[]>(props.items.map(() => createRef()))
    const scrollContainerRef = useRef<HTMLDivElement>(null)

    const [position, setPosition] = useState({ atStart: true, atEnd: false })

    // Returns a set of DOMRects used frequently in the component.
    function getRects() {
        const container = dragContainerRef.current?.getBoundingClientRect()
        const grid = cardsContainerRef.current?.getBoundingClientRect()
        const firstCard = cardsRef.current[0]?.current?.getBoundingClientRect()
        const lastCard =
            cardsRef.current[cardsRef.current.length - 1]?.current?.getBoundingClientRect()

        if (!container || !grid || !firstCard || !lastCard) {
            // eslint-disable-next-line no-console
            console.warn("Missing reference to container, card, or grid.")
            return
        }

        return {
            container,
            grid,
            firstCard,
            lastCard,
        }
    }

    const updatePosition = useCallback(() => {
        const rects = getRects()
        if (!rects || !scrollContainerRef.current) return
        const newPosition = {
            atStart: Math.floor(scrollContainerRef.current.scrollLeft) === 0,
            atEnd:
                Math.floor(
                    scrollContainerRef.current.scrollWidth -
                        scrollContainerRef.current.scrollLeft -
                        rects.container.width
                ) === 0,
        }
        if (newPosition.atStart === position.atStart && newPosition.atEnd === position.atEnd) {
            return
        }

        setPosition(newPosition)
    }, [position, setPosition])

    const handleScrollEnd = useCallback(() => {
        updatePosition()
    }, [updatePosition])

    useEffect(() => {
        const currentScrollContainerRef = scrollContainerRef.current
        if (currentScrollContainerRef) {
            currentScrollContainerRef.addEventListener("scrollend", handleScrollEnd)

            return () => {
                currentScrollContainerRef.removeEventListener("scrollend", handleScrollEnd)
            }
        }
    }, [handleScrollEnd])

    // Call onPositionChange when position changes.
    useEffect(() => {
        props.onPositionChange?.(position)
    }, [position, props])

    // Ref functions that allow other components consuming this one to trigger next/prev switch.
    useImperativeHandle(ref, () => ({
        next() {
            handleSlideClick("next")
        },
        prev() {
            handleSlideClick("prev")
        },
    }))
    const debouncedUpdatePosition = useMemo(() => debounce(updatePosition, 500), [updatePosition])

    useEffect(() => {
        // The intersection observer is used to observe when any of the cards intersect with
        // the container.
        const intersectionObserver = new IntersectionObserver(debouncedUpdatePosition, {
            root: dragContainerRef.current,
            rootMargin: "0px",
            threshold: 1.0,
        })

        for (const c of cardsRef.current) {
            if (c.current) intersectionObserver.observe(c.current)
        }

        // We need the mutation observer to observe for changes in position controlled by style
        // attribute of child elements. Because of that it's difficult to trigger in a better way.
        const mutationObserver = new MutationObserver(debouncedUpdatePosition)

        if (cardsContainerRef.current) {
            mutationObserver.observe(cardsContainerRef.current, {
                attributes: true,
                subtree: true,
            })
        }

        return () => {
            mutationObserver.disconnect()
            intersectionObserver.disconnect()
        }
    }, [debouncedUpdatePosition])

    // Just copied from CardsBlock, probably a good candidate for rafactoring.
    const handleSlideClick = useCallback((direction: "prev" | "next") => {
        if (!dragContainerRef.current) return

        // Direction is the direction the cards will slide in, which is the opposite of the way the
        // arrow on the button is pointing.
        const slideDirection = direction === "prev" ? "right" : "left"
        const containerRect = dragContainerRef.current.getBoundingClientRect()

        try {
            // Find first card that is partially on the inside and partially on the outside of
            // container. Or the card before a card that is just inside the container This is
            // the card we'll want to move to the edge of the container.
            const nextCardIndex = cardsRef.current.findIndex((item, index) => {
                const itemRect = item.current?.getBoundingClientRect()
                if (!itemRect) throw new Error(`No element for card item at index ${index}.`)
                if (slideDirection === "left") {
                    return itemRect.right > containerRect.right
                } else {
                    const nextRect = cardsRef.current[index + 1]?.current?.getBoundingClientRect()

                    if (!nextRect) {
                        return true
                    }

                    return (
                        // If the next card is on the edge of the container, this is the next.
                        (itemRect.left < containerRect.left &&
                            Math.round(nextRect.left) === Math.round(containerRect.left)) ||
                        // Left edge of element is outside, but has right edge inside container
                        (itemRect.left < containerRect.left &&
                            itemRect.right > containerRect.left) ||
                        // Entire element is outside, but next is inside, return this
                        (itemRect.left < containerRect.left && nextRect.left >= containerRect.left)
                    )
                }
            })

            const nextCard = cardsRef.current[nextCardIndex]
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (nextCard?.current) {
                nextCard.current.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: nextCardIndex === props.items.length - 1 ? "end" : "start",
                })
            }
        } catch (err) {
            //eslint-disable-next-line no-console
            console.warn(err)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div ref={dragContainerRef}>
            <div ref={cardsContainerRef}>
                {props.renderContainer(
                    props.items.map((item, index) => (
                        <div
                            key={index}
                            ref={cardsRef.current[index]}
                            css={css({
                                scrollSnapAlign: index === props.items.length - 1 ? "end" : "start",
                            })}
                        >
                            {props.renderItem(item, index)}
                        </div>
                    )),
                    scrollContainerRef
                )}
            </div>
        </div>
    )
})

type Demo = string
const arr: Demo[] = [...new Array(3)].map(() => "Hello, World!")

Component(CardsCarousel as any, {
    name: "CardsCarousel",
    gallery: {
        items: [
            {
                variants: [
                    {
                        props: {
                            items: arr,
                            renderContainer: (children: any) => (
                                <div
                                    style={{
                                        display: "grid",
                                        gridAutoFlow: "column",
                                        gap: 16,
                                        gridAutoColumns: "min-content",
                                    }}
                                >
                                    {children}
                                </div>
                            ),
                            renderItem: (_: any, index: any) => (
                                <WithOverlayCard
                                    title={`Hello ${index}${index}${index}${index}`}
                                    text={Markdown(`${index}`)}
                                    image={
                                        `${server()}/static/redoit/how-it-works-card-illustration-3.svg` as any as Image
                                    }
                                    readMoreAriaPrefix="Read more"
                                />
                            ),
                        },
                    },
                ],
            },
        ],
    },
})

function debounce(callback: (...args: any[]) => void, wait: number) {
    let timeoutId: number | undefined
    return (...args: any[]) => {
        if (typeof window === "undefined") callback(...args)
        window.clearTimeout(timeoutId)
        timeoutId = window.setTimeout(() => {
            callback(...args)
        }, wait)
    }
}
