import {
    Dispatch,
    Fragment,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
} from "react"
import { css } from "@emotion/react"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { useMe, useShippingOptions } from "../../client"
import { Heading } from "../../ui/components/typography/Heading"
import { TextInput } from "../../ui/components/controllers/TextInput"
import { RadioButton } from "../../ui/components/controllers/Radio"
import { Checkbox } from "../../ui/components/controllers/Checkbox"
import { Button } from "../../ui/components/buttons/Button"
import { useCheckoutContext } from "./CheckoutContext"
import { scaleValue } from "../../ui/helpers/css"
import { Flex } from "../../ui/components/base/Flex"
import { IconName, useRedoitTheme } from "../../ui/theme"
import { Email, Markdown, PhoneNumber, Uuid } from "../../../../../reactor"
import { Localized } from "../../../../../packages/localization/Localized"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { NextStepsBlock } from "../../ui/components/blocks/NextStepsBlock"
import { Text } from "../../ui/components/typography/Text"
import { Divider } from "../../ui/components/other/Divider"
import { CheckoutShippingState, useCheckoutConsents } from "../Checkout"
import { Tooltip } from "../../ui/components/base/Tooltip"
import { ShippingType } from "../../../model/Shipping"
import { useFormatAmount } from "../../ui/constants/text"
import { MarkdownView } from "../../ui/components/base/MarkdownView"

/**
 * Declares the global `fbq` function for Meta Pixel.
 */
declare const fbq: undefined | ((eventType: string, eventName: string, ...args: any[]) => void)

type ContactInfo = {
    name?: {
        /**
         * @default '{"en": "Name", "no": "Navn"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        errorMessage?: Localized<string>
    }
    email?: {
        /**
         * @default '{"en": "Email", "no": "Epostadresse"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        type?: "email"
        /**
         * @default '{"no": "Sjekk at du har oppgitt en gyldig epost-adresse."}'
         */
        errorMessage?: Localized<string>
    }
    phoneNumber?: {
        /**
         * @default '{"en": "Phone", "no": "Telefonnummer"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        type?: "tel"
        /**
         * @default '{"no": "Sjekk at du har oppgitt et gyldig telefonnummer, og at landkode (+47 for Norge f.eks.) er med."}'
         */
        errorMessage?: Localized<string>
    }
}

type Address = {
    street?: {
        /**
         * @default '{"en": "Street", "no": "Gateadresse"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        errorMessage?: Localized<string>
    }

    unit?: {
        /**
         * @default '{"en": "Unit", "no": "Enhet"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        errorMessage?: Localized<string>
    }
    region?: {
        /**
         * @default '{"en": "City", "no": "Poststed"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        errorMessage?: Localized<string>
    }
    postalCode?: {
        /**
         * @default '{"en":"Postal Code", "no": "Postnummer"}'
         */
        label: Localized<string>
        group?: number
        order?: number
        errorMessage?: Localized<string>
    }
}

type FormKey = keyof ContactInfo | keyof Address | "countryCode"

type FormField = {
    label: Localized<string>
    formKey: FormKey
    group: number
    order: number
    type: "text" | "tel" | "email"
    errorMessage?: Localized<string>
}

type NextSteps = {
    readonly id: Uuid
    /**
     * @title
     */
    text: Localized<Markdown>
    icon: IconName
}
export type CheckoutShippingFormState = Record<
    FormKey | "countryCode",
    { value: string; valid?: boolean; dirty?: boolean }
>

type Action =
    | { type: keyof CheckoutShippingFormState; payload: string }
    | { type: "SET_ALL"; payload: Record<keyof CheckoutShippingFormState, string> }

const initialState: CheckoutShippingFormState = {
    name: { value: "" },
    email: { value: "" },
    phoneNumber: { value: "" },
    street: { value: "" },
    unit: { value: "" },
    region: { value: "" },
    postalCode: { value: "" },
    countryCode: { value: "" },
}

function formReducer(state: CheckoutShippingFormState, action: Action): CheckoutShippingFormState {
    if (action.type === "email") {
        try {
            Email(action.payload)
            return { ...state, email: { value: action.payload, valid: true, dirty: true } }
        } catch (e) {
            return { ...state, email: { value: action.payload, valid: false, dirty: true } }
        }
    }
    if (action.type === "phoneNumber") {
        try {
            PhoneNumber(action.payload)
            return { ...state, phoneNumber: { value: action.payload, valid: true, dirty: true } }
        } catch (e) {
            return { ...state, phoneNumber: { value: action.payload, valid: false, dirty: true } }
        }
    }
    if (action.type === "unit") {
        return { ...state, unit: { value: action.payload, valid: true, dirty: true } }
    }
    if (action.type !== "SET_ALL") {
        return {
            ...state,
            [action.type]: { value: action.payload, dirty: true, valid: !!action.payload },
        }
    } else {
        return {
            ...state,
            ...Object.keys(action.payload).reduce((acc, key) => {
                acc[key as FormKey] = {
                    value: action.payload[key as FormKey],
                    valid: true,
                    dirty: true,
                }
                return acc
            }, {} as Partial<CheckoutShippingFormState>),
        }
    }
}

export type ShippingStepProps = {
    /**
     * Name of this step, used in checkout step navigation bar, and possibly other places the
     * step is referenced, like the summary page.
     *
     * @default '{"en": "Shipping", "no": "Levering"}'
     */
    name: Localized<string>

    /**
     * Shipping step identifier to use in the URL.
     * @default '{"en": "shipping", "no": "levering"}'
     */
    slug: Localized<string>

    /**
     * @default '{"en": "Shipping"}'
     */
    pageHeading: Localized<string>

    /**
     * @default '{"en": "Personal information", "no": "Personopplysninger"}'
     */
    personalInformationHeading: Localized<string>

    personalInformationFromVippsNotice?: Localized<Markdown>

    /**
     * @default '{"en": "Delivery address", "no": "Leveringsadresse"}'
     */
    addressHeading: Localized<string>
    /**
     * @default '{"en": "Delivery options", "no": "Leveringsalternativer"}'
     */
    deliveryOptionsHeading: Localized<string>
    /**
     * @default '{"en": "Unfortunately we have no delivery options available for your address", "no": "Vi har dessverre ingen leveringsalternativer til din adresse"}
     */
    noDeliveryOptions: Localized<string>

    /**
     * Text to show in tooltip for delivery slots that are fully booked.
     *
     * @default '{"no": "Leveringstidspunktet er ikke tilgjengelig."}'
     */
    deliverySlotFull: Localized<string>

    /**
     * @default '{"en": "No available times", "no": "Ingen tilgjengelige tider"}
     */
    noDeliverySlots: Localized<string>

    marketingCheckbox: Localized<Markdown>
    termsCheckbox: Localized<Markdown>

    /**
     * @default '{"en": "Proceed to payment", "no": "Gå til betaling"}'
     */
    nextStep: Localized<string>
    contactInfo: ContactInfo
    address: Address
    nextStepsHeading: Localized<string>
    nextSteps: NextSteps[]
}
export function ShippingStep({
    contactInfo: c,
    address: a,
    nextSteps,
    pageHeading,
    personalInformationHeading,
    personalInformationFromVippsNotice,
    addressHeading,
    deliveryOptionsHeading,
    noDeliveryOptions,
    noDeliverySlots,
    deliverySlotFull,
    marketingCheckbox,
    termsCheckbox,
    nextStep,
    nextStepsHeading,
}: ShippingStepProps) {
    const { helpers, colors } = useRedoitTheme()
    const { responsiveCss } = helpers
    const me = useMe()
    const localize = useLocalize()
    const { setStep, shipping, setShipping, price, checkoutState, setCheckoutState } =
        useCheckoutContext()
    const [consents, setConsents] = useCheckoutConsents()

    dayjs.extend(advancedFormat)

    const [state, dispatch] = useReducer(formReducer, {
        ...initialState,
        ...(shipping.form ?? {}),
    })
    const validate = useCallback(
        () =>
            !Object.keys(state).some(
                (key) => !state[key as FormKey].valid || !state[key as FormKey].dirty
            ),
        [state]
    )
    const formatAmount = useFormatAmount()

    const contactInfo = groupAndSort(c)
    const address = groupAndSort(a)
    // Don't request shipping options unless complete zip code is provided, to avoid unnecessary
    // requests and also the UI from showing "no options" while typing (if switching form one
    // valid zip code to another).
    const shippingOptions = useShippingOptions(
        "NO",
        state.postalCode.value.length === 4 ? state.postalCode.value : null
    )

    useEffect(
        () => {
            if (typeof fbq !== "undefined") {
                fbq("track", "InitiateCheckout", {
                    content_ids: [price?.phoneOffer.id.valueOf()],
                    content_name: price?.phoneOffer.name.en,
                    content_type: "product",
                    value: price?.monthlyCost.discountedTotal.majorUnits,
                    currency: price?.monthlyCost.discountedTotal.currency,
                })
            }
        },
        // Should only trigger once, when the component mounts
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const availableShippingOptions = useMemo(
        () =>
            shippingOptions.data?.options.filter((so) => {
                if (so.type === "Manual") return so.slots.filter((s) => !s.full).length > 0
                return true
            }),
        [shippingOptions.data?.options]
    )

    // When shipping options are ready, update the options and auto-select first
    // option (that is not full).
    useEffect(() => {
        let updatedState: CheckoutShippingState
        if (!availableShippingOptions) {
            // If data?.options is undefined, it means no options have been fetched, which should be
            // because zip code is incomplete. When that's the case, we don't want to change the
            // presented options.
            return
        } else if (!availableShippingOptions.length) {
            // Shipping options are fetched, but came up empty, update state to show
            // "no options available".
            updatedState = { ...shipping, options: [], selectedOption: undefined }
        } else {
            // Keep selection, or select first if none was set.
            const selectedOption =
                (shipping.selectedOption
                    ? availableShippingOptions.find((o) => o.type === shipping.selectedOption?.type)
                    : undefined) ?? availableShippingOptions[0]

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!selectedOption) return

            const previouslySelectedSlot =
                selectedOption.type === "Manual"
                    ? selectedOption.slots.find(
                          (s) =>
                              shipping.selectedOption?.type === "Manual" &&
                              s.id === shipping.selectedOption.selectedSlot?.id
                      )
                    : undefined
            updatedState = {
                ...shipping,
                options: availableShippingOptions,
                selectedOption: {
                    ...selectedOption,
                    ...(shipping.selectedOption?.type === "Manual"
                        ? {
                              selectedSlot:
                                  previouslySelectedSlot ??
                                  (selectedOption.type === "Manual"
                                      ? selectedOption.slots.find((s) => !s.full)
                                      : undefined),
                          }
                        : {}),
                },
            }
        }

        if (JSON.stringify(updatedState) !== JSON.stringify(shipping.options)) {
            setShipping(updatedState)
        }
    }, [availableShippingOptions, setShipping, shipping])

    // Ensures the CheckoutShippingState stored on the session is up to date with the form state.
    useEffect(() => {
        if (JSON.stringify(state) === JSON.stringify(initialState)) return

        const updatedShipping: CheckoutShippingState = {
            ...shipping,
            form: state,
        }
        if (JSON.stringify(updatedShipping) !== JSON.stringify(shipping)) {
            setShipping(updatedShipping)
        }
    }, [setShipping, shipping, state])

    const shippingSlotId =
        shipping.selectedOption?.type === "Manual"
            ? shipping.selectedOption.selectedSlot?.id
            : undefined
    const shippingIsValid = useMemo(() => {
        if (shipping.selectedOption?.type === "Manual") {
            return !!shippingSlotId
        }
        return !!shipping.selectedOption
    }, [shipping.selectedOption, shippingSlotId])

    useEffect(() => {
        if (!me.data) return
        if (shipping.form) return

        dispatch({
            type: "SET_ALL",
            payload: {
                name: `${me.data.givenName} ${me.data.familyName}`,
                email: me.data.email?.valueOf() ?? "",
                phoneNumber: me.data.phoneNumber
                    ? me.data.phoneNumber.valueOf().startsWith("+")
                        ? me.data.phoneNumber.valueOf()
                        : "+" + me.data.phoneNumber.valueOf()
                    : "",
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                street: me.data.address?.street ?? "",
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                unit: me.data.address?.unit ?? "",
                countryCode: me.data.address?.country.valueOf() ?? "",
                postalCode: me.data.address?.zip ?? "",
                region: me.data.address?.city ?? "",
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(me.data)])

    const handleShippingSelect = useCallback(
        (shippingType: ShippingType) => () => {
            const opt = shipping.options?.find((so) => so.type === shippingType)
            if (opt) {
                setShipping({ ...shipping, selectedOption: opt })
            }
        },
        [setShipping, shipping]
    )

    return (
        <div>
            <Flex direction="column" css={css({ gap: 32 })}>
                <span css={css(responsiveCss("max", "sm", { display: "none" }))}>
                    <Heading level={2}>{localize(pageHeading)}</Heading>
                </span>
                <SectionHeading title={localize(personalInformationHeading)}>
                    <Flex direction="column" gap={8}>
                        {contactInfo.map((g) => {
                            if (Array.isArray(g)) {
                                return (
                                    <InputGroup
                                        key={g.join("")}
                                        group={g}
                                        state={state}
                                        localize={localize}
                                        dispatch={dispatch}
                                    ></InputGroup>
                                )
                            } else {
                                return (
                                    <TextInput
                                        type={g.type}
                                        label={localize(g.label)}
                                        key={g.formKey}
                                        value={state[g.formKey].value}
                                        disabled={["name", "email", "phoneNumber"].includes(
                                            g.formKey
                                        )}
                                        onChange={(e) =>
                                            dispatch({
                                                type: g.formKey,
                                                payload: e.target.value,
                                            })
                                        }
                                        warning={
                                            setFormFieldState(state, g.formKey) === "error" &&
                                            g.errorMessage
                                                ? localize(g.errorMessage)
                                                : undefined
                                        }
                                        state={setFormFieldState(state, g.formKey)}
                                    />
                                )
                            }
                        })}
                    </Flex>
                    {personalInformationFromVippsNotice ? (
                        <Text variant="body" size="md" color="gray300" margin={{ top: 16 }}>
                            <MarkdownView value={localize(personalInformationFromVippsNotice)} />
                        </Text>
                    ) : null}
                </SectionHeading>
                <SectionHeading title={localize(addressHeading)}>
                    <Flex direction="column" gap={8}>
                        {address.map((g) => {
                            if (Array.isArray(g)) {
                                return (
                                    <InputGroup
                                        key={g.join("")}
                                        group={g}
                                        state={state}
                                        localize={localize}
                                        dispatch={dispatch}
                                    ></InputGroup>
                                )
                            } else {
                                return (
                                    <TextInput
                                        type={g.type}
                                        label={localize(g.label)}
                                        key={g.formKey}
                                        value={state[g.formKey].value}
                                        onChange={(e) =>
                                            dispatch({
                                                type: g.formKey,
                                                payload: e.target.value,
                                            })
                                        }
                                        state={setFormFieldState(state, g.formKey)}
                                    />
                                )
                            }
                        })}
                    </Flex>
                </SectionHeading>
                <SectionHeading title={localize(deliveryOptionsHeading)}>
                    <Flex
                        borderRadius="md"
                        direction="column"
                        css={css(
                            responsiveCss("min", "md", {
                                padding: scaleValue(40),
                                background: colors.gray100,
                                border: "1px solid",
                                borderColor: colors.gray200,
                            })
                        )}
                    >
                        {shipping.options?.length === 0 ? (
                            <Text variant="body" size="md">
                                {localize(noDeliveryOptions)}
                            </Text>
                        ) : (
                            shipping.options?.map((option, index) => (
                                <Fragment key={localize(option.name)}>
                                    {index > 0 && <Divider horizontal spacing={24} />}
                                    <Flex
                                        direction="column"
                                        gap={24}
                                        css={css(
                                            responsiveCss("max", "sm", {
                                                gap: 16,
                                            }),
                                            responsiveCss("min", "md", {
                                                gap: 24,
                                            })
                                        )}
                                    >
                                        <RadioButton
                                            checked={shipping.selectedOption?.type === option.type}
                                            onClick={handleShippingSelect(option.type)}
                                            label={
                                                <div>
                                                    <Text
                                                        variant="heading"
                                                        level={[3, ["min", "md", 4]]}
                                                        color={
                                                            shipping.selectedOption?.type ===
                                                            option.type
                                                                ? "brand"
                                                                : undefined
                                                        }
                                                    >
                                                        {localize(option.name)} -{" "}
                                                        {!option.price.minorUnits.valueOf()
                                                            ? localize({ no: "Gratis", en: "Free" })
                                                            : formatAmount(option.price)}
                                                    </Text>
                                                    <Text variant="body" size="sm" color="gray350">
                                                        {localize(option.description)}
                                                    </Text>
                                                </div>
                                            }
                                        />
                                        {option.type === "Manual" ? (
                                            <Flex
                                                gap={8}
                                                css={css(
                                                    responsiveCss("max", "sm", {
                                                        flexWrap: "nowrap",
                                                        overflowX: "auto",
                                                        width: "calc(100% + var(--padding-x) + var(--padding-x))",
                                                        transform:
                                                            "translateX(calc(var(--padding-x) * -1))",
                                                        paddingLeft: "var(--padding-x)",
                                                        paddingRight: "var(--padding-x)",
                                                        minWidth: 0,
                                                    }),
                                                    { flexWrap: "wrap" }
                                                )}
                                            >
                                                {option.slots.map((slot) => (
                                                    <Tooltip
                                                        key={slot.id.valueOf()}
                                                        placement="bottom-center"
                                                        renderTrigger={(ref) => (
                                                            <div ref={ref as any}>
                                                                <Button
                                                                    size="sm"
                                                                    variant="option"
                                                                    selected={
                                                                        shipping.selectedOption
                                                                            ?.type === "Manual" &&
                                                                        shipping.selectedOption
                                                                            .selectedSlot?.id ===
                                                                            slot.id
                                                                    }
                                                                    key={slot.id.valueOf()}
                                                                    disabled={slot.full}
                                                                    onClick={
                                                                        slot.full
                                                                            ? undefined
                                                                            : () =>
                                                                                  setShipping({
                                                                                      ...shipping,
                                                                                      selectedOption:
                                                                                          {
                                                                                              ...option,
                                                                                              selectedSlot:
                                                                                                  slot,
                                                                                          },
                                                                                  })
                                                                    }
                                                                >
                                                                    {dayjs(slot.date).format(
                                                                        "dddd Do MMMM"
                                                                    )}
                                                                    , {slot.from} - {slot.to}
                                                                </Button>
                                                            </div>
                                                        )}
                                                    >
                                                        {slot.full ? (
                                                            <Text variant="body" size="sm">
                                                                {localize(deliverySlotFull)}
                                                            </Text>
                                                        ) : null}
                                                    </Tooltip>
                                                ))}

                                                {option.slots.length === 0 && (
                                                    <div style={{ padding: 32 }}>
                                                        {localize(noDeliverySlots)}
                                                    </div>
                                                )}
                                            </Flex>
                                        ) : null}
                                    </Flex>
                                </Fragment>
                            ))
                        )}
                    </Flex>
                </SectionHeading>
                {nextSteps.length > 0 && (
                    <SectionHeading title={localize(nextStepsHeading)}>
                        <NextStepsBlock
                            steps={nextSteps.map((s) => ({
                                id: s.id.valueOf(),
                                icon: s.icon,
                                text: localize(s.text),
                            }))}
                        />
                    </SectionHeading>
                )}
                <div>
                    {!consents.marketing.preConsented && (
                        <>
                            <Checkbox
                                checked={consents.marketing.consentGiven}
                                onChange={() => {
                                    setConsents({
                                        ...consents,
                                        marketing: {
                                            consentGiven: !consents.marketing.consentGiven,
                                            preConsented: false,
                                        },
                                    })
                                }}
                                label={localize(marketingCheckbox)}
                            />
                            <Divider horizontal spacing={24} />
                        </>
                    )}
                    <Checkbox
                        checked={checkoutState.termsAccepted}
                        onChange={() =>
                            setCheckoutState({
                                ...checkoutState,
                                termsAccepted: !checkoutState.termsAccepted,
                            })
                        }
                        label={localize(termsCheckbox)}
                    />
                </div>
                <Button
                    variant="primary"
                    onClick={() => {
                        setStep("Payment")
                    }}
                    disabled={
                        !validate() ||
                        !shippingIsValid ||
                        !checkoutState.termsAccepted ||
                        !shipping.selectedOption
                    }
                >
                    {localize(nextStep)}
                </Button>
            </Flex>
        </div>
    )
}

type SectionHeadingProps = { title: string }
function SectionHeading({ title, children }: PropsWithChildren<SectionHeadingProps>) {
    const { responsiveCss } = useRedoitTheme()
    return (
        <>
            <div css={css(responsiveCss("min", "md", { display: "none" }))}>
                <Heading level={2} margin={{ bottom: 16 }}>
                    {title}
                </Heading>
                {children}
            </div>
            <div css={css(responsiveCss("max", "sm", { display: "none" }))}>
                <Heading level={3} margin={{ bottom: 16 }}>
                    {title}
                </Heading>
                {children}
            </div>
        </>
    )
}

type InputGroupProps = {
    group: FormField[]
    state: CheckoutShippingFormState
    localize: ReturnType<typeof useLocalize>
    dispatch: Dispatch<Action>
}
function InputGroup({ group, state, localize, dispatch }: InputGroupProps) {
    const { responsiveCss } = useRedoitTheme()
    return (
        <Flex gap={8} css={css(responsiveCss("max", "sm", { flexDirection: "column" }))}>
            {group.map((field) => (
                <div style={{ flex: 1 }} key={field.formKey}>
                    <TextInput
                        type={field.type}
                        disabled={["name", "email", "phoneNumber"].includes(field.formKey)}
                        label={localize(field.label)}
                        value={state[field.formKey].value}
                        onChange={(e) =>
                            dispatch({
                                type: field.formKey,
                                payload: e.target.value,
                            })
                        }
                        state={setFormFieldState(state, field.formKey)}
                        warning={
                            setFormFieldState(state, field.formKey) === "error" &&
                            field.errorMessage
                                ? localize(field.errorMessage)
                                : undefined
                        }
                    />
                </div>
            ))}
        </Flex>
    )
}

const setFormFieldState = (state: CheckoutShippingFormState, key: FormKey) => {
    return state[key].dirty
        ? !state[key].valid
            ? "error"
            : state[key].value
              ? "completed"
              : undefined
        : undefined
}

function groupAndSort(data: Record<string, Partial<FormField>>) {
    const sortedItems: FormField[] = Object.keys(data)
        .map((key) => {
            const value = data[key]
            // TypeScript claims value is always falsy, but this is not the case.
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!value) return

            return {
                formKey: key,
                label: value.label,
                group: value.group ?? 0,
                order: value.order ?? 0,
                type: value.type,
                errorMessage: value.errorMessage,
            }
        })
        .filter(Boolean)

        .sort((a, b) => a!.order - b!.order) as FormField[]

    return sortedItems.reduce(
        (acc, item) => {
            if (!item.group) {
                acc.push(item)
            } else {
                const groupIndex = acc.findIndex(
                    (group) => Array.isArray(group) && group[0]?.group === item.group
                )
                if (groupIndex === -1) {
                    acc.push([item])
                } else if (Array.isArray(acc[groupIndex])) {
                    acc[groupIndex]?.push(item)
                }
            }
            return acc
        },
        [] as (FormField | FormField[])[]
    )
}
