import { Route } from "react-router-dom"
import { css } from "@emotion/react"

// Make generic types work with forwardRef
declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
    ): (props: P & React.RefAttributes<T>) => React.ReactNode | null
}

import "./sections/CardsWithMoreInfo"
import "./sections/Carousel"
import "./sections/Footer"
import "./sections/FrequentlyAskedQuestions"
import "./sections/FullNotification"
import "./sections/Header"
import "./sections/PressLogos"
import "./sections/ProductDetails"
import "./sections/PromotedProducts"
import "./sections/PromotedReviews"
import "./sections/UniqueSellingPoints"
import "./sections/WaitingListSignupModal"
import "./sections/WaitingListTrigger"
import "./sections/AllProducts"
import "./sections/Checkout"
import "./sections/AccountOverview"
import "./sections/SubscriptionOverview"
import "./sections/CancelView"
import "./sections/Breadcrumb"
import "./sections/TextContent"
import "./sections/ImageContent"
import "./sections/ListWithIconsContent"
import "./sections/ListWithStepsContent"
import "./sections/ContentGroup"
import "./sections/ContentDivider"
import "./sections/OrderConfirmation"
import "./sections/PromoCard"
import "./sections/MoreReasonsToRedoit"
import "./sections/TrustpilotRateUs"

import "./ui/components/buttons/Button"
import "./ui/components/buttons/Dropdown"
import "./ui/components/carousel/HighlightCarousel"
import "./ui/components/typography/Heading"
import "./ui/components/cards/WithOverlay"
import "./ui/components/cards/SeeAllCard"
import "./ui/components/cards/DeviceCheckoutCard"
import "./ui/components/controllers/Checkbox"
import "./ui/components/controllers/TextInput"
import "./ui/components/controllers/BoxSelection"
import "./ui/components/controllers/LocaleSelect"
import "./ui/components/base/CarouselBase"
import "./ui/components/controllers/Radio"
import "./ui/components/blocks/BenefitsBlock"
import "./ui/components/blocks/Faq"
import "./ui/components/blocks/StepsBlock"
import "./ui/components/blocks/WhatsInTheBoxBlock"
import "./ui/components/modal/Modal"
import "./ui/components/modal/NavigationModal"
import "./ui/components/modal/WaitingListSignupModal"
import "./ui/components/carousel/PressLogosCarousel"
import "./ui/components/carousel/ReviewsCarousel"
import "./ui/components/carousel/ContinuousSlideCarousel"
import "./ui/components/carousel/CardsCarousel"
import "./ui/components/carousel/ProductCarousel"
import "./ui/components/forms/MultipleChoiceQuestion"
import "./ui/components/forms/PaymentSummary"

import { WebSite } from "../../../packages/web/components/WebSite"
import { useAnalyticsLogger } from "../../../packages/analytics/useAnalyticsLogger"
import { NewsletterUnsubscribePage } from "./pages/NewsletterUnsubscribePage"
import { LocaleKey } from "../../../packages/localization/Locale"
import { CookieConsentView } from "./ui/components/cookie-consent/CookieConsentView"
import { WebPage, WebPageProps } from "../../../packages/web/components/WebPage"
import { useMe } from "./client"
import Intercom from "@intercom/messenger-js-sdk"
import {
    GeneralTermsAndConditions,
    ProtectionPlanTermsAndConditions,
    TermsAndConditions,
} from "./TermsAndConditions"
import { ErrorPage } from "./pages/ErrorPage"
import { ThemeProvider } from "../../../packages/web-ui/theme"
import { globalStyles, pageWidthCss, redoitTheme } from "./ui/theme"

function sectionItemCss() {
    return css(
        {
            margin: "0 auto",
            // Override Bootstrap line height variable.
            "--bs-body-line-height": "1",
            lineHeight: "1",
        },
        pageWidthCss()
    )
}

export function App() {
    useAnalyticsLogger()

    return (
        <ThemeProvider theme={redoitTheme}>
            <WebSite
                gallery={true}
                studio={true}
                studioPlugins={async () => {
                    await import("../email/EmailTemplate")
                }}
                globalStyles={globalStyles}
                EditableSectionsProps={{ itemCss: sectionItemCss }}
                pageComponent={RedoitPage}
                renderCookieConsentView={() => <CookieConsentView />}
                errorScreen={() => <ErrorPage />}
            >
                <Route
                    path="/terms-and-conditions/phone-order/:id"
                    element={<TermsAndConditions />}
                />
                <Route
                    path="/terms-and-conditions/phone-protection-plan"
                    element={<ProtectionPlanTermsAndConditions />}
                />
                <Route
                    path="/terms-and-conditions/phone-order"
                    element={<GeneralTermsAndConditions />}
                />
                <Route
                    path="/system/nyhetsbrev-meld-av"
                    element={<NewsletterUnsubscribePage locale={LocaleKey.no} />}
                />
                <Route
                    path="/system/en/newsletter-unsubscribe"
                    element={<NewsletterUnsubscribePage locale={LocaleKey.en} />}
                />
            </WebSite>
        </ThemeProvider>
    )
}

function RedoitPage(props: WebPageProps) {
    const user = useMe()

    if (user.data && user.data.anonymous === false) {
        Intercom({
            app_id: "wve6w27x",
            user_id: user.data.id?.valueOf(),
            name: `${user.data.givenName} ${user.data.familyName}`,
            email: user.data.email?.valueOf(),
            created_at: user.data.createdAt,
        })
    } else {
        Intercom({
            app_id: "wve6w27x",
        })
    }

    return <WebPage {...props} />
}
