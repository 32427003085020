import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { Uuid, Image, Url } from "../../../../reactor"
import { PressLogosCarousel } from "../ui/components/carousel/PressLogosCarousel"
import { Localized } from "../../../../packages/localization/Localized"
import { Flex } from "../ui/components/base/Flex"
import { Body } from "../ui/components/typography/Body"
import { Heading } from "../ui/components/typography/Heading"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { responsiveSectionSpacing } from "../ui/theme"

function PressLogos(section: {
    /**
     * Heading for the reviews section.
     * @default '{"en": "Medieomtaler"}'
     */
    heading?: Localized<string>

    /**
     * Text to show below the heading.
     */
    text?: Localized<string>

    /**
     * How fast the carousel scrolls. Given in pixels per second.
     *
     * @default 30
     */
    carouselSpeed: number

    logos: {
        readonly id: Uuid<"PressLogo">
        image: Image
        url?: Url
    }[]
}) {
    const localize = useLocalize()
    return (
        <div
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                },
                responsiveSectionSpacing()
            )}
        >
            {!!section.heading && (
                <Flex alignItems="center" justifyContent="space-between">
                    <Heading level={2} padding={{ y: 8 }} truncate>
                        {localize(section.heading)}
                    </Heading>
                </Flex>
            )}
            {!!section.text && (
                <Body size="lg" color="gray400" margin={{ bottom: "sm" }}>
                    {localize(section.text)}
                </Body>
            )}
            <PressLogosCarousel logos={section.logos} speed={section.carouselSpeed} />
        </div>
    )
}
Section(PressLogos)
