import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../../packages/localization/client-side/useLocalize"
import { useFooter } from "../client"
import { Logo } from "../ui/components/visual/Logo"
import { responsiveCss } from "../ui/helpers/css"
import { Body } from "../ui/components/typography/Body"
import { pageSize, sectionSpacing } from "../ui/constants/sizes"
import { useMemo } from "react"
import { useDefaultLocale } from "../../../../studio/client"
import { Url } from "../../../../reactor"

/**
 * Section displaying logo and some links on the bottom of the page.
 *
 * @icon ui-flex-align-bottom
 */
function Footer(section: {}) {
    const localize = useLocalize()
    const footer = useFooter()
    const currentLocale = useCurrentLocale()
    const defaultLocale = useDefaultLocale().data?.defaultLocale

    const footerItems = useMemo(
        () =>
            footer.data?.items.map((item) => {
                const localePath = currentLocale === defaultLocale ? "" : `/${currentLocale}`
                const path = item.slug ? `${localePath}/${localize(item.slug)}` : "/"
                const fragment = item.section ? `#${item.section}` : ""
                const href = item.url && !item.slug ? item.url : Url(`${path}${fragment}`)

                return {
                    id: item.id.valueOf(),
                    href: item.slug || item.section || item.url ? href : undefined,
                    text: localize(item.text),
                }
            }) || [],
        [footer.data?.items, currentLocale, defaultLocale, localize]
    )

    if (!footer.data) return <></>

    return (
        <div
            css={css(
                { paddingTop: sectionSpacing.xs, paddingBottom: pageSize.xs.paddingBottom },
                responsiveCss("min", "md", {
                    paddingTop: sectionSpacing.md,
                    paddingBottom: pageSize.md.paddingBottom,
                })
            )}
        >
            <Logo css={css({ width: "100%", height: "auto" })} />
            <div
                css={css(
                    { display: "flex", marginTop: 32, flexDirection: "column" },
                    responsiveCss("min", "md", {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 24,
                    })
                )}
            >
                <div
                    css={css(
                        { display: "grid", gap: 16 },
                        responsiveCss("min", "md", { gridAutoFlow: "column", gap: 24 })
                    )}
                >
                    {footerItems.map((item) => (
                        <a
                            href={item.href?.valueOf()}
                            key={item.id.valueOf()}
                            style={{ cursor: "pointer" }}
                        >
                            <Body size="md">{item.text}</Body>
                        </a>
                    ))}
                </div>
                <div
                    css={css(
                        { opacity: 0.6, textAlign: "right", marginTop: 32 },
                        responsiveCss("min", "md", { marginTop: 0 })
                    )}
                >
                    <Body size="md">
                        © {new Date().getFullYear()} Redoit · All rights reserved
                    </Body>
                </div>
            </div>
        </div>
    )
}
Section(Footer)
