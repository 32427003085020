import { ReactNode, useMemo } from "react"
import { keyframes } from "@emotion/react"
import { css } from "@emotion/react"
import { Component } from "../../../../../../packages/editing/Component"
import { useRedoitTheme } from "../../theme"

export function ContinuousSlideCarousel(props: {
    /**
     * @reflection any
     */
    items: ReactNode[]
}) {
    const { responsiveCss } = useRedoitTheme()

    const containerCss = useMemo(
        () =>
            css(
                {
                    maxHeight: 64,
                    "&:hover div": {
                        animationPlayState: "paused",
                    },
                    "&:before, &:after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        width: 50,
                        zIndex: 1,
                        pointerEvents: "none",
                    },
                    "&:before": {
                        left: 0,
                        background:
                            "linear-gradient(270deg, rgba(255, 255,255,0), rgba(255, 255,255,1))",
                    },
                    "&:after": {
                        right: 0,
                        background:
                            "linear-gradient(90deg, rgba(255, 255,255,0), rgba(255, 255,255,1))",
                    },
                },
                responsiveCss("min", "md", {
                    "&:before, &:after": {
                        width: 360,
                        maxHeight: 80,
                    },
                })
            ),
        [responsiveCss]
    )

    return (
        <div
            style={{
                position: "relative",
                overflow: "hidden",
                whiteSpace: "nowrap",
            }}
            css={containerCss}
        >
            <div
                style={{ animationDuration: `${props.items.length * 5}s` }}
                css={[responsiveGridCss, animationCss]}
            >
                {props.items.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
            <div
                style={{ animationDuration: `${props.items.length * 5}s` }}
                css={[responsiveGridCss, animationCss]}
            >
                {props.items.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
        </div>
    )
}

const marquee = keyframes({
    from: {
        transform: "translateX(0%)",
    },
    to: {
        transform: "translateX(-100%)",
    },
})

const animationCss = css({
    animationName: marquee,
})

const responsiveGridCss = css({
    display: "inline-flex",
    alignItems: "center",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
})

Component(ContinuousSlideCarousel, {
    name: "ContinuousSlideCarousel",
    gallery: {
        items: [
            {
                variants: [
                    {
                        props: {
                            items: [...new Array(5)].map((item, index) => (
                                <div key={index}>Hello, world!</div>
                            )),
                        },
                    },
                ],
            },
        ],
    },
})
