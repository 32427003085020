import ReactDOM from "react-dom/server"
import { css, CSSObject, SerializedStyles } from "@emotion/react"
import {
    createTheme,
    LimitType,
    useTheme,
    ResponsiveStyleValueGeneric,
    SpacingGeneric,
    FixedVariantGeneric,
    ResponsiveVariantGeneric,
    ButtonVariantGeneric as ButtonVariantGeneric,
    BorderRadiusGeneric,
    ColorGeneric,
    IconNameGeneric,
    SpacingSizeGeneric,
    ScreenSizeGeneric,
    BodySizeGeneric,
    ThemeProvider,
} from "../../../../packages/web-ui/theme"
import { retinaCss } from "../../../../packages/web-ui/helpers/css"
import {
    lgScaleFactor,
    mdScaleFactor,
    pageSize,
    pageWidth,
    xlScaleFactor,
    xsScaleFactor,
} from "./constants/sizes"
import { Icon } from "./components/visual/Icon"

const screenSizes = {
    xs: 0,
    sm: 480,
    md: 840,
    lg: 1200,
    xl: 1600,
} as const

/**
 * The colors in Redoit design system
 *
 * Do not shorten to less than 6 chars, to ensure setting opacity using 2 char hex.
 */
const colors = {
    // Primary
    brand: "#9b64e1",
    brandLight: "#f4e3ff",
    brandDark: "#7248a8",

    // Grays
    grayWhite: "#ffffff",
    gray100: "#f6f6f6",
    gray200: "#e9e6ea",
    gray300: "#868589",
    // For gray text on gray100 background, use this to satisfy WCAG AA requirements.
    gray350: "#717075",
    gray400: "#4d4852",
    gray500: "#150624",

    // Secondary
    forest: "#155c58",
    forestLight: "#c7d8d7",
    sky: "#347cff",
    skyLight: "#e5e3ff",
    sand: "#b49469",
    sandLight: "#f7f2ef",
    highlight: "#c5ffaa",
    error: "#ff4c34",
    warning: "#ff4c34",
    warningLight: "#ffedeb",
    vippsOrange: "#ff5b24",

    // Colors used in specific components that are not defined in the design system
    _productsSeeAllBg: "#efeae7",
    _trustpilot: "#63d793",
} as const

const icons = {
    asterisk: (
        <path
            fill="currentColor"
            d="M20.143 16.886a.748.748 0 0 1-1.031.257l-6.362-3.818v6.925a.75.75 0 1 1-1.5 0v-6.925l-6.364 3.818a.749.749 0 1 1-.771-1.286L10.542 12 4.115 8.143a.75.75 0 1 1 .771-1.286l6.364 3.818V3.75a.75.75 0 1 1 1.5 0v6.925l6.364-3.818a.75.75 0 1 1 .771 1.286L13.458 12l6.427 3.857a.749.749 0 0 1 .258 1.03Z"
        />
    ),
    at: (
        <path
            fill="currentColor"
            d="M12 2.25a9.75 9.75 0 0 0 0 19.5c2.017 0 4.134-.608 5.665-1.625a.748.748 0 0 0 .003-1.247.751.751 0 0 0-.833-.002c-1.273.847-3.128 1.374-4.835 1.374A8.25 8.25 0 1 1 20.25 12c0 2.48-1.02 3-1.875 3s-1.875-.52-1.875-3V8.25a.75.75 0 0 0-1.5 0v.4a4.5 4.5 0 1 0 .556 6.102c.562 1.125 1.533 1.748 2.819 1.748 2.113 0 3.375-1.682 3.375-4.5A9.76 9.76 0 0 0 12 2.25ZM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
        />
    ),
    arrowUp: (
        <path
            fill="currentColor"
            d="M10.295 20.485V7.345l-6.07 6.07-1.42-1.42 8.49-8.48 8.49 8.48-1.42 1.42-6.07-6.07v13.14h-2Z"
        />
    ),
    arrowRight: (
        <path
            fill="currentColor"
            d="M2.811 13h13.14l-6.07 6.07 1.42 1.42 8.48-8.49-8.48-8.49-1.42 1.42 6.07 6.07H2.811v2Z"
        />
    ),
    arrowDown: (
        <path
            fill="currentColor"
            d="M10.295 3.515v13.14l-6.07-6.07-1.42 1.42 8.49 8.48 8.49-8.48-1.42-1.42-6.07 6.07V3.515h-2Z"
        />
    ),
    arrowLeft: (
        <path
            fill="currentColor"
            d="M19.78 13H6.64l6.07 6.07-1.42 1.42L2.81 12l8.48-8.49 1.42 1.42L6.64 11h13.14v2Z"
        />
    ),
    calendar: (
        <path
            fill="currentColor"
            d="M19.5 3h-2.25v-.75a.75.75 0 1 0-1.5 0V3h-7.5v-.75a.75.75 0 0 0-1.5 0V3H4.5A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3ZM6.75 4.5v.75a.75.75 0 0 0 1.5 0V4.5h7.5v.75a.75.75 0 1 0 1.5 0V4.5h2.25v3h-15v-3h2.25Zm12.75 15h-15V9h15v10.5Z"
        />
    ),
    calendarFilled: (
        <path
            fill="currentColor"
            d="M19.5 3h-2.25v-.75a.75.75 0 1 0-1.5 0V3h-7.5v-.75a.75.75 0 0 0-1.5 0V3H4.5A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3Zm0 4.5h-15v-3h2.25v.75a.75.75 0 0 0 1.5 0V4.5h7.5v.75a.75.75 0 1 0 1.5 0V4.5h2.25v3Z"
        />
    ),
    check: (
        <path
            fill="currentColor"
            d="M9.457 14.293 6.164 11 4.75 12.414l4.707 4.707 9.707-9.707L17.75 6l-8.293 8.293Z"
        />
    ),
    checkBadge: (
        <path
            fill="currentColor"
            d="M21.174 9.64c-.353-.37-.719-.75-.857-1.085-.127-.307-.134-.815-.142-1.307-.014-.915-.03-1.952-.75-2.673-.72-.72-1.758-.736-2.673-.75-.492-.008-1-.015-1.307-.143-.333-.137-.715-.503-1.084-.856C13.714 2.204 12.979 1.5 12 1.5c-.979 0-1.713.704-2.36 1.326-.37.353-.75.719-1.085.857-.305.127-.815.135-1.307.142-.915.014-1.952.03-2.673.75-.72.72-.731 1.758-.75 2.673-.008.492-.015 1-.143 1.307-.137.333-.503.715-.856 1.084C2.204 10.286 1.5 11.021 1.5 12c0 .979.704 1.713 1.326 2.36.353.37.719.75.857 1.085.127.307.135.815.142 1.307.014.915.03 1.952.75 2.673.72.72 1.758.736 2.673.75.492.008 1 .015 1.307.142.333.138.715.504 1.084.857.647.622 1.382 1.326 2.361 1.326.979 0 1.713-.704 2.36-1.326.37-.353.75-.719 1.085-.857.307-.127.815-.134 1.307-.142.915-.014 1.952-.03 2.673-.75.72-.72.736-1.758.75-2.673.008-.492.015-1 .142-1.307.138-.333.504-.715.857-1.084.622-.647 1.326-1.382 1.326-2.361 0-.979-.704-1.713-1.326-2.36Zm-1.082 3.683c-.45.469-.915.953-1.161 1.549-.236.571-.247 1.225-.256 1.858-.01.656-.02 1.343-.311 1.634-.292.29-.974.302-1.634.311-.633.01-1.287.02-1.858.256-.596.246-1.08.711-1.55 1.16-.468.45-.947.909-1.322.909-.375 0-.858-.461-1.323-.908-.465-.448-.953-.915-1.549-1.161-.571-.236-1.225-.247-1.858-.256-.656-.01-1.343-.02-1.634-.311-.29-.292-.302-.974-.311-1.634-.01-.633-.02-1.287-.256-1.858-.247-.596-.712-1.08-1.16-1.55C3.458 12.855 3 12.376 3 12c0-.375.461-.858.908-1.323.448-.465.914-.953 1.161-1.549.236-.571.247-1.225.256-1.858.01-.656.02-1.343.311-1.634.292-.29.974-.302 1.634-.311.633-.01 1.287-.02 1.858-.256.596-.247 1.08-.712 1.55-1.16C11.145 3.458 11.624 3 12 3c.375 0 .858.461 1.323.908.465.448.953.914 1.549 1.161.571.236 1.225.247 1.858.256.656.01 1.343.02 1.634.311.29.292.302.974.311 1.634.01.633.02 1.287.256 1.858.246.596.711 1.08 1.16 1.55.45.468.909.947.909 1.322 0 .375-.461.858-.908 1.323ZM16.28 9.219a.748.748 0 0 1 0 1.062l-5.25 5.25a.747.747 0 0 1-1.062 0l-2.25-2.25a.75.75 0 1 1 1.062-1.062l1.719 1.72 4.72-4.72a.749.749 0 0 1 1.06 0Z"
        />
    ),
    checkBadgeFilled: (
        <path
            fill="currentColor"
            d="M21.174 9.64c-.353-.37-.719-.75-.857-1.085-.127-.307-.134-.815-.142-1.307-.014-.915-.03-1.952-.75-2.673-.72-.72-1.758-.736-2.673-.75-.492-.008-1-.015-1.307-.143-.333-.137-.715-.503-1.084-.856C13.714 2.204 12.979 1.5 12 1.5c-.979 0-1.713.704-2.36 1.326-.37.353-.75.719-1.085.857-.305.127-.815.135-1.307.142-.915.014-1.952.03-2.673.75-.72.72-.731 1.758-.75 2.673-.008.492-.015 1-.143 1.307-.137.333-.503.715-.856 1.084C2.204 10.286 1.5 11.021 1.5 12c0 .979.704 1.713 1.326 2.36.353.37.719.75.857 1.085.127.307.135.815.142 1.307.014.915.03 1.952.75 2.673.72.72 1.758.736 2.673.75.492.008 1 .015 1.307.142.333.138.715.504 1.084.857.647.622 1.382 1.326 2.361 1.326.979 0 1.713-.704 2.36-1.326.37-.353.75-.719 1.085-.857.307-.127.815-.134 1.307-.142.915-.014 1.952-.03 2.673-.75.72-.72.736-1.758.75-2.673.008-.492.015-1 .142-1.307.138-.333.504-.715.857-1.084.622-.647 1.326-1.382 1.326-2.361 0-.979-.704-1.713-1.326-2.36Zm-4.893.64-5.25 5.25a.747.747 0 0 1-1.062 0l-2.25-2.25a.75.75 0 1 1 1.062-1.06l1.719 1.72 4.72-4.72a.751.751 0 0 1 1.06 1.06Z"
        />
    ),
    checkSquareOffset: (
        <path
            fill="currentColor"
            d="M21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-6.75a.75.75 0 0 1 0-1.5h6.75v-15h-15v9a.75.75 0 1 1-1.5 0v-9A1.5 1.5 0 0 1 4.5 3h15A1.5 1.5 0 0 1 21 4.5Zm-9.22 9.97a.749.749 0 0 0-1.06 0L6 19.19l-1.72-1.72a.75.75 0 1 0-1.06 1.06l2.25 2.25a.748.748 0 0 0 1.06 0l5.25-5.25a.748.748 0 0 0 0-1.06Z"
        />
    ),
    chevronUp: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3 15.586 4.414 17l7.293-7.293L19 17l1.414-1.414-8.707-8.707L3 15.586Z"
            clipRule="evenodd"
        />
    ),
    chevronRight: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.414 3.3 8 4.714l7.293 7.293L8 19.3l1.414 1.414 8.707-8.707L9.414 3.3Z"
            clipRule="evenodd"
        />
    ),
    chevronDown: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3.3 9.014 4.714 7.6l7.293 7.293L19.3 7.6l1.414 1.414-8.707 8.707L3.3 9.014Z"
            clipRule="evenodd"
        />
    ),
    chevronLeft: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M14.586 3.3 16 4.714l-7.293 7.293L16 19.3l-1.414 1.414-8.707-8.707L14.586 3.3Z"
            clipRule="evenodd"
        />
    ),
    close: (
        <path
            fill="currentColor"
            d="M19.28 18.22a.752.752 0 0 1-.53 1.28.75.75 0 0 1-.53-.22L12 13.06l-6.22 6.22a.75.75 0 1 1-1.06-1.06L10.94 12 4.72 5.78a.75.75 0 0 1 1.06-1.06L12 10.94l6.22-6.22a.75.75 0 1 1 1.06 1.06L13.06 12l6.22 6.22Z"
        />
    ),
    creditCard: (
        <path
            fill="currentColor"
            d="M21 4.5H3A1.5 1.5 0 0 0 1.5 6v12A1.5 1.5 0 0 0 3 19.5h18a1.5 1.5 0 0 0 1.5-1.5V6A1.5 1.5 0 0 0 21 4.5ZM21 6v2.25H3V6h18Zm0 12H3V9.75h18V18Zm-1.5-2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 1 1 0-1.5h3a.75.75 0 0 1 .75.75Zm-6 0a.75.75 0 0 1-.75.75h-1.5a.75.75 0 1 1 0-1.5h1.5a.75.75 0 0 1 .75.75Z"
        />
    ),
    data: (
        <path
            fill="currentColor"
            d="M12 2.25c-5.047 0-9 2.306-9 5.25v9c0 2.944 3.953 5.25 9 5.25 5.047 0 9-2.306 9-5.25v-9c0-2.944-3.953-5.25-9-5.25ZM19.5 12c0 .902-.739 1.822-2.026 2.524-1.45.79-3.394 1.226-5.474 1.226s-4.025-.436-5.474-1.226C5.239 13.822 4.5 12.902 4.5 12v-1.56c1.6 1.406 4.334 2.31 7.5 2.31 3.166 0 5.9-.908 7.5-2.31V12ZM6.526 4.976C7.976 4.186 9.92 3.75 12 3.75s4.025.436 5.474 1.226C18.761 5.678 19.5 6.598 19.5 7.5c0 .902-.739 1.822-2.026 2.524-1.45.79-3.394 1.226-5.474 1.226s-4.025-.436-5.474-1.226C5.239 9.322 4.5 8.402 4.5 7.5c0-.902.739-1.822 2.026-2.524Zm10.948 14.048c-1.45.79-3.394 1.226-5.474 1.226s-4.025-.436-5.474-1.226C5.239 18.322 4.5 17.402 4.5 16.5v-1.56c1.6 1.406 4.334 2.31 7.5 2.31 3.166 0 5.9-.907 7.5-2.31v1.56c0 .902-.739 1.822-2.026 2.524Z"
        />
    ),
    dataFilled: (
        <path
            fill="currentColor"
            d="M12 2.25c-5.047 0-9 2.306-9 5.25v9c0 2.944 3.953 5.25 9 5.25 5.047 0 9-2.306 9-5.25v-9c0-2.944-3.953-5.25-9-5.25ZM19.5 12c0 .902-.739 1.822-2.026 2.524-1.45.79-3.394 1.226-5.474 1.226s-4.025-.436-5.474-1.226C5.239 13.822 4.5 12.902 4.5 12v-1.56c1.6 1.406 4.334 2.31 7.5 2.31 3.166 0 5.9-.908 7.5-2.31V12Zm-2.026 7.024c-1.45.79-3.394 1.226-5.474 1.226s-4.025-.436-5.474-1.226C5.239 18.322 4.5 17.402 4.5 16.5v-1.56c1.6 1.406 4.334 2.31 7.5 2.31 3.166 0 5.9-.907 7.5-2.31v1.56c0 .902-.739 1.822-2.026 2.524Z"
        />
    ),
    desktop: (
        <path
            fill="currentColor"
            d="M21 6.938h-1.688V6a2.062 2.062 0 0 0-2.062-2.063H3.75A2.063 2.063 0 0 0 1.687 6v9a2.063 2.063 0 0 0 2.063 2.063h10.688V18a2.062 2.062 0 0 0 2.062 2.063H21A2.062 2.062 0 0 0 23.063 18V9A2.062 2.062 0 0 0 21 6.937Zm-17.25 9A.938.938 0 0 1 2.812 15V6a.937.937 0 0 1 .938-.938h13.5a.937.937 0 0 1 .938.938v.938H16.5A2.063 2.063 0 0 0 14.437 9v6.938H3.75ZM21.938 18a.938.938 0 0 1-.938.938h-4.5a.938.938 0 0 1-.938-.938V9a.938.938 0 0 1 .938-.938H21a.937.937 0 0 1 .938.938v9Zm-9.375 1.5a.562.562 0 0 1-.563.563H8.25a.563.563 0 0 1 0-1.125H12a.562.562 0 0 1 .563.562Zm7.5-9a.562.562 0 0 1-.563.563H18a.562.562 0 1 1 0-1.126h1.5a.562.562 0 0 1 .563.563Z"
        />
    ),
    device: (
        <path
            fill="currentColor"
            d="M7.5 22.5h9a2.25 2.25 0 0 0 2.25-2.25V3.75A2.25 2.25 0 0 0 16.5 1.5h-9a2.25 2.25 0 0 0-2.25 2.25v16.5A2.25 2.25 0 0 0 7.5 22.5ZM6.75 3.75A.75.75 0 0 1 7.5 3h9a.75.75 0 0 1 .75.75v16.5a.75.75 0 0 1-.75.75h-9a.75.75 0 0 1-.75-.75V3.75Zm4.125 14.625a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
        />
    ),
    deviceFilled: (
        <path
            fill="currentColor"
            d="M7.5 22.5h9a2.25 2.25 0 0 0 2.25-2.25V3.75A2.25 2.25 0 0 0 16.5 1.5h-9a2.25 2.25 0 0 0-2.25 2.25v16.5A2.25 2.25 0 0 0 7.5 22.5Zm4.5-5.25a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25Z"
        />
    ),
    delivery: (
        <path
            fill="currentColor"
            d="m20.97 6.202-8.25-4.514a1.489 1.489 0 0 0-1.44 0L3.03 6.202a1.5 1.5 0 0 0-.78 1.313v8.966a1.5 1.5 0 0 0 .78 1.313l8.25 4.516a1.489 1.489 0 0 0 1.44 0l8.25-4.516a1.5 1.5 0 0 0 .78-1.313V7.517a1.5 1.5 0 0 0-.78-1.315ZM12 3l7.532 4.125-2.791 1.528-7.533-4.125L12 3Zm0 8.25L4.468 7.125l3.178-1.74 7.532 4.125L12 11.25ZM3.75 8.437l7.5 4.105v8.043l-7.5-4.102V8.437Zm16.5 8.042-7.5 4.106v-8.04l3-1.64v3.345a.75.75 0 1 0 1.5 0v-4.167l3-1.646v8.042Z"
        />
    ),
    deliveryFilled: (
        <path
            fill="currentColor"
            d="m20.97 6.202-8.25-4.514a1.489 1.489 0 0 0-1.44 0L3.03 6.202a1.5 1.5 0 0 0-.78 1.313v8.966a1.5 1.5 0 0 0 .78 1.313l8.25 4.516a1.489 1.489 0 0 0 1.44 0l8.25-4.516a1.5 1.5 0 0 0 .78-1.313V7.517a1.5 1.5 0 0 0-.78-1.315ZM12 3l7.533 4.125-2.792 1.527-7.533-4.125L12 3Zm0 8.25L4.467 7.125l3.18-1.74 7.532 4.124L12 11.25Zm8.25 5.236-7.5 4.105v-8.044l3-1.642v3.345a.75.75 0 1 0 1.5 0v-4.166l3-1.642V16.486Z"
        />
    ),
    eraser: (
        <path
            fill="currentColor"
            d="m21.094 7.537-3.882-3.88a2.25 2.25 0 0 0-3.181 0L2.906 14.78a2.25 2.25 0 0 0 0 3.181l2.818 2.819a.75.75 0 0 0 .533.219H20.25a.75.75 0 1 0 0-1.5h-7.94l8.784-8.78a2.25 2.25 0 0 0 0-3.183ZM10.189 19.5H6.567l-2.599-2.599a.75.75 0 0 1 0-1.06L9 10.81l4.94 4.939-3.751 3.75Zm9.843-9.844L15 14.69l-4.94-4.94 5.034-5.03a.749.749 0 0 1 1.061 0l3.88 3.879a.75.75 0 0 1 0 1.06l-.003-.003Z"
        />
    ),
    filter: (
        <path
            fill="currentColor"
            d="M18.75 12.25A.75.75 0 0 1 18 13H6a.75.75 0 1 1 0-1.5h12a.75.75 0 0 1 .75.75Zm3-5.25H2.25a.75.75 0 0 0 0 1.5h19.5a.75.75 0 1 0 0-1.5Zm-7.5 9h-4.5a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5Z"
        />
    ),
    globe: (
        <path
            fill="currentColor"
            d="M12 2.25A9.75 9.75 0 1 0 21.75 12 9.76 9.76 0 0 0 12 2.25ZM20.25 12a8.214 8.214 0 0 1-.6 3.088l-4.19-2.577a1.494 1.494 0 0 0-.586-.21l-2.139-.288a1.51 1.51 0 0 0-1.5.737h-.817l-.357-.737A1.492 1.492 0 0 0 9.03 11.2l-.75-.162.733-1.288h1.567c.253 0 .502-.065.724-.188l1.149-.633c.1-.056.195-.124.281-.2l2.523-2.283a1.494 1.494 0 0 0 .306-1.843l-.034-.06A8.26 8.26 0 0 1 20.25 12Zm-6.815-8.124.815 1.458-2.523 2.282-1.147.634H9.013A1.5 1.5 0 0 0 7.712 9l-.819 1.428-.951-2.535 1.026-2.425a8.238 8.238 0 0 1 6.468-1.594v.002ZM3.75 12a8.206 8.206 0 0 1 .8-3.544l1.064 2.838a1.5 1.5 0 0 0 1.09.938l2.008.432.357.742a1.508 1.508 0 0 0 1.35.844h.14l-.679 1.522a1.5 1.5 0 0 0 .268 1.628l.014.013L12 19.307l-.182.937A8.26 8.26 0 0 1 3.75 12Zm9.617 8.136.106-.545a1.507 1.507 0 0 0-.388-1.316l-1.835-1.893 1.284-2.882 2.14.289 4.286 2.636a8.268 8.268 0 0 1-5.593 3.71Z"
        />
    ),
    globeFilled: (
        <path
            fill="currentColor"
            d="M12 2.25A9.75 9.75 0 1 0 21.75 12 9.76 9.76 0 0 0 12 2.25ZM20.25 12a8.214 8.214 0 0 1-.6 3.088l-4.19-2.577a1.494 1.494 0 0 0-.586-.21l-2.139-.288a1.51 1.51 0 0 0-1.5.737h-.817l-.357-.737A1.492 1.492 0 0 0 9.03 11.2l-.75-.162.733-1.288h1.567c.253 0 .502-.065.724-.188l1.149-.633c.1-.056.195-.124.281-.2l2.523-2.283a1.494 1.494 0 0 0 .306-1.843l-.034-.06A8.26 8.26 0 0 1 20.25 12Zm-16.5 0a8.206 8.206 0 0 1 .8-3.544l1.064 2.838a1.5 1.5 0 0 0 1.09.938l2.008.432.357.742a1.508 1.508 0 0 0 1.35.844h.14l-.679 1.522a1.5 1.5 0 0 0 .268 1.628l.014.013L12 19.307l-.182.937A8.26 8.26 0 0 1 3.75 12Z"
        />
    ),
    handCoins: (
        <path
            fill="currentColor"
            d="M22.343 13.974a2.29 2.29 0 0 0-1.99-.396l-3.923.902a2.625 2.625 0 0 0-2.555-3.23H9.182a2.982 2.982 0 0 0-2.122.878L4.94 14.25H2.25a1.5 1.5 0 0 0-1.5 1.5v3.75a1.5 1.5 0 0 0 1.5 1.5H12a.744.744 0 0 0 .182-.023l6-1.5a.653.653 0 0 0 .111-.037l3.645-1.55.04-.02a2.306 2.306 0 0 0 .37-3.896h-.005ZM2.25 15.75H4.5v3.75H2.25v-3.75Zm19.072.77-3.563 1.517-5.853 1.463H6v-4.19l2.122-2.12a1.487 1.487 0 0 1 1.06-.44h4.693a1.125 1.125 0 1 1 0 2.25H11.25a.75.75 0 1 0 0 1.5h3a.782.782 0 0 0 .168-.019l6.281-1.444.03-.008a.806.806 0 0 1 .59 1.49h.003Zm-5.197-6.77c.185 0 .37-.015.553-.045a3.376 3.376 0 1 0 2.646-4.406 3.375 3.375 0 1 0-3.199 4.451Zm5.625-1.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0ZM16.125 4.5a1.875 1.875 0 0 1 1.805 1.37 3.376 3.376 0 0 0-1.407 2.337 1.875 1.875 0 1 1-.398-3.707Z"
        />
    ),
    menu: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M20.8 14h-16v2h16v-2Zm0-6h-16v2h16V8Z"
            clipRule="evenodd"
        />
    ),
    more: (
        <path
            fill="currentColor"
            d="M13.125 12a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm5.25-1.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm-12.75 0a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
        />
    ),
    navigationArrow: (
        <path
            fill="currentColor"
            d="M21.5 9.207 5.007 3.094l-.015-.005A1.5 1.5 0 0 0 3.09 5.007L9.207 21.5a1.478 1.478 0 0 0 1.413 1h.028a1.478 1.478 0 0 0 1.406-1.058l2.21-7.178 7.177-2.209a1.5 1.5 0 0 0 .058-2.848Zm-.5 1.415-7.178 2.209a1.5 1.5 0 0 0-.991.992L10.62 21l-.005-.016L4.5 4.5l16.483 6.114.015.006.002.002Z"
        />
    ),
    placeholder: (
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M16.485 5.515h-8.97a2 2 0 0 0-2 2v8.97a2 2 0 0 0 2 2h8.97a2 2 0 0 0 2-2v-8.97a2 2 0 0 0-2-2Zm-8.97-2a4 4 0 0 0-4 4v8.97a4 4 0 0 0 4 4h8.97a4 4 0 0 0 4-4v-8.97a4 4 0 0 0-4-4h-8.97Z"
            clipRule="evenodd"
        />
    ),
    plus: (
        <path
            fill="currentColor"
            d="M12.75 21.546a.75.75 0 1 1-1.5 0V12.75H2.454a.75.75 0 0 1 0-1.5h8.796V2.454a.75.75 0 1 1 1.5 0v8.796h8.796a.75.75 0 1 1 0 1.5H12.75v8.796Z"
        />
    ),
    piggybank: (
        <path
            fill="currentColor"
            d="M18 10.875a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM14.25 6H10.5a.75.75 0 1 0 0 1.5h3.75a.75.75 0 1 0 0-1.5Zm9 4.5v3A2.25 2.25 0 0 1 21 15.75h-.221l-1.52 4.254a1.5 1.5 0 0 1-1.413.996h-1.192a1.5 1.5 0 0 1-1.413-.996l-.18-.504H9.689l-.18.504A1.5 1.5 0 0 1 8.096 21H6.904a1.5 1.5 0 0 1-1.413-.996l-1.178-3.296a8.22 8.22 0 0 1-2.01-4.535A1.5 1.5 0 0 0 1.5 13.5a.75.75 0 1 1-1.5 0 3 3 0 0 1 2.276-2.906A8.261 8.261 0 0 1 10.5 3h9.75a.75.75 0 1 1 0 1.5h-2.005a8.243 8.243 0 0 1 2.945 3.75 2.25 2.25 0 0 1 2.06 2.25Zm-1.5 0a.75.75 0 0 0-.75-.75h-.343a.75.75 0 0 1-.716-.525A6.74 6.74 0 0 0 13.5 4.5h-3a6.75 6.75 0 0 0-4.977 11.31.75.75 0 0 1 .154.254L6.904 19.5h1.192l.358-1.002A.75.75 0 0 1 9.16 18h6.43a.75.75 0 0 1 .706.498l.358 1.002h1.192l1.698-4.752a.75.75 0 0 1 .706-.498H21a.75.75 0 0 0 .75-.75v-3Z"
        />
    ),
    pin: (
        <path
            fill="currentColor"
            d="M12 6a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 6Zm0 6a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm0-10.5a8.26 8.26 0 0 0-8.25 8.25c0 2.944 1.36 6.064 3.938 9.023a23.837 23.837 0 0 0 3.885 3.591.75.75 0 0 0 .861 0 23.835 23.835 0 0 0 3.879-3.59c2.573-2.96 3.937-6.08 3.937-9.024A8.26 8.26 0 0 0 12 1.5Zm0 19.313c-1.55-1.22-6.75-5.696-6.75-11.063a6.75 6.75 0 0 1 13.5 0c0 5.365-5.2 9.844-6.75 11.063Z"
        />
    ),
    receipt: (
        <path
            fill="currentColor"
            d="M6.75 9.75A.75.75 0 0 1 7.5 9h9a.75.75 0 1 1 0 1.5h-9a.75.75 0 0 1-.75-.75Zm.75 3.75h9a.75.75 0 1 0 0-1.5h-9a.75.75 0 1 0 0 1.5Zm14.25-8.25V19.5a.75.75 0 0 1-1.086.67L18 18.838l-2.664 1.332a.75.75 0 0 1-.672 0L12 18.838 9.336 20.17a.75.75 0 0 1-.672 0L6 18.838 3.336 20.17a.75.75 0 0 1-1.086-.67V5.25a1.5 1.5 0 0 1 1.5-1.5h16.5a1.5 1.5 0 0 1 1.5 1.5Zm-1.5 0H3.75v13.037l1.914-.958a.75.75 0 0 1 .672 0L9 18.662l2.664-1.333a.75.75 0 0 1 .672 0L15 18.662l2.664-1.333a.75.75 0 0 1 .672 0l1.914.958V5.25Z"
        />
    ),
    shield: (
        <path
            fill="currentColor"
            d="M7.553 10.219a.75.75 0 0 1 .978-.416l2.719 1.089V8.25a.75.75 0 1 1 1.5 0v2.642l2.719-1.089a.75.75 0 1 1 .562 1.394l-2.841 1.136 1.66 2.217a.75.75 0 0 1-1.2.9L12 13.25l-1.65 2.2a.75.75 0 1 1-1.2-.9l1.663-2.217-2.844-1.136a.75.75 0 0 1-.416-.978ZM21 4.5v5.51c0 8.402-7.108 11.188-8.531 11.661a1.446 1.446 0 0 1-.938 0C10.106 21.203 3 18.413 3 10.011V4.5A1.5 1.5 0 0 1 4.5 3h15A1.5 1.5 0 0 1 21 4.5Zm-1.5 0h-15v5.512c0 7.359 6.232 9.813 7.5 10.235 1.28-.427 7.5-2.884 7.5-10.235V4.5Z"
        />
    ),
    shuffle: (
        <path
            fill="currentColor"
            d="M22.28 16.72a.747.747 0 0 1 0 1.06l-2.25 2.25a.75.75 0 0 1-1.06-1.06l.97-.97h-1.102a6.762 6.762 0 0 1-5.493-2.827l-3.91-5.475A5.26 5.26 0 0 0 5.161 7.5H3A.75.75 0 0 1 3 6h2.162a6.761 6.761 0 0 1 5.493 2.827l3.91 5.475a5.26 5.26 0 0 0 4.273 2.198h1.102l-.97-.97a.75.75 0 1 1 1.06-1.06l2.25 2.25Zm-8.874-6.689a.75.75 0 0 0 1.046-.174l.113-.157a5.26 5.26 0 0 1 4.273-2.2h1.102l-.97.97a.75.75 0 1 0 1.06 1.06l2.25-2.25a.748.748 0 0 0 0-1.06l-2.25-2.25a.75.75 0 1 0-1.06 1.06l.97.97h-1.102a6.762 6.762 0 0 0-5.493 2.827l-.112.156a.749.749 0 0 0 .173 1.048Zm-2.812 3.938a.75.75 0 0 0-1.046.174l-.113.157a5.259 5.259 0 0 1-4.273 2.2H3A.75.75 0 1 0 3 18h2.162a6.762 6.762 0 0 0 5.493-2.827l.112-.156a.75.75 0 0 0-.173-1.048Z"
        />
    ),
    simCard: (
        <path
            fill="currentColor"
            d="M7.72 3.97 2.47 9.22a.75.75 0 0 0-.22.53v9a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V5.25a1.5 1.5 0 0 0-1.5-1.5h-12a.75.75 0 0 0-.53.22Zm12.53 1.28v13.5H3.75v-8.69l4.81-4.81h11.69ZM10.5 7.5v9a.75.75 0 0 0 .75.75H18a.75.75 0 0 0 .75-.75v-9a.75.75 0 0 0-.75-.75h-6.75a.75.75 0 0 0-.75.75Zm6.75.75v1.5h-3a.75.75 0 1 0 0 1.5h3v1.5h-3a.75.75 0 1 0 0 1.5h3v1.5H12v-7.5h5.25Z"
        />
    ),
    star: (
        <path
            fill="currentColor"
            d="M22.423 9.118a1.536 1.536 0 0 0-1.337-1.056l-5.53-.446-2.137-5.164a1.533 1.533 0 0 0-2.837 0L8.448 7.615l-5.534.447a1.543 1.543 0 0 0-.879 2.706l4.22 3.64-1.286 5.444a1.536 1.536 0 0 0 2.297 1.67L12 18.608l4.737 2.914a1.537 1.537 0 0 0 2.294-1.67l-1.29-5.444 4.22-3.64a1.541 1.541 0 0 0 .462-1.65Zm-1.438.513-4.566 3.937a.751.751 0 0 0-.24.742l1.395 5.888a.035.035 0 0 1-.016.044c-.016.014-.021.01-.035 0l-5.13-3.154a.75.75 0 0 0-.786 0l-5.13 3.156c-.014.009-.018.012-.035 0a.035.035 0 0 1-.016-.045l1.395-5.887a.75.75 0 0 0-.24-.742L3.015 9.633c-.011-.01-.022-.018-.012-.047.01-.03.017-.025.03-.027l5.993-.484a.75.75 0 0 0 .63-.463l2.308-5.589c.008-.016.01-.023.033-.023s.026.007.033.023l2.314 5.589a.75.75 0 0 0 .633.461l5.992.484c.014 0 .023 0 .031.027.009.027 0 .038-.015.047Z"
        />
    ),
    starFilled: (
        <path
            fill="currentColor"
            d="m21.984 10.723-4.228 3.69 1.267 5.494a1.5 1.5 0 0 1-2.235 1.625l-4.792-2.906-4.78 2.906a1.5 1.5 0 0 1-2.236-1.625l1.265-5.488-4.23-3.696a1.5 1.5 0 0 1 .855-2.63l5.574-.483 2.176-5.19a1.495 1.495 0 0 1 2.76 0l2.183 5.19 5.572.482a1.5 1.5 0 0 1 .854 2.631h-.005Z"
        />
    ),
    tablet: (
        <path
            fill="currentColor"
            d="M18 2.438H6A2.063 2.063 0 0 0 3.937 4.5v15A2.063 2.063 0 0 0 6 21.563h12a2.062 2.062 0 0 0 2.063-2.063v-15A2.062 2.062 0 0 0 18 2.437ZM5.062 6.561h13.875v10.875H5.063V6.563Zm.938-3h12a.937.937 0 0 1 .938.938v.938H5.063V4.5A.937.937 0 0 1 6 3.562Zm12 16.875H6a.938.938 0 0 1-.938-.937v-.938h13.875v.938a.938.938 0 0 1-.937.938Z"
        />
    ),
    truck: (
        <path
            fill="currentColor"
            d="m23.196 10.969-1.313-3.281a1.493 1.493 0 0 0-1.391-.938H17.25V6a.75.75 0 0 0-.75-.75H2.25a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h1.594a3 3 0 0 0 5.812 0h4.688a3 3 0 0 0 5.812 0h1.594a1.5 1.5 0 0 0 1.5-1.5v-6a.742.742 0 0 0-.054-.281ZM17.25 8.25h3.242l.9 2.25H17.25V8.25Zm-15-1.5h13.5v6H2.25v-6Zm4.5 12.75a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm7.594-2.25H9.656a3 3 0 0 0-5.812 0H2.25v-3h13.5v1.154a3.008 3.008 0 0 0-1.406 1.846Zm2.906 2.25a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm4.5-2.25h-1.594A3.007 3.007 0 0 0 17.25 15v-3h4.5v5.25Z"
        />
    ),
    trustpilot: (
        <path
            fill={colors._trustpilot}
            d="m11.566 17.252 4.591-1.164L18.076 22l-6.51-4.748ZM22.132 9.61H14.05L11.566 2 9.082 9.61H1l6.54 4.717-2.483 7.61 6.54-4.717 4.026-2.893 6.51-4.717Z"
        />
    ),
    vipps: (
        <path
            fill="currentColor"
            d="M12.656 16.282c2.97 0 4.656-1.445 6.262-3.532.883-1.124 2.007-1.365 2.81-.723.802.643.882 1.847 0 2.97-2.329 3.051-5.299 4.898-9.072 4.898-4.094 0-7.706-2.248-10.195-6.182-.722-1.043-.562-2.167.241-2.729.803-.562 2.007-.321 2.73.803 1.766 2.649 4.174 4.495 7.224 4.495Zm5.54-9.874c0 1.445-1.125 2.409-2.409 2.409-1.284 0-2.408-.964-2.408-2.409C13.379 4.963 14.503 4 15.787 4c1.284 0 2.408 1.044 2.408 2.408Z"
        />
    ),
    warning: (
        <path
            fill="currentColor"
            d="M12 2a9.75 9.75 0 1 0 9.75 9.75A9.76 9.76 0 0 0 12 2Zm-.75 5.25a.75.75 0 1 1 1.5 0v5.25a.75.75 0 1 1-1.5 0V7.25ZM12 17a1.125 1.125 0 1 1 0-2.25A1.125 1.125 0 0 1 12 17Z"
        />
    ),
    watch: (
        <path
            fill="currentColor"
            d="M19.312 12a7.306 7.306 0 0 0-3.05-5.935l-.597-3.3a1.312 1.312 0 0 0-1.291-1.078H9.626a1.313 1.313 0 0 0-1.292 1.079l-.597 3.299a7.3 7.3 0 0 0 0 11.87l.597 3.3a1.313 1.313 0 0 0 1.292 1.078h4.748a1.313 1.313 0 0 0 1.291-1.079l.598-3.299A7.306 7.306 0 0 0 19.313 12Zm-9.87-9.034a.187.187 0 0 1 .187-.154h4.748a.187.187 0 0 1 .187.154l.428 2.362a7.283 7.283 0 0 0-5.972 0l.421-2.362Zm5.116 18.068a.188.188 0 0 1-.187.154H9.626a.187.187 0 0 1-.187-.154l-.428-2.362a7.283 7.283 0 0 0 5.972 0l-.425 2.362ZM12 18.188A6.187 6.187 0 1 1 18.187 12 6.195 6.195 0 0 1 12 18.188Z"
        />
    ),
    wave: (
        <path
            fill="currentColor"
            d="m20.64 9.375-1.622-2.813a2.625 2.625 0 0 0-3.585-.96 2.596 2.596 0 0 0-.844.781l-1.633-2.82a2.625 2.625 0 0 0-4.545 0 2.625 2.625 0 0 0-3.897 3.374l.15.26a2.625 2.625 0 0 0-2.06 3.928l3.748 6.498a8.2 8.2 0 0 0 5.01 3.844 8.25 8.25 0 0 0 9.28-12.094v.002Zm-.623 5.873a6.75 6.75 0 0 1-12.365 1.627l-3.75-6.499A1.125 1.125 0 0 1 5.85 9.251l1.803 3.124a.75.75 0 1 0 1.298-.75L5.812 6.187a1.125 1.125 0 1 1 1.949-1.125l2.926 5.063a.751.751 0 1 0 1.299-.75L9.71 5.437a1.125 1.125 0 1 1 1.948-1.125l3.133 5.429a4.5 4.5 0 0 0-.52 5.68.75.75 0 1 0 1.242-.843 3 3 0 0 1 .685-4.078.75.75 0 0 0 .2-.975l-.627-1.088a1.126 1.126 0 0 1 1.264-1.676 1.125 1.125 0 0 1 .684.551l1.622 2.813a6.707 6.707 0 0 1 .675 5.12v.003ZM17.273 2.973a.75.75 0 0 1 .914-.536 5.59 5.59 0 0 1 3.425 2.625l.031.054a.75.75 0 1 1-1.298.75l-.031-.054A4.094 4.094 0 0 0 17.8 3.891a.75.75 0 0 1-.528-.918Zm-9.69 19.246a.75.75 0 0 1-1.052.124 11.209 11.209 0 0 1-2.775-3.218.75.75 0 1 1 1.299-.75 9.72 9.72 0 0 0 2.404 2.786.75.75 0 0 1 .124 1.058Z"
        />
    ),
} as const

const borderRadiuses = {
    xs: { xs: 6 },
    sm: { xs: 12 },
    md: { xs: 16, md: 24 },
    lg: { xs: 24, md: 32 },
} as const

const spacingSizes = {
    xs: {
        xs: 4,
        sm: 12,
        md: 18,
        lg: 24,
    },
    sm: {
        xs: 16,
        lg: 22,
    },
    md: {
        xs: 16,
        sm: 22,
        md: 24,
        lg: 24,
        xl: 24,
    },
    lg: {
        xs: 24,
        sm: 32,
        md: 56,
        lg: 60,
        xl: 64,
    },
    xl: {
        xs: 40,
        sm: 56,
        md: 72,
        lg: 80,
        xl: 96,
    },
} as const

const headingLevels = {
    "1": {
        xs: {
            fontSize: 28 * xlScaleFactor,
            fontWeight: 500,
            lineHeight: "100%",
            letterSpacing: -0.6,
        },
        md: {
            fontSize: 40 * xlScaleFactor,
            letterSpacing: -0.8,
        },
        lg: {
            fontSize: 54 * xlScaleFactor,
            letterSpacing: -0.8,
        },
    },
    "2": {
        xs: {
            fontSize: 24 * xlScaleFactor,
            fontWeight: 400,
            lineHeight: "110%",
            letterSpacing: 0.24,
        },
        md: {
            fontSize: 32 * xlScaleFactor,
            letterSpacing: 0.3,
        },
        lg: {
            fontSize: 40 * xlScaleFactor,
            letterSpacing: 0.4,
        },
    },
    "3": {
        xs: { fontSize: 18 * xlScaleFactor, fontWeight: 400, lineHeight: "130%" },
        md: { fontSize: 24 * xlScaleFactor },
        lg: { fontSize: 28 * xlScaleFactor },
    },
    "4": {
        xs: {
            fontSize: 15,
            fontWeight: 600,
            lineHeight: "120%",
            letterSpacing: -0.15,
        },
        md: {
            fontSize: 16 * xlScaleFactor,
            fontWeight: 500,
        },
        lg: {
            fontSize: 18 * xlScaleFactor,
            letterSpacing: -0.18,
        },
    },
    "5": {
        xs: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "110%",
            letterSpacing: -0.14,
        },
        md: {
            fontSize: 15 * xlScaleFactor,
        },
        lg: {
            fontSize: 16 * xlScaleFactor,
            letterSpacing: -0.18,
        },
    },
} as const

const buttonBase = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.30s",
    borderStyle: "solid",
    borderColor: "transparent",
    whiteSpace: "nowrap",
    borderRadius: "100vw",
    cursor: "pointer",
    gap: 8,
} as const

const disabledButton = {
    backgroundColor: colors.gray100,
    color: colors.gray300,
    cursor: "default",
} as const

const buttonVariants = {
    primary: {
        ...buttonBase,
        color: colors.grayWhite,
        background: colors.brand,
        "&:hover:not([disabled])": {
            color: colors.grayWhite,
            backgroundColor: colors.brandDark,
        },
        "&:focus": { color: colors.gray500, backgroundColor: colors.brandLight },
        "&:disabled": disabledButton,
    } as CSSObject,
    secondary: {
        ...buttonBase,
        color: colors.gray500,
        backgroundColor: colors.gray100,
        "&:hover:not([disabled])": {
            backgroundColor: colors.gray200,
        },
        "&:focus": {
            color: colors.gray500,
            borderWidth: 2,
            borderColor: colors.gray500,
        },
        "&:disabled": disabledButton,
    } as CSSObject,
    dark: {
        ...buttonBase,
        color: colors.grayWhite,
        backgroundColor: colors.gray500,
        "&:hover:not([disabled])": {
            color: colors.grayWhite,
            backgroundColor: colors.gray400,
        },
        "&:focus": { color: colors.gray500, backgroundColor: colors.gray200 },
        "&:disabled": disabledButton,
    } as CSSObject,
    light: {
        ...buttonBase,
        color: colors.gray500,
        backgroundColor: colors.grayWhite,
        "&:hover:not([disabled])": {
            color: colors.gray500,
            backgroundColor: colors.gray100,
        },
        "&:focus": { color: colors.gray500, backgroundColor: colors.gray200 },
        "&:disabled": disabledButton,
    } as CSSObject,
    outlined: {
        ...buttonBase,
        color: colors.gray500,
        borderColor: colors.gray200,
        "&:hover:not([disabled])": { borderColor: colors.gray400 },
        "&:focus": { borderColor: colors.gray500 },
        "&:disabled": disabledButton,
    } as CSSObject,
    select: {
        ...buttonBase,
        color: colors.gray500,
        borderColor: colors.gray200,
        "&[data-selected=false]": { borderWidth: 1 },
        "&:hover:not([disabled])": { borderColor: colors.brand },
        "&:focus": { color: colors.gray500, backgroundColor: colors.gray200 },
        "&[data-selected=true]": {
            borderWidth: 2,
            borderColor: colors.brand,
            backgroundColor: colors.brandLight,
        },
        "&:disabled": disabledButton,
    } as CSSObject,
    option: {
        ...buttonBase,
        color: colors.gray400,
        borderColor: colors.gray200,
        background: colors.grayWhite,
        paddingLeft: `${scaleValue(16)} !important`,
        paddingRight: `${scaleValue(16)} !important`,
        borderRadius: scaleValue(12),
        "&[data-selected=false]": { borderWidth: 1, fontWeight: 400 },
        "&:hover:not([disabled])": { borderColor: colors.brand },
        "&:focus": { color: colors.gray400, backgroundColor: colors.gray100 },
        "&[data-selected=true]": {
            borderColor: colors.brand,
            backgroundColor: colors.brandLight,
        },
        "&:disabled": disabledButton,
    } as CSSObject,
    profile: {
        ...buttonBase,
        color: colors.brand,
        backgroundColor: colors.brandLight,
    } as CSSObject,
} as const

const buttonSizes = {
    xs: {
        xs: {
            height: 36 * xlScaleFactor,
            padding: `0 ${12 * xlScaleFactor}px`,
        },
    },
    sm: {
        xs: {
            height: 48 * xlScaleFactor,
            padding: `0 ${24 * xlScaleFactor}px`,
        },
    },
    md: {
        xs: {
            height: 48 * xlScaleFactor,
            padding: `0 ${24 * xlScaleFactor}px`,
        },
        lg: {
            height: 64 * xlScaleFactor,
            padding: `0 ${32 * xlScaleFactor}px`,
        },
    },
} as const

const bodySizes = {
    xs: {
        xs: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "135%",
        },
        md: {
            fontSize: 15,
            fontWeight: 300,
        },
    },
    sm: {
        xs: {
            fontSize: 15,
            fontWeight: 400,
            lineHeight: "135%",
        },
        md: {
            fontSize: 16 * xlScaleFactor,
            fontWeight: 300,
        },
        xl: {
            fontSize: 17 * xlScaleFactor,
        },
    },
    md: {
        xs: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "135%",
        },
        md: {
            fontSize: 17 * xlScaleFactor,
            fontWeight: 300,
        },
        xl: { fontSize: 18 * xlScaleFactor },
    },
    lg: {
        xs: {
            fontSize: 17,
            fontWeight: 300,
            lineHeight: "135%",
        },
        md: { fontSize: 22 * lgScaleFactor },
        xl: { fontSize: 22 * xlScaleFactor },
    },
    xl: {
        xs: {
            fontSize: 18,
            fontWeight: 300,
            lineHeight: "130%",
        },
        md: {
            fontSize: 21 * xlScaleFactor,
        },
        lg: { fontSize: 28 * lgScaleFactor },
        xl: { fontSize: 28 * xlScaleFactor },
    },
} as const

const textVariants = {
    body: bodySizes,
    label: {
        md: {
            xs: {
                fontSize: 12,
                fontWeight: 500,
                letterSpacing: 0.96,
                textTransform: "uppercase",
                lineHeight: "115%",
            },
            md: {
                fontSize: 12,
            },
            lg: {
                fontSize: 14,
                letterSpacing: 1.28,
            },
            xl: {
                fontSize: 16 * xlScaleFactor,
            },
        },
    },
} as const

export const redoitTheme = createTheme({
    textVariants,
    screenSizes,
    bodySizes,
    borderRadiuses,
    spacingSizes,
    headingLevels,
    iconSizes: [16, 24, 32, 40, 48, 64, 96],
    colors,
    icons,
    buttonVariants,
    buttonSizes,
})

export type RedoitTheme = typeof redoitTheme

export type MediaQuery = [LimitType, ScreenSize]
export type IconName = IconNameGeneric<RedoitTheme>
export type Color = ColorGeneric<RedoitTheme>
export type ScreenSize = ScreenSizeGeneric<RedoitTheme>
export type ButtonVariant = ButtonVariantGeneric<RedoitTheme>
export type ResponsiveStyles = Partial<Record<ScreenSize, CSSObject>>
export type ResponsiveStyleValue = ResponsiveStyleValueGeneric<ScreenSizeGeneric<RedoitTheme>>
export type ResponsiveVariantStyles<V extends string> = Partial<Record<V, ResponsiveStyles>>
export type SpacingSize = SpacingSizeGeneric<RedoitTheme>
export type Spacing = SpacingGeneric<RedoitTheme>
export type RedoitSpacing = SpacingGeneric<RedoitTheme>
export type FixedVariant<V extends string> = FixedVariantGeneric<ScreenSizeGeneric<RedoitTheme>, V>
export type ResponsiveVariant<V extends string> = ResponsiveVariantGeneric<
    ScreenSizeGeneric<RedoitTheme>,
    V
>
export type BorderRadiusVariant = BorderRadiusGeneric<RedoitTheme>
export type BodySize = BodySizeGeneric<RedoitTheme>

export const useRedoitTheme = useTheme<RedoitTheme>

/**
 * Global styles that apply to the whole site. More or less just reset + fonts and then some.
 */
export const globalStyles = css(
    {
        html: {
            boxSizing: "border-box",
        },
        "*, *:before, *:after": {
            boxSizing: "inherit",
            margin: 0,
            padding: 0,
            border: 0,
            verticalAlign: "baseline",
        },
        button: {
            background: "none",
            border: "none",
            font: "inherit",
            cursor: "pointer",
            outline: "inherit",
        },
        body: {
            fontFamily: "Catalogue, sans-serif",
            color: redoitTheme.colors.gray500,
            fontSize: 18,
            "--scale-factor": xsScaleFactor,
        },
        "p a": {
            textDecoration: "underline",
            textDecorationSkipInk: "none",
            textUnderlineOffset: "20%",
        },
        "p:last-child": {
            marginBottom: 0,
        },
        strong: {
            fontWeight: 600,
        },
        em: {
            fontStyle: "italic",
        },
        a: { textDecoration: "none", color: "inherit" },
        "td, th": {
            verticalAlign: "middle",
        },
    },
    redoitTheme.helpers.responsiveCss("min", "md", {
        body: {
            "--scale-factor": mdScaleFactor,
        },
    }),
    redoitTheme.helpers.responsiveCss("min", "lg", {
        body: {
            "--scale-factor": lgScaleFactor,
        },
    }),
    redoitTheme.helpers.responsiveCss("min", "xl", {
        body: {
            "--scale-factor": xlScaleFactor,
        },
    }),
    retinaCss({
        body: {
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
            fontSmooth: "never",
        },
    })
)

export function scaleValue(value: number | string): string {
    return `calc(${typeof value === "number" ? `${value}px` : value} * var(--scale-factor))`
}

export function pageSizeCss() {
    return redoitTheme.helpers.responsiveVariantToCss(pageSize)
}

export function pageWidthCss() {
    return redoitTheme.helpers.responsiveVariantToCss(pageWidth)
}

export function responsiveScaleValueCss(
    prop: string,
    responsiveValues: ResponsiveStyleValue
): SerializedStyles {
    if (typeof responsiveValues === "string" || typeof responsiveValues === "number") {
        return css({ [prop]: scaleValue(responsiveValues) })
    }
    return css(
        responsiveValues.map((rv) => {
            if (typeof rv === "string" || typeof rv === "number") {
                return { [prop]: rv }
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            } else if (rv) {
                const [limitType, screenSize, value] = rv
                return redoitTheme.helpers.responsiveCss(limitType, screenSize, {
                    [prop]: scaleValue(value),
                })
            }
        })
    )
}

export function responsiveBoxShadowVars(variableName: string) {
    // eslint-disable-next-line no-console
    if (!variableName.startsWith("--")) console.warn("CSS-variables should start with `--`.")

    return css(
        {
            [variableName]: "0px 2px 12px 0px rgba(21, 6, 36, 0.12)",
        },
        redoitTheme.helpers.responsiveCss("min", "md", {
            [variableName]: "0px 3px 18px 0px rgba(21, 6, 36, 0.12)",
        }),
        redoitTheme.helpers.responsiveCss("min", "lg", {
            [variableName]: "0px 4px 24px 0px rgba(21, 6, 36, 0.12)",
        }),
        redoitTheme.helpers.responsiveCss("min", "xl", {
            [variableName]: "0px 4px 32px 0px rgba(21, 6, 36, 0.12)",
        })
    )
}

export function responsiveBoxShadow() {
    return css(
        {
            boxShadow: "0px 2px 12px 0px rgba(21, 6, 36, 0.12)",
        },
        redoitTheme.helpers.responsiveCss("min", "md", {
            boxShadow: "0px 3px 18px 0px rgba(21, 6, 36, 0.12)",
        }),
        redoitTheme.helpers.responsiveCss("min", "lg", {
            boxShadow: "0px 4px 24px 0px rgba(21, 6, 36, 0.12)",
        }),
        redoitTheme.helpers.responsiveCss("min", "xl", {
            boxShadow: "0px 4px 32px 0px rgba(21, 6, 36, 0.12)",
        })
    )
}

const sectionSpacing = {
    xs: scaleValue(36),
    md: scaleValue(48),
}

export function responsiveSectionSpacing() {
    return Object.entries(sectionSpacing as Partial<Record<ScreenSize, number | string>>).map(
        ([screenSize, spacing]) =>
            redoitTheme.helpers.responsiveCss("min", screenSize as ScreenSize, {
                paddingTop: spacing,
                paddingBottom: spacing,
            })
    )
}

export type FontWeight = 300 | 400 | 500 | 600

export const adyenPaymentFormCss = css(
    {
        ".adyen-checkout-form-instruction": {
            display: "none",
            fontSize: 16,
            color: colors.gray300,
        },
        ".adyen-checkout-input__inline-validation": {
            height: 24,
            width: 24,
        },
        ".adyen-checkout-input__inline-validation img": {
            display: "none",
        },
        ".adyen-checkout-input__inline-validation.adyen-checkout-input__inline-validation--valid:after":
            {
                display: "block",
                content: `url("data:image/svg+xml,${encodeURIComponent(
                    ReactDOM.renderToString(
                        <ThemeProvider theme={redoitTheme}>
                            <Icon icon="check" color="forest" />
                        </ThemeProvider>
                    )
                )}")`,
                width: 24,
                height: 24,
            },
        ".adyen-checkout__payment-method": {
            backgroundColor: "#fff",
            padding: 0,
            border: 0,
        },
        "li.adyen-checkout__payment-method": {
            marginBottom: 16,
        },
        "li.adyen-checkout__payment-method:last-of-type": {
            marginBottom: 0,
        },
        ".adyen-checkout__payment-method__header": {
            padding: 0,
            marginBottom: 16,
            display: "flex",
            justifyContent: "space-between",
        },
        "li.adyen-checkout__payment-method:last-of-type:not(.adyen-checkout__payment-method--selected) .adyen-checkout__payment-method__header":
            {
                marginBottom: 0,
            },
        ".adyen-checkout__payment-method__details": {
            padding: 0,
        },
        "button.adyen-checkout__payment-method__header__title": {
            display: "flex",
            padding: 0,
            alignItems: "stretch",
        },
        ".adyen-checkout__payment-method__name_wrapper": {
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
        },
        ".adyen-checkout__payment-method__radio": { opacity: 0 },
        ".adyen-checkout__payment-method__name": {
            lineHeight: "120%",
        },
        ".adyen-checkout__button.adyen-checkout__button--pay": css(
            { marginTop: 16 },
            // responsiveButtonCss("primary", "md"),
            {
                borderRadius: "100px",
            }
        ),
        ".adyen-checkout__button__icon": {
            display: "none",
        },
        ".adyen-checkout__button__text": {
            lineHeight: "120%",
        },
        [[
            ".adyen-checkout__button:hover",
            ".adyen-checkout__button:focus",
            ".adyen-checkout__button:focus:hover",
        ].join(",")]: {
            boxShadow: "none",
        },
        ".adyen-checkout__payment-method__image__wrapper--outline:after": { display: "none" },
        ".adyen-checkout__input": {
            borderColor: colors.gray200,
            borderWidth: 2,
            height: 48 * xlScaleFactor,
            padding: `6px ${20 * xlScaleFactor}px`,
            fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            fontWeight: "300",
            WebkitFontSmoothing: "auto",
            MozOsxFontSmoothing: "auto",
            fontSmooth: "auto",
            fontSize: "16px",
        },

        "input.adyen-checkout__input::placeholder": {
            fontWeight: "300",
            color: colors.gray300,
        },
        ".adyen-checkout__input:hover": {
            borderColor: colors.gray200,
            borderWidth: 2,
            boxShadow: "none",
        },
        ".adyen-checkout__input:focus, .adyen-checkout__input--focus": {
            borderColor: colors.brand,
            borderStyle: "solid",
            borderWidth: 2,
            boxShadow: "none",
        },
        [[
            ".adyen-checkout__input:focus:hover",
            ".adyen-checkout__input:active",
            ".adyen-checkout__input:active:hover",
            ".adyen-checkout__input--focus:hover",
        ].join(",")]: {
            border: `2px solid ${colors.brand}`,
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: colors.brand,
            boxShadow: "none",
        },
        ".adyen-checkout__applepay__button": {
            borderRadius: "100px",
        },
        ".adyen-checkout__label, .adyen-checkout__card__brands": {
            display: "none",
        },
        ".adyen-checkout__label__text": {
            fontSize: "16px",
            marginBottom: 8,
        },
        ".adyen-checkout__label--focused .adyen-checkout__label__text": {
            color: colors.gray500,
        },
        ".adyen-checkout__card__cardNumber__brandIcon": {
            right: 16,
        },
        "img.adyen-checkout__field__exp-date_hint": {
            marginRight: 0,
        },
        ".adyen-checkout__field__exp-date_hint_wrapper": {
            marginRight: 0,
            right: 16,
        },
        ".adyen-checkout__card__cvc__hint__wrapper": {
            marginRight: 0,
            right: 16,
        },
        [[".adyen-checkout__label__text--error", ".adyen-checkout__error-text"].join(",")]: {
            color: colors.error,
        },
        ".adyen-checkout__error-text": {
            margin: 0,
        },
        ".adyen-checkout__field--error .adyen-checkout__error-text": {
            marginTop: 16,
            marginBottom: 16,
        },
        ".adyen-checkout__field--error .adyen-checkout__card__brands": {
            marginTop: 0,
        },
        ".adyen-checkout__field--error .adyen-checkout__error-text:before": {
            display: "block",
            content: `url("data:image/svg+xml,${encodeURIComponent(
                ReactDOM.renderToString(
                    <ThemeProvider theme={redoitTheme}>
                        <Icon icon="warning" color="error" />
                    </ThemeProvider>
                )
            )}")`,
            width: 24,
            height: 24,
            marginRight: 8,
        },
        [[
            ".adyen-checkout__input--error",
            ".adyen-checkout__input--error:hover",
            ".adyen-checkout__input--error:active",
            ".adyen-checkout__input--error:active:hover",
            ".adyen-checkout__input--error:focus:hover",
            ".adyen-checkout__input--error--focus:hover",
            ".adyen-checkout__field--error input",
        ].join(",")]: {
            borderColor: colors.error,
        },
        [[
            ".adyen-checkout__input--valid",
            ".adyen-checkout__input--valid:hover",
            ".adyen-checkout__input--valid:active",
            ".adyen-checkout__input--valid:active:hover",
            ".adyen-checkout__input--valid:focus:hover",
            ".adyen-checkout__input--valid--focus:hover",
        ].join(",")]: {
            borderColor: colors.forest,
        },
        ".adyen-checkout__payment-method__details__content": {
            margin: 0,
        },
        ".error-description": {
            display: "none",
        },
    },
    redoitTheme.helpers.responsiveCss("min", "md", {
        ".adyen-checkout__input": {
            height: 64 * xlScaleFactor,
        },
    }),
    css`
        .adyen-checkout__input {
            ${redoitTheme.helpers.responsiveBorderRadius("sm")};
        }
    `
)
