import { useState } from "react"
import { useRedoitTheme } from "../../theme"

export function DualSlider(props: {
    left: number
    setLeft: (left: number) => void
    right: number
    setRight: (right: number) => void
}) {
    const { colors } = useRedoitTheme()
    const [lock, setLock] = useState<"left" | "right" | null>(null)

    return (
        <div style={{ height: 32, position: "relative" }}>
            <div
                style={{
                    pointerEvents: "none",
                    userSelect: "none",
                    position: "absolute",
                    width: 32,
                    height: 32,
                    boxShadow: "0px 2px 8px 0px #1307221F",
                    borderRadius: 32,
                    top: 0,
                    left: `${props.left * 90}%`,
                    zIndex: 10,
                    backgroundColor: "white",
                }}
            />
            <div
                style={{
                    pointerEvents: "none",
                    userSelect: "none",
                    position: "absolute",
                    width: 32,
                    height: 32,
                    boxShadow: "0px 2px 8px 0px #1307221F",
                    borderRadius: 32,
                    top: 0,
                    right: `${(1 - props.right) * 90}%`,
                    zIndex: 10,
                    backgroundColor: "white",
                }}
            />
            <div
                onPointerDown={(e) => {
                    e.currentTarget.setPointerCapture(e.pointerId)
                    const rect = e.currentTarget.getBoundingClientRect()
                    const x = (e.clientX - rect.left) / rect.width
                    const distLeft = Math.abs(x - props.left)
                    const distRight = Math.abs(x - props.right)
                    if (distLeft < distRight) {
                        setLock("left")
                    } else {
                        setLock("right")
                    }
                }}
                onPointerUp={() => setLock(null)}
                onPointerMove={(e) => {
                    const rect = e.currentTarget.getBoundingClientRect()
                    const x = (e.clientX - rect.left) / rect.width

                    if (lock === "left") {
                        props.setLeft(Math.max(0, Math.min(x, props.right - 0.1)))
                    }
                    if (lock === "right") {
                        props.setRight(Math.min(1, Math.max(x, props.left + 0.1)))
                    }
                }}
                style={{
                    height: 32,
                    width: "100%",
                }}
            />
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    height: 4,
                    borderRadius: 4,
                    backgroundColor: colors.brand,
                    width: "100%",
                    zIndex: -1,
                }}
            />
        </div>
    )
}
