import { CookieConsents } from "../../cookie-consent/CookieConsent"

export type WebScript = {
    /**
     * The consent level required to execute this script.
     */
    consent: keyof CookieConsents
    /**
     * A key that identifies this script, to avoid double injects.
     */
    key: string

    /**
     * HTML Code to inject into the <head> of the page. This is injected
     * regardless of consent, BEFORE the script.
     */
    headHtml?: string

    /**
     * The JavaScript code to inject. The code is only executed if the user has given consent.
     */
    code: string
}

/**
 * Registers a script (JavScript snippet) to be injected into each WebPage's <head>
 * during server-side rendering based on the user's consent.
 */
export function WebScript(script: WebScript) {
    WebScript.all.push(script)
}
WebScript.all = [] as WebScript[]
