import { css } from "@emotion/react"
import {
    ButtonProps,
    createButton,
    ButtonElementTypeMap,
} from "../../../../../../packages/web-ui/components/controls/Button"
import { ButtonVariant, IconName, ResponsiveVariant, RedoitTheme } from "../../theme"
import { Icon } from "../visual/Icon"
import { responsiveHeadingLevel } from "../../helpers/css"

export const ThemedButton = createButton<RedoitTheme>()

/**
 * Type helper to make a properties K that is required in T, optional in the returned type.
 */
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

type Element = keyof ButtonElementTypeMap

export function Button<E extends Element = "button">({
    iconStart,
    iconEnd,
    selected,
    children,
    variant,
    size,
    ...rest
}: PartialBy<ButtonProps<RedoitTheme, E>, "variant" | "size"> & {
    /**
     * Button variant. Defaults to primary.
     */
    variant?: ResponsiveVariant<ButtonVariant>

    // TODO: Only allow this prop if variant === 'select'.
    selected?: boolean

    /**
     * Show an icon from the design system before the button text.
     */
    iconStart?: IconName

    /**
     * Show an icon from the design system after the button text.
     */
    iconEnd?: IconName
}) {
    const c = css(
        {
            borderWidth: size === "md" ? 2 : 1,
            width: rest.fullwidth ? "100%" : "auto",
        },
        // Icon only buttons should be circular, so just remove all x padding and ensure
        // width === height using aspect-ratio. && ensures higher specificity than the default
        // styles for the Button.
        // TODO: Auto prefix incoming css to base Button component with &&.
        !children && { "&&": { paddingLeft: 0, paddingRight: 0, aspectRatio: "1" } },
        responsiveHeadingLevel("4")
    )

    return (
        <ThemedButton
            css={c}
            data-selected={!!selected}
            size={size ?? "md"}
            variant={variant ?? "primary"}
            {...rest}
        >
            {iconStart && <Icon icon={iconStart} />}
            {children}
            {iconEnd && <Icon icon={iconEnd} />}
        </ThemedButton>
    )
}
