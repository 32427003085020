import { css } from "@emotion/react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { scaleValue } from "../ui/helpers/css"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Markdown, Url } from "../../../../reactor"
import { Heading } from "../ui/components/typography/Heading"
import { ReviewsCarousel } from "../ui/components/carousel/ReviewsCarousel"
import { Flex } from "../ui/components/base/Flex"
import { Text } from "../ui/components/typography/Text"
import { Button } from "../ui/components/buttons/Button"
import { Box } from "../ui/components/base/Box"
import { useReviews } from "../client"

import { percentageHexValues } from "../ui/constants/opacity"
import { Logo } from "../ui/components/visual/Logo"
import { TrustpilotStars } from "../ui/components/visual/TrustpilotStars"
import { redoitTheme, useRedoitTheme } from "../ui/theme"

const { useMediaQuery } = redoitTheme.helpers

/**
 * @icon ui-message-heart-square
 */
function TrustpilotRateUs(section: {
    /**
     * Heading for the reviews section.
     * @default '{"en": "Rate us on Trustpilot"}'
     */
    heading: Localized<string>

    /**
     * Text to show below the heading.
     * @default '{"en": "Your opinion is important for us. We would love to hear from you to keep getting better. Help us to grow by leaving us a note on Trustpilot."}'
     */
    text?: Localized<Markdown>

    /**
     * Show button linking to Trustpilot review page.
     */
    cta?: {
        /**
         * @default '{"en": "Leave us a review"}'
         */
        text: Localized<string>

        /**
         * @default https://www.trustpilot.com
         */
        url: Url
    }

    /**
     * How fast the carousel scrolls. Given in pixels per second.
     *
     * @default 30
     */
    autoScrollSpeed: number
}) {
    const localize = useLocalize()
    const reviews = useReviews()
    const { colors, helpers } = useRedoitTheme()
    const { responsiveCss } = helpers

    dayjs.extend(relativeTime)

    const isMobile = useMediaQuery("max", "sm")

    if (reviews.loading) return <></>

    return (
        <div
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                }
                // TODO: Fix
                // responsiveSectionSpacing()
            )}
        >
            <Flex
                backgroundColor="gray100"
                borderRadius="lg"
                css={css(
                    {
                        overflow: "hidden",
                        gap: 48,
                        alignItems: "flex-start",
                        flexDirection: "column",
                        paddingTop: 24,
                        paddingBottom: 24,
                    },
                    responsiveCss("min", "md", {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 48,
                        paddingRight: 48,
                        gap: 64,
                        alignItems: "center",
                        flexDirection: "row",
                        height: 560,
                    }),
                    responsiveCss("min", "lg", {
                        height: 680,
                        paddingLeft: 96,
                        paddingRight: 96,
                        gap: 112,
                    })
                )}
            >
                <Flex
                    direction="column"
                    flex="1 0 0"
                    alignItems="flex-start"
                    style={{ minWidth: 0 }}
                    css={responsiveCss("max", "sm", { paddingLeft: 24, paddingRight: 24 })}
                >
                    <Flex direction="column" gap={scaleValue(24)}>
                        <Flex gap={16} alignItems="center">
                            <Logo width={56} height="auto" />
                            <TrustpilotStars rating={5} />
                        </Flex>
                        <Heading level={1}>{localize(section.heading)}</Heading>
                        {section.text && (
                            <Text variant="body" size="lg" color="gray400">
                                {localize(section.text)}
                            </Text>
                        )}
                    </Flex>
                    {section.cta ? (
                        <Box margin={{ top: "sm" }}>
                            <Button
                                as="a"
                                href={section.cta.url.valueOf()}
                                target="_blank"
                                variant="primary"
                                css={responsiveCss("max", "xs", { width: "100%" })}
                            >
                                {localize(section.cta.text)}
                            </Button>
                        </Box>
                    ) : null}
                </Flex>
                <div
                    style={{
                        overflow: "hidden",
                        height: "100%",
                        flex: "0 0 auto",
                        position: "relative",
                    }}
                    css={css(
                        responsiveCss("max", "sm", {
                            width: "100%",
                            "&:after": {
                                content: '""',
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                width: 220,
                                zIndex: 1,
                                pointerEvents: "none",
                                right: 0,
                                background: `linear-gradient(90deg, ${colors.gray100}${percentageHexValues["0"]}, ${colors.gray100}${percentageHexValues["100"]})`,
                            },
                        }),
                        responsiveCss("min", "md", {
                            "&:before, &:after": {
                                content: '""',
                                position: "absolute",
                                left: 0,
                                height: 180,
                                zIndex: 1,
                                pointerEvents: "none",
                                width: "100%",
                            },
                            "&:before": {
                                top: 0,
                                background: `linear-gradient(0deg, ${colors.gray100}${percentageHexValues["0"]}, ${colors.gray100}${percentageHexValues["100"]})`,
                            },
                            "&:after": {
                                bottom: 0,
                                background: `linear-gradient(180deg, ${colors.gray100}${percentageHexValues["0"]}, ${colors.gray100}${percentageHexValues["100"]})`,
                            },
                        })
                    )}
                >
                    {/* <div
                        style={{
                            position: "absolute",
                            left: 0,
                            height: 180,
                            zIndex: 1,
                            pointerEvents: "none",
                            width: "100%",
                            bottom: 0,
                            // background: `linear-gradient(180deg, ${colors.gray100}${percentageHexValues["0"]}, ${colors.gray100}${percentageHexValues["100"]})`,
                            background: "magenta",
                        }}
                    >
                        a
                    </div> */}
                    <ReviewsCarousel
                        ReviewCardProps={{ backgroundColor: "grayWhite" }}
                        direction={isMobile ? "row" : "column"}
                        reviews={
                            reviews.data?.reviews.map(({ id, rating, ...r }) => ({
                                id: id.valueOf(),
                                rating: rating.valueOf(),
                                name: localize(r.name),
                                title: localize(r.title),
                                text: localize(r.text),
                                timestamp:
                                    (Date.now() - new Date(r.timestamp.valueOf()).valueOf()) /
                                        (60 * 60 * 24 * 1000) >
                                    7
                                        ? dayjs(r.timestamp.valueOf()).format("DD.MM.YYYY")
                                        : dayjs(r.timestamp.valueOf()).fromNow(),
                            })) ?? []
                        }
                        speed={section.autoScrollSpeed}
                    />
                </div>
            </Flex>
        </div>
    )
}

Section(TrustpilotRateUs)
