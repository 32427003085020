import { css } from "@emotion/react"
import { TrustpilotStar } from "../visual/TrustpilotStars"

import { responsiveHeadingLevel } from "../../helpers/css"
import { xlScaleFactor } from "../../constants/sizes"
import { Url } from "../../../../../../reactor"
import { useRedoitTheme } from "../../theme"

export function TrustpilotButton(props: { href?: Url; score: number; onClick?: () => void }) {
    const { colors } = useRedoitTheme()
    const style = {
        default: {
            color: colors.gray500,
            borderColor: colors.gray200,
        },
        hover: { borderColor: colors.gray400 },
        focus: { borderColor: colors.gray500 },
    }

    return (
        <button
            onClick={props.onClick}
            css={css(
                {
                    display: "flex",
                    alignItems: "center",
                    borderStyle: "solid",
                    borderWidth: 1,
                    height: 48 * xlScaleFactor,
                    borderRadius: 24,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingBottom: 1,
                    backgroundColor: colors.grayWhite,
                },
                responsiveHeadingLevel("4"),
                style.default
            )}
        >
            {props.score.toString().replace(".", ",")}
            <TrustpilotStar css={css({ marginLeft: 6, marginRight: 6 })} />
            Trustpilot
        </button>
    )
}
