import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { css } from "@emotion/react"
import { scaleValue } from "../../helpers/css"
import { Component } from "../../../../../../packages/editing/Component"
import { Image, ImageToCSS } from "../../../../../../reactor"
import { server } from "../../../../../../server"
import { responsiveCss, responsiveSpacing } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { Text } from "../typography/Text"
import { Body } from "../typography/Body"
import { Icon, IconName } from "../visual/Icon"
import { Box } from "../base/Box"
import { LabelNotification } from "../notification/LabelNotification"
import { percentageHexValues } from "../../constants/opacity"
import { AnimatePresence, motion, MotionConfig, useAnimate, usePresence } from "framer-motion"
import { springAnimations } from "../../constants/animation"
import { useRedoitTheme } from "../../theme"

export type DeviceCheckoutCardInfoItem = { id: string; icon: IconName; title: string; text: string }

export function DeviceCheckoutCard(props: {
    /**
     * The product being checked out.
     */
    product: {
        image: Image

        /**
         * Color.
         */
        color: string

        /**
         * Storage size.
         */
        storageSize: string
    }

    /**
     * Items with more info about the checkout.
     */
    infoItems?: DeviceCheckoutCardInfoItem[]

    /**
     * Price for the product and subscription being checked out.
     */
    checkout: {
        /**
         * Label for monthly total price.
         */
        monthlyTotalPriceLabel: string

        /**
         * Text below the monthly total price saying how much the total cost for the
         * rental period is.
         */
        rentalPeriodTotalPriceText: string

        /**
         * Text of currency per rental period unit (e.g. "kr/md.").
         */
        rentalPeriodCurrencyPeriodUnit: string

        /**
         * The original price.
         */
        originalPrice: number

        /**
         * The checkout price with discount applied.
         */
        discountedPrice: number
    }

    /**
     * The subscription the customer is signing up for.
     */
    subscription: {
        /**
         * Duration in months.
         */
        duration: number

        /**
         * Localized string for months.
         */
        unit: string
    }

    /**
     * Label to show in the top right corner.
     */
    label?: string
}) {
    const { colors } = useRedoitTheme()
    const isDiscountedPrice = useMemo(
        () => props.checkout.originalPrice > props.checkout.discountedPrice,
        [props.checkout.originalPrice, props.checkout.discountedPrice]
    )

    return (
        <Flex
            backgroundColor="gray500"
            borderRadius="lg"
            color="grayWhite"
            css={css(
                {
                    position: "relative",
                    padding: scaleValue(24),
                    flexDirection: "column-reverse",
                    gap: 24,
                },
                responsiveCss("min", "md", {
                    height: scaleValue(800),
                    gap: 40,
                    maxHeight: `calc(100vh - 24px - ${scaleValue(48)} - 24px - 24px)`,
                    padding: scaleValue(40),
                    paddingTop: scaleValue(60),
                    flexDirection: "column",
                })
            )}
        >
            {props.label && (
                <div css={css({ position: "absolute" }, responsiveSpacing("lg", ["top", "right"]))}>
                    <LabelNotification color="brand">{props.label}</LabelNotification>
                </div>
            )}
            <Flex
                flex="1 1 auto"
                alignItems="center"
                justifyContent="space-between"
                shrink={1}
                css={css(
                    { flexDirection: "column-reverse", gap: 24 },
                    responsiveCss("min", "md", {
                        flexDirection: "column",
                        gap: 40,
                    })
                )}
            >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={props.product.image.valueOf()}
                    style={{
                        maxHeight: scaleValue(416),
                        display: "flex",
                        flex: "1 0 auto",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        scrollSnapAlign: "center",
                    }}
                >
                    <div
                        css={responsiveCss("max", "sm", { minHeight: 264 })}
                        style={{
                            display: "flex",
                            height: "100%",
                            width: "100%",
                            flexDirection: "column",
                            backgroundImage: ImageToCSS(props.product.image, { width: 900 }),
                            backgroundPosition: "center",
                            objectFit: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "auto 100%",
                            maxHeight: scaleValue(420),
                        }}
                    />
                </motion.div>
                <Flex direction="column" gap={8} style={{ width: "100%" }}>
                    <MotionConfig transition={springAnimations["200"]}>
                        <AnimatePresence initial={false}>
                            {props.infoItems?.map((item) => (
                                <AppearingItem key={item.id}>
                                    <Flex
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        style={{
                                            backgroundColor: "#31253f",
                                        }}
                                        css={css(
                                            {
                                                borderRadius: 8,
                                                padding: 16,
                                            },
                                            responsiveCss("min", "md", {
                                                borderRadius: 20,
                                            })
                                        )}
                                        gap={[8, ["min", "md", 12]]}
                                    >
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            css={responsiveCss("min", "md", {
                                                backgroundColor: `${colors.grayWhite}${percentageHexValues["10"]}`,
                                                borderRadius: 8,
                                                height: scaleValue(48),
                                                width: scaleValue(48),
                                            })}
                                        >
                                            <Icon icon={item.icon} />
                                        </Flex>
                                        <Box>
                                            <Body size="sm">{item.title}</Body>
                                            <Body size="xs" style={{ opacity: 0.6 }}>
                                                {item.text}
                                            </Body>
                                        </Box>
                                    </Flex>
                                </AppearingItem>
                            ))}
                        </AnimatePresence>
                    </MotionConfig>
                </Flex>
            </Flex>
            <Flex flex="0 0 auto" justifyContent="space-between" alignItems="flex-end">
                <div>
                    <Flex alignItems="center">
                        <Text variant="heading" level={3}>
                            {props.checkout.monthlyTotalPriceLabel}
                        </Text>
                    </Flex>
                    <Text variant="body" size="xs" style={{ opacity: 0.6 }}>
                        {props.checkout.rentalPeriodTotalPriceText}
                    </Text>
                </div>
                <Flex direction="column" alignItems="flex-end">
                    <Flex alignItems="baseline">
                        {isDiscountedPrice && (
                            <Text
                                variant="body"
                                size="sm"
                                color="gray200"
                                style={{ textDecoration: "line-through", marginRight: 4 }}
                            >
                                {props.checkout.originalPrice}
                            </Text>
                        )}
                        <Text
                            variant="heading"
                            level={2}
                            color={isDiscountedPrice ? "brand" : undefined}
                        >
                            {props.checkout.discountedPrice}
                        </Text>
                    </Flex>
                    <Body size="sm" style={{ opacity: 0.6, textTransform: "lowercase" }}>
                        {props.checkout.rentalPeriodCurrencyPeriodUnit}
                    </Body>
                </Flex>
            </Flex>
        </Flex>
    )
}

function AppearingItem(props: { children?: ReactNode }) {
    const [isPresent, safeToRemove] = usePresence()
    const [scope, animate] = useAnimate()

    const ref = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState(0)

    const updateHeight = useCallback(() => {
        setHeight(ref.current?.clientHeight ?? 0)
    }, [setHeight])

    const heightRef = useRef<number>(0)

    useEffect(() => {
        if (isPresent) {
            const enterAnimation = async () => {
                await animate(scope.current, {
                    opacity: 1,
                    height: ref.current?.clientHeight ?? 0,
                    transform: `translateY(0px)`,
                })
            }
            void enterAnimation()
        } else {
            const exitAnimation = async () => {
                await animate(scope.current, {
                    opacity: 0,
                    height: 0,
                    transform: `translateY(-${(ref.current?.clientHeight ?? 0) - 16}px)`,
                })
                safeToRemove()
            }
            void exitAnimation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPresent])

    useEffect(() => {
        heightRef.current = ref.current?.clientHeight ?? 0
        updateHeight()
    }, [updateHeight])

    return (
        <div
            ref={scope}
            style={{
                opacity: 0,
                height: 0,
                transform: `translateY(${height * -1 + 16}px)`,
            }}
        >
            <div ref={ref}>{props.children}</div>
        </div>
    )
}

Component(DeviceCheckoutCard, {
    name: "DeviceCheckoutCard",
    gallery: {
        path: "Cards/DeviceCheckoutCard",
        items: [
            {
                variants: [
                    {
                        props: {
                            product: {
                                image: `${server()}/static/redoit/product-card-product-image3.png` as any as Image,
                                color: "Midnight",
                                storageSize: "256 GB",
                            },
                            subscription: {
                                duration: 24,
                                unit: "months lease",
                            },
                            checkout: {
                                originalPrice: 320,
                                discountedPrice: 250,
                                rentalPeriodCurrencyPeriodUnit: "kr/md.",
                                monthlyTotalPriceLabel:
                                    "Totalt 3400 NOK for leieperiode på 12 måneder",
                                rentalPeriodTotalPriceText: "Pris per måned",
                            },
                            infoItems: [
                                {
                                    id: "trade-in",
                                    title: "Trade-in cashback",
                                    text: "We will deduct 50 kr. for the next 20 months.",
                                    icon: "device",
                                },
                            ],
                            label: "Trade-in",
                        },
                    },
                ],
            },
        ],
    },
})
