import { ComponentPropsWithoutRef, ReactNode } from "react"
import { css } from "@emotion/react"
import { ColorGeneric, HeadingLevelGeneric, ThemeAny, useTheme } from "../../theme"
import { SpacingGeneric } from "../../theme"
import { resetStyles, underline } from "../../helpers/css"

export function createHeading<T extends ThemeAny>() {
    return function Heading({
        level,
        color,
        margin,
        padding,
        truncate,
        children,
        ...rest
    }: {
        level: HeadingLevelGeneric<T>

        /**
         * Specify a text color from the color palette.
         */
        color?: ColorGeneric<T>

        /**
         * Specify margin.
         */
        margin?: SpacingGeneric<T>

        /**
         * Specify padding.
         */
        padding?: SpacingGeneric<T>

        /**
         * Truncate overflowing text. Forces nowrap and adds ellipsis.
         */
        truncate?: boolean

        /**
         * @reflection any
         */
        children: ReactNode
    } & ComponentPropsWithoutRef<"h1">) {
        const HeadingTag = `h${level}` as "h1"
        const { helpers, colors } = useTheme<T>()
        const { spacingToCss, responsiveHeadingLevel } = helpers

        return (
            <HeadingTag
                css={css(
                    resetStyles(HeadingTag),
                    { "& a": underline },
                    responsiveHeadingLevel(level),
                    truncate && {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    },
                    color && { color: colors[color] },
                    margin && spacingToCss("margin", margin),
                    padding && spacingToCss("padding", padding)
                )}
                {...rest}
            >
                {children}
            </HeadingTag>
        )
    }
}
