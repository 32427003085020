import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { NonNegativeNumber } from "../../../../reactor"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { FaqBlock } from "../ui/components/blocks/FaqBlock"
import { useFrequentlyAskedQuestions } from "../client"
import { responsiveSectionSpacing } from "../ui/theme"

/**
 * @icon ui-message-question-circle
 */
function FrequentlyAskedQuestions(section: {
    /**
     * Heading for the FAQ block.
     * @default '{"en": "Frequently asked questions", "no": "Vanlige spørsmål"}'
     */
    heading: Localized<string>

    /**
     * The maximum number of questions initially displayed before clicking "Show all" button.
     *
     * @default 3
     */
    maxInitialQuestions: NonNegativeNumber

    /**
     * Text for button to show all questions.
     * @default '{"en": "Show all", "no": "Vis alle"}'
     */
    showAllButtonText: Localized<string>
}) {
    const localize = useLocalize()
    const frequentlyAskedQuestions = useFrequentlyAskedQuestions()
    if (!frequentlyAskedQuestions.loading && !frequentlyAskedQuestions.data) return <></>

    return (
        <div css={css(responsiveSectionSpacing())}>
            {frequentlyAskedQuestions.data?.questions.length && (
                <FaqBlock
                    heading={localize(section.heading)}
                    questions={frequentlyAskedQuestions.data.questions.map((item) => ({
                        id: item.id,
                        question: localize(item.question),
                        answer: localize(item.answer),
                    }))}
                    showAllButtonText={localize(section.showAllButtonText)}
                    maxInitialQuestions={section.maxInitialQuestions.valueOf()}
                />
            )}
        </div>
    )
}

Section(FrequentlyAskedQuestions)
