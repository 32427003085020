import { useEffect } from "react"
import { WebScript } from "../../../../packages/web/scripts/WebScript"
import { useCookieConsents } from "../../../../studio/client"

const OFFER_SID = "vfc15f"
const EVENT_SID = "wj0bj2"

// Inject script for Cirlcewise / TargetCircle to be included on every page.
WebScript({
    consent: "marketing",
    key: "target-circle-entrypoint",
    code: `
        window.tmt_offer_sid = '${OFFER_SID}';
        (function() {
            var timestamp = +new Date;
            var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
            po.src = 'https://cdn.tmtarget.com/tracking/s/entrypoint_v2.min.js?t=' + timestamp;
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
        })();
    `,
})

/**
 * Hook to track a sale with TargetCircle. Just include the hook on the order confirmation page,
 * and the rest should be taken care of by the script. Documentation available at
 * https://targetcircle.com/blog/automatic-article/installing-first-party-tracking-v2/
 */
export function useTargetCircleSaleTracking() {
    const cookieConsents = useCookieConsents()

    useEffect(() => {
        if (
            cookieConsents.data?.consents?.marketing &&
            typeof document !== "undefined" &&
            // Avoid appending this script more than once.
            !document.querySelector(
                'script[src*="cdn.trackmytarget.com/tracking/s/checkout.min.js"]'
            )
        ) {
            const el = document.createElement("script")
            el.setAttribute("src", "https://cdn.trackmytarget.com/tracking/s/checkout.min.js")
            el.setAttribute("data-type", "sale")
            el.setAttribute("data-offer-sid", OFFER_SID)
            el.setAttribute("data-event-sid", EVENT_SID)
            document.body.appendChild(el)
        }
    }, [cookieConsents.data?.consents?.marketing])
}
