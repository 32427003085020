import { css } from "@emotion/react"
import { Flex } from "../../ui/components/base/Flex"
import { SoldOutProps } from "../Checkout"
import { responsiveCss } from "../../ui/helpers/css"
import { Heading } from "../../ui/components/typography/Heading"
import { Text } from "../../ui/components/typography/Text"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Button } from "../../ui/components/buttons/Button"
import { useWebPageInfo } from "../../../../../studio/client"

export function SoldOut(props: SoldOutProps) {
    const localize = useLocalize()
    const primaryActionPageInfo = useWebPageInfo(props.primaryAction.page)

    return (
        <Flex justifyContent="center" style={{ width: "100%", height: "100%" }}>
            <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                css={css(responsiveCss("max", "sm", { padding: "16px 0" }))}
            >
                <Flex
                    direction="column"
                    alignItems="center"
                    gap={12}
                    css={css(
                        responsiveCss("max", "sm", { gap: 8, marginBottom: 32 }),
                        responsiveCss("min", "md", {
                            padding: "0 40px",
                            gap: 12,
                            marginBottom: 40,
                        })
                    )}
                >
                    <Heading color="gray500" level={2}>
                        {localize(props.heading)}
                    </Heading>
                    <Text color="gray400" variant="body" size="md">
                        {localize(props.text)}
                    </Text>
                </Flex>
                {primaryActionPageInfo.data?.slug ? (
                    <Button
                        variant="primary"
                        size="md"
                        as="a"
                        href={`/${localize(primaryActionPageInfo.data.slug)}`}
                    >
                        {localize(props.primaryAction.buttonText)}
                    </Button>
                ) : null}
            </Flex>
        </Flex>
    )
}
