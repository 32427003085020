import { useCallback } from "react"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../../../packages/localization/client-side/useLocalize"
import { CurrencyAmount } from "../../client"
import { CurrencyCode } from "../../../../../reactor"
import { Localized } from "../../../../../packages/localization/Localized"

export const monthsLong = { no: "måneder", en: "months" }
export const monthsShort = { no: "md", en: "mo" }

export const localizedCurrencyCodes: Partial<Record<CurrencyCode, Localized<string>>> = {
    [CurrencyCode("NOK")]: { no: "kr", en: "kr" },
}

export function useLocalizeCurrency() {
    const localize = useLocalize()
    return useCallback(
        (cc: CurrencyCode) => {
            return `${localize(localizedCurrencyCodes[cc] ?? { no: cc })}`
        },
        [localize]
    )
}

/**
 * A hook to get a formatted and localized CurrencyAmount. Used the localizedCurrencyCodes defined
 * in this file, with fallback to just using the currency code if translations for the currency
 * is not defined.
 */
export function useFormatAmount() {
    const localize = useLocalize()
    const currentLocale = useCurrentLocale()

    return useCallback(
        (a: CurrencyAmount) => {
            return `${Intl.NumberFormat(currentLocale.valueOf(), {
                minimumFractionDigits: Number.isInteger(a.majorUnits) ? 0 : 2,
                maximumFractionDigits: 2,
            }).format(
                a.majorUnits
            )} ${localize(localizedCurrencyCodes[a.currency] ?? { no: a.currency })}`
        },
        [currentLocale, localize]
    )
}
