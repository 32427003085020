import { ComponentProps } from "react"
import { css } from "@emotion/react"
import { MarkdownView as MarkdownViewComponent } from "../../../../../../packages/markdown-edit/MarkdownView"
import { useRedoitTheme } from "../../theme"

export function MarkdownView(props: ComponentProps<typeof MarkdownViewComponent>) {
    const { colors, helpers } = useRedoitTheme()
    const { responsiveHeadingCss } = helpers
    return (
        <div
            css={css({
                "table, tr, td, th": {
                    borderColor: colors.gray200,
                    borderWidth: 1,
                },
                table: {
                    marginBottom: 16,
                },
                "th, td": {
                    padding: 8,
                },
                th: { fontWeight: 500, backgroundColor: colors.gray100 },
                h1: css(responsiveHeadingCss(1), { marginTop: ".4em", marginBottom: ".4em" }),
                h2: css(responsiveHeadingCss(2), { marginTop: ".4em", marginBottom: ".4em" }),
                h3: css(responsiveHeadingCss(3), { marginTop: ".4em", marginBottom: ".4em" }),
                h4: css(responsiveHeadingCss(4), { marginTop: ".4em", marginBottom: ".4em" }),
            })}
        >
            <MarkdownViewComponent {...props} />
        </div>
    )
}
