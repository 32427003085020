import { ComponentPropsWithoutRef, ReactNode, Ref } from "react"
import { AnimationLifecycles, motion as Motion } from "framer-motion"
import { SerializedStyles, css, jsx } from "@emotion/react"
import { AnimationProps } from "framer-motion"
import {
    BorderRadiusGeneric,
    ColorGeneric,
    ResponsiveStyleValueGeneric,
    ResponsiveVariantGeneric,
    ScreenSizeGeneric,
    SpacingGeneric,
    ThemeAny,
    useTheme,
} from "../../theme"
import { responsiveBoxShadow } from "../../../../models/redoit/web/ui/theme"

type ElementTypeMap = {
    a: HTMLAnchorElement
    button: HTMLButtonElement
    div: HTMLDivElement
    label: HTMLLabelElement
    nav: HTMLElement
    section: HTMLElement
}

type Element = keyof ElementTypeMap

export type BoxProps<T extends ThemeAny = never, E extends Element = "div"> = {
    as?: E

    /**
     * Specify padding.
     */
    padding?: SpacingGeneric<T>

    /**
     * Specify margin.
     */
    margin?: SpacingGeneric<T>

    /**
     * Add framer-motion animations. Any animation props that can be added as a prop
     * to the motion component can be added here.
     */
    motion?: AnimationProps & AnimationLifecycles

    /**
     * Specify a border radius. The border radius is specified in the relative
     * BorderRadiusVariant unit.
     */
    borderRadius?: BorderRadiusGeneric<T>

    /**
     * Specify a border color from the color palette. This will automatically add a border
     * with style solid and width 1. Use props `borderWidth` and `borderStyle`, or `css`
     * to override.
     */
    borderColor?: ResponsiveVariantGeneric<ScreenSizeGeneric<T>, ColorGeneric<T>>

    /**
     * Specify border width.
     */
    borderWidth?: ResponsiveStyleValueGeneric<ScreenSizeGeneric<T>>

    /**
     * Specify a text color from the color palette.
     */
    color?: ColorGeneric<T>

    /**
     * Specify a text color from the color palette.
     */
    backgroundColor?: ResponsiveVariantGeneric<ScreenSizeGeneric<T>, ColorGeneric<T>>

    /**
     * Elevate the box by applying a box-shadow.
     */
    elevation?: boolean

    /**
     * Any additional CSS to apply.
     */
    css?: SerializedStyles

    /**
     * A reference to the rendered element.
     */
    elementRef?: Ref<ElementTypeMap[E]>

    /**
     * @reflection any
     */
    children?: ReactNode
} & Omit<ComponentPropsWithoutRef<E>, "ref">

/**
 * A generic Box component for a convenient way to render a Box with rules from the design system
 * applied, like colors and responsive border radius.
 */
export function createBox<T extends ThemeAny>() {
    return function Box<E extends Element = "div">({
        as,
        motion,
        backgroundColor,
        borderColor,
        borderWidth,
        borderRadius,
        color,
        elevation,
        padding,
        margin,
        elementRef: boxRef,
        children,
        ...rest
    }: BoxProps<T, E>) {
        const { colors, helpers } = useTheme<T>()
        const { responsivePropCss, responsiveValueCss, responsiveBorderRadius, spacingToCss } =
            helpers

        const props = {
            style: rest.style,
            css: css(
                color && {
                    color: colors[color],
                },
                backgroundColor && responsivePropCss(colors, "backgroundColor", backgroundColor),
                borderColor && {
                    borderStyle: "solid",
                    borderWidth: 1,
                },
                borderWidth && responsiveValueCss("borderWidth", borderWidth),
                borderColor && responsivePropCss(colors, "borderColor", borderColor),
                borderRadius && responsiveBorderRadius(borderRadius),
                padding && spacingToCss("padding", padding),
                margin && spacingToCss("margin", margin),
                elevation && responsiveBoxShadow(),
                rest.css
            ),
        }

        const el = as ?? "div"

        if (typeof motion !== "undefined") {
            return jsx(
                typeof motion !== "undefined" ? Motion[el as "div"] : el,
                {
                    // The typing is starting to get complicated and struggled with motion not
                    // accepting the rest spread and the forwarded ref. Cast rest as any fixes it,
                    // and I don't think it should be a problem as long as all props are typed.
                    ...motion,
                    ...(rest as any),
                    ...props,
                    ref: boxRef,
                },
                children
            )
        }

        return jsx(
            el,
            {
                ...rest,
                ...props,
                ref: boxRef,
            },
            children
        )
    }
}
