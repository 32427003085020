import { useState, useEffect, useRef, ReactNode, RefObject, useCallback, useMemo, Ref } from "react"
import { createPortal } from "react-dom"
import { css, Global } from "@emotion/react"
import { AnimatePresence, MotionConfig } from "framer-motion"
import { Box } from "../base/Box"
import { springAnimations } from "../../constants/animation"
import { boxShadow } from "../../constants/shadow"
import { pageSize } from "../../constants/sizes"
import { useRedoitTheme } from "../../theme"

// Size (or rather height) of the arrow pointing towards the trigger element. Width is 2x height.
const ARROW_SIZE = 8
// Distance from the trigger element to the tooltip (arrow).
const TOOLTIP_TRIGGER_MARGIN = 4
// Distance from the trigger element edge to the trigger arrow, for left or right aligned tooltips.
const TRIGGER_ARROW_OFFSET = 16
// Distance from tooltip edge to the arrow, for left or right aligned tooltips.
const TOOLTIP_ARROW_OFFSET = 32
// Tooltip max width.
const TOOLTIP_MAX_WIDTH = "400px"
const DEFAULT_PLACEMENT = "bottom-center"
const DEFAULT_ALIGNMENT = "right"

type Placement =
    | "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right"
type Alignment = "left" | "center" | "right" | "fullwidth"
type InternalPlacementY = "top" | "bottom"
type InternalPlacementX = "left" | "center" | "right"
type InternalPlacement = {
    x: InternalPlacementX
    y: InternalPlacementY
}
type Coordinates = {
    y: number
    x: number
}
type TooltipProps = {
    /**
     * Preferred placement relative to the trigger element.
     *
     * @default bottom-center
     */
    placement?: Placement

    /**
     * Preferred arrow position relative to the trigger element.
     *
     * @default right
     */
    alignment?: Alignment

    /**
     * @reflection any
     */
    renderTrigger: (ref: Ref<any>) => ReactNode

    /**
     * If no children are provided, no popover will be rendered, only the trigger.
     *
     * @reflection any
     */
    children: ReactNode
}

type Rects = {
    triggerRect: DOMRect
    tooltipRect: DOMRect
}

export const Tooltip = (props: TooltipProps) => {
    const { colors } = useRedoitTheme()
    const preferredPlacement: InternalPlacement = useMemo(
        () => ({
            x: (props.placement ?? DEFAULT_PLACEMENT).split("-")[1] as InternalPlacementX,
            y: (props.placement ?? DEFAULT_PLACEMENT).split("-")[0] as InternalPlacementY,
        }),
        [props.placement]
    )
    const preferredAlignment = props.alignment ?? DEFAULT_ALIGNMENT

    const [tooltipPlacement, setTooltipPlacement] = useState<InternalPlacement | undefined>()
    const [tooltipAlignment, setTooltipAlignment] = useState<Alignment | undefined>()
    const [tooltipCoordinates, setTooltipCoordinates] = useState<Coordinates | undefined>()
    const [showTooltip, setShowTooltip] = useState(false)
    const [enterAnimationComplete, setEnterAnimationComplete] = useState(false)
    // This is used in case of fullwidth placement, where the constant arrow offset is not sufficient.
    const [arrowOffsetOverride, setArrowOffsetOverride] = useState<undefined | number>()

    const tooltipRef = useRef<HTMLDivElement>(null)
    const tooltipPreRenderRef = useRef<HTMLDivElement>(null)
    const triggerRef = useRef<HTMLDivElement>(null)
    const mouseOverRef = useRef<boolean>(false)

    // Returns the relevant DOMRects used in the component if refs are set as expected.
    function getRects(): Rects | undefined {
        const triggerRect = triggerRef.current?.getBoundingClientRect()
        const tooltipRect = tooltipPreRenderRef.current?.getBoundingClientRect()
        if (tooltipRect && triggerRect) return { triggerRect, tooltipRect }
    }

    const getTooltipElementCoordinates = useCallback(
        (placement: InternalPlacement, alignment: Alignment) => {
            const { y, x } = placement
            const rects = getRects()
            if (rects) {
                const { triggerRect, tooltipRect } = rects
                const alignmentOffset =
                    alignment === "right"
                        ? 0 - TOOLTIP_ARROW_OFFSET
                        : alignment === "center"
                          ? (rects.tooltipRect.width / 2) * -1
                          : alignment === "left"
                            ? rects.tooltipRect.width * -1 + TOOLTIP_ARROW_OFFSET
                            : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                              alignment === "fullwidth"
                              ? triggerRect.x * -1 - triggerRect.width / 2 + pageSize.xs.paddingLeft
                              : 0

                const triggerX =
                    x === "left"
                        ? triggerRect.left + TRIGGER_ARROW_OFFSET
                        : x === "right"
                          ? triggerRect.right - TRIGGER_ARROW_OFFSET
                          : Math.round(triggerRect.x + triggerRect.width / 2)
                const triggerY =
                    y === "top"
                        ? triggerRect.top - tooltipRect.height - ARROW_SIZE - TOOLTIP_TRIGGER_MARGIN
                        : triggerRect.bottom + ARROW_SIZE + TOOLTIP_TRIGGER_MARGIN

                return { x: triggerX + alignmentOffset, y: triggerY }
            }
        },
        []
    )

    function getTriggerXForPlacement(triggerRect: DOMRect, placement: InternalPlacementX) {
        return placement === "center"
            ? triggerRect.left + triggerRect.width / 2
            : triggerRect[placement]
    }

    function getAlignmentSpanX(tooltipRect: DOMRect, alignment: Alignment): [number, number] {
        return alignment === "center"
            ? [
                  (tooltipRect.left + tooltipRect.width / 2) * -1,
                  tooltipRect.left + tooltipRect.width / 2,
              ]
            : alignment === "left"
              ? [tooltipRect.width * -1, 0]
              : [0, tooltipRect.width]
    }

    function getTriggerYForPlacement(triggerRect: DOMRect, placement: InternalPlacementY) {
        return placement === "top" ? triggerRect.top : triggerRect.bottom
    }

    function getPlacementSpanY(
        tooltipRect: DOMRect,
        placement: InternalPlacementY
    ): [number, number] {
        return placement === "top" ? [tooltipRect.height * -1, 0] : [0, tooltipRect.height]
    }

    const checkCombinationSpaceX = useCallback(
        (rects: Rects, placement: InternalPlacement, alignment: Alignment): [number, number] => {
            const x = getTriggerXForPlacement(rects.triggerRect, placement.x)
            const asx = getAlignmentSpanX(rects.tooltipRect, alignment)
            return [x + asx[0], x + asx[1]]
        },
        []
    )

    const checkCombinationSpaceY = useCallback(
        (rects: Rects, placement: InternalPlacementY): [number, number] => {
            const y = getTriggerYForPlacement(rects.triggerRect, placement)
            const py = getPlacementSpanY(rects.tooltipRect, placement)
            return [y + py[0], y + py[1]]
        },
        []
    )

    function fitsInViewportX(xx: [number, number]) {
        return xx.filter((x) => x > 0 && x < window.innerWidth).length === 2
    }
    function fitsInViewportY(yy: [number, number]) {
        return yy.filter((y) => y > 0 && y < window.innerHeight).length === 2
    }

    const updatePosition = useCallback(() => {
        const rects = getRects()
        if (rects) {
            const yPlacements: InternalPlacementY[] = ["top", "bottom"]
            const yPlacement = yPlacements
                .sort((p1) => (p1 === preferredPlacement.y ? -1 : 1))
                .find((p) => fitsInViewportY(checkCombinationSpaceY(rects, p)))

            const alignments: Alignment[] = ["left", "center", "right"]
            const alignment = alignments
                // Prioritize perferred alignment and center.
                .sort((a1, a2) =>
                    a1 === preferredAlignment
                        ? -1
                        : a1 === "center" && a2 !== preferredAlignment
                          ? -1
                          : 1
                )
                .find((a) => fitsInViewportX(checkCombinationSpaceX(rects, preferredPlacement, a)))

            if (alignment && yPlacement) {
                const coordinates = getTooltipElementCoordinates(
                    {
                        x: preferredPlacement.x,
                        y: yPlacement,
                    },
                    alignment
                )

                if (coordinates) {
                    setTooltipPlacement({
                        x: preferredPlacement.x,
                        y: yPlacement,
                    })
                    setTooltipAlignment(alignment)
                    setTooltipCoordinates(coordinates)
                }
            } else if (yPlacement) {
                // If we found an yPlacement but no aligntment, we're assuming it was because the
                // tooltip was placed partially outside of the screen width for all the preferred
                // alignments. So try to get coordinates for a fullwith alignment, where we try to
                // position it around x = 0 + page left padding.
                const coordinates = getTooltipElementCoordinates(
                    {
                        x: preferredPlacement.x,
                        y: yPlacement,
                    },
                    "fullwidth"
                )

                if (coordinates) {
                    setTooltipPlacement({
                        x: preferredPlacement.x,
                        y: yPlacement,
                    })
                    setTooltipAlignment("fullwidth")
                    setTooltipCoordinates(coordinates)
                    setArrowOffsetOverride(
                        rects.triggerRect.x + rects.triggerRect.width / 2 - coordinates.x
                    )
                }
            } else {
                // eslint-disable-next-line no-console
                console.warn("Could not find alignment or placement for tooltip.")
            }
        }
    }, [
        checkCombinationSpaceX,
        checkCombinationSpaceY,
        getTooltipElementCoordinates,
        preferredAlignment,
        preferredPlacement,
    ])

    const handlePointerOver = useCallback(() => {
        mouseOverRef.current = true
        updatePosition()
        if (!showTooltip) setShowTooltip(true)
    }, [showTooltip, updatePosition])

    const handlePointerLeave = useCallback(() => {
        mouseOverRef.current = false
        if (enterAnimationComplete) setShowTooltip(false)
    }, [enterAnimationComplete])

    const handleScroll = useCallback(() => {
        if (showTooltip) {
            updatePosition()
        }
    }, [showTooltip, updatePosition])

    useEffect(() => {
        if (!props.children) return

        const triggerRefCurrent = triggerRef.current
        if (triggerRefCurrent) {
            triggerRefCurrent.addEventListener("pointerover", handlePointerOver)
            triggerRefCurrent.addEventListener("pointerleave", handlePointerLeave)
            triggerRefCurrent.addEventListener("click", handlePointerLeave)
            triggerRefCurrent.addEventListener("touchstart", handlePointerOver)
            triggerRefCurrent.addEventListener("touchend", handlePointerLeave)

            if (typeof window !== "undefined") {
                window.addEventListener("scroll", handleScroll)
            }

            return () => {
                triggerRefCurrent.removeEventListener("pointerover", handlePointerOver)
                triggerRefCurrent.removeEventListener("pointerleave", handlePointerLeave)
                triggerRefCurrent.removeEventListener("click", handlePointerLeave)
                triggerRefCurrent.removeEventListener("touchstart", handlePointerOver)
                triggerRefCurrent.removeEventListener("touchend", handlePointerLeave)

                if (typeof window !== "undefined") {
                    window.removeEventListener("scroll", handleScroll)
                }
            }
        }
    }, [props.children, handlePointerOver, handlePointerLeave, handleScroll])

    return (
        <>
            {props.renderTrigger(triggerRef)}
            {showTooltip && (
                <Global
                    styles={css({
                        "*, *:before, *:after": {
                            userSelect: "none",
                            MozUserSelect: "none",
                            msUserSelect: "none",
                            WebkitTouchCallout: "none",
                            WebkitUserSelect: "none",
                        },
                    })}
                />
            )}
            {props.children &&
                typeof window !== "undefined" &&
                createPortal(
                    <>
                        <div
                            aria-hidden={true}
                            ref={tooltipPreRenderRef}
                            css={css({
                                position: "absolute",
                                top: 0,
                                left: 0,
                                opacity: 0,
                                zIndex: -1,
                                maxWidth: `min(calc(100vw - ${pageSize.xs.paddingLeft}px - ${pageSize.xs.paddingLeft}px), ${TOOLTIP_MAX_WIDTH})`,
                                padding: "16px 24px",
                            })}
                        >
                            {props.children}
                        </div>
                        <MotionConfig transition={springAnimations["200"]}>
                            <AnimatePresence>
                                {showTooltip && tooltipCoordinates && tooltipPlacement && (
                                    <Box
                                        key="tooltip"
                                        motion={{
                                            variants: {
                                                hide: {
                                                    opacity: 0,
                                                    translateY:
                                                        tooltipPlacement.y === "top" ? 12 : -12,
                                                },
                                                show: {
                                                    opacity: 1,
                                                    translateY: 0,
                                                },
                                            },
                                            initial: "hide",
                                            animate: "show",
                                            exit: "hide",
                                            onAnimationComplete: (variant) => {
                                                setEnterAnimationComplete(
                                                    variant === "show" ? true : false
                                                )
                                                if (
                                                    variant === "show" &&
                                                    mouseOverRef.current === false
                                                ) {
                                                    setShowTooltip(false)
                                                }
                                            },
                                        }}
                                        elementRef={tooltipRef}
                                        css={css(
                                            {
                                                opacity: 1,
                                                top: tooltipCoordinates.y,
                                                left: tooltipCoordinates.x,
                                            },
                                            {
                                                position: "fixed",
                                                maxWidth: `min(calc(100vw - ${pageSize.xs.paddingLeft}px - ${pageSize.xs.paddingLeft}px), ${TOOLTIP_MAX_WIDTH})`,
                                                backgroundColor: colors.grayWhite,
                                                padding: "16px 24px",
                                                borderRadius: 16,
                                                boxShadow,
                                                whiteSpace: "normal",
                                                zIndex: 10000,
                                                "::after": {
                                                    content: "''",
                                                    position: "absolute",
                                                    top:
                                                        tooltipPlacement.y === "top"
                                                            ? "100%"
                                                            : ARROW_SIZE * 2 * -1,
                                                    left:
                                                        typeof arrowOffsetOverride !== "undefined"
                                                            ? arrowOffsetOverride
                                                            : tooltipAlignment === "right"
                                                              ? TOOLTIP_ARROW_OFFSET
                                                              : tooltipAlignment === "left"
                                                                ? `calc(100% - ${TOOLTIP_ARROW_OFFSET}px)`
                                                                : "50%",
                                                    transform: "translateX(-50%)",
                                                    borderWidth: ARROW_SIZE,
                                                    borderStyle: "solid",
                                                    borderColor:
                                                        tooltipPlacement.y === "bottom"
                                                            ? `transparent transparent ${colors.grayWhite} transparent`
                                                            : `${colors.grayWhite} transparent transparent transparent`,
                                                },
                                            }
                                        )}
                                    >
                                        {props.children}
                                    </Box>
                                )}
                            </AnimatePresence>
                        </MotionConfig>
                    </>,
                    document.body
                )}
        </>
    )
}
