import { Component } from "../../../../../../packages/editing/Component"
import { createBody } from "../../../../../../packages/web-ui/components/typography/Body"
import { RedoitTheme } from "../../theme"

export const Body = createBody<RedoitTheme>()

Component(Body, {
    name: "Body",
    gallery: {
        path: "Typography/Body",
        items: [
            { variants: [{ props: { size: "md", children: "Hello, world!" } }] },
            {
                title: "Fixed size",
                variants: [
                    { props: { size: ["fixed", "md", "md"] as any, children: "Hello, world!" } },
                ],
            },
            {
                title: "Media Query Responsive Sizes",
                variants: [
                    { props: { size: ["lg", ["min", "md", "xs"]], children: "Hello, world!" } },
                ],
            },
            {
                variants: [
                    {
                        props: {
                            size: "lg",
                            children:
                                "Our mission is to accelerate the transition to a circular economy. Consumers and retailers will only make that transition if it is cost effective and simple. That's where we come in. Our technology platform enables the lease-and-reuse of consumer goods, which makes them more sustainable, accessible and affordable.",
                        },
                    },
                ],
            },
        ],
    },
})
