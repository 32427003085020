import { css } from "@emotion/react"
import { scaleValue } from "../../helpers/css"
import { Color as ReactorColor, Image } from "../../../../../../reactor"
import { Flex, FlexProps } from "../base/Flex"
import { Box } from "../base/Box"
import { Component } from "../../../../../../packages/editing/Component"
import { responsiveCss } from "../../helpers/css"
import { Heading } from "../typography/Heading"
import { Button } from "../buttons/Button"
import { server } from "../../../../../../server"
import { Color } from "../../theme"
import { ResponsiveImage } from "../base/ResponsiveImage"

export type PromoCardProps = {
    heading: string
    textColor?: Color
    cta: { text: string; url: string; buttonVariant?: "dark" | "light" }
    image?: Image
    backgroundColor?: ReactorColor
    soldOut?: boolean
}

export function PromoCard(props: PromoCardProps) {
    const p: Pick<
        FlexProps,
        "css" | "color" | "direction" | "justifyContent" | "borderRadius" | "style"
    > = {
        css: css(
            { height: 360 },
            responsiveCss("min", "sm", { height: scaleValue(440) }),
            responsiveCss("min", "md", { height: scaleValue(600) })
        ),
        color: props.textColor ?? "gray500",
        direction: "column",
        justifyContent: "space-between",
        borderRadius: "md",
        style: {
            backgroundColor: props.backgroundColor?.valueOf(),
            position: "relative",
            overflow: "clip",
            flexGrow: 0,
        },
    }

    return (
        <Flex
            {...p}
            {...(props.soldOut
                ? {}
                : { as: "a", href: props.cta.url, "aria-label": props.cta.text })}
        >
            <Box
                css={css({ padding: 32 }, responsiveCss("min", "md", { padding: 48 }))}
                style={{ zIndex: 1 }}
            >
                <Heading level={2} margin={{ bottom: 24 }}>
                    {props.heading}
                </Heading>
                <Button
                    variant={props.cta.buttonVariant ?? "dark"}
                    size="md"
                    disabled={props.soldOut}
                >
                    {props.cta.text}
                </Button>
            </Box>
            {props.image ? <ResponsiveImage image={props.image} width={640} /> : null}
        </Flex>
    )
}

Component(PromoCard, {
    name: "SeeAllCard",
    gallery: {
        path: "Cards/SeeAllCard",
        items: [
            {
                variants: [
                    {
                        props: {
                            heading: "Se hele vårt utvalg",
                            cta: { text: "Vis alt (14)", url: "", buttonVariant: "light" },
                            image: `${server()}/static/redoit/see-all-card.png` as any as Image,
                        },
                    },
                ],
            },
        ],
    },
})
