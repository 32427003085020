import { css, CSSObject, SerializedStyles } from "@emotion/react"

/**
 * A base reset that should be applied to all elements that is reset.
 */
const baseReset = css({
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: "100%",
    font: "inherit",
    verticalAlign: "baseline",
})

/**
 * Helper function that returns reset styles for a given HTML element, beyond the base reset
 * styles, but also removes default styles.
 *
 * @param element The HTML element to return reset styles for
 * @returns `SerializedStyles` to be used in an Emotion `css` prop
 */
export function resetStyles(element: keyof JSX.IntrinsicElements): SerializedStyles {
    switch (element) {
        case "button":
            return css(
                baseReset,
                css({
                    background: "none",
                    border: "none",
                    padding: 0,
                    font: "inherit",
                    cursor: "pointer",
                    outline: "inherit",
                })
            )
        case "input":
            return css(baseReset, {
                border: "none",
                background: "none",
                font: "inherit",
                outline: "none",
                webkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
            })
        case "ol":
        case "ul":
            return css(baseReset, css({ listStyle: "none" }))
        default:
            return baseReset
    }
}

export const underline = css({
    textDecoration: "underline",
    textDecorationSkipInk: "none",
    textUnderlineOffset: "20%",
})

export function retinaCss(styles: CSSObject): SerializedStyles {
    const mq = `@media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx)
    `
    return css({
        [mq]: styles,
    })
}

export function touchOnlyCss(styles: CSSObject): SerializedStyles {
    return css({
        "@media (hover: none)": styles,
    })
}

export function nonTouchCss(styles: CSSObject): SerializedStyles {
    return css({
        "@media (hover: hover)": styles,
    })
}

const cssVars = ["accent-color", "accent-color-light", "body-padding"] as const
export type CssVarName = (typeof cssVars)[number]

export function cssVarName(name: CssVarName) {
    return `--${name}`
}

export function cssVarValue(name: CssVarName) {
    return `var(--${name})`
}
