import { css } from "@emotion/react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { Section } from "../../../../packages/editing/Section"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Url } from "../../../../reactor"
import { Heading } from "../ui/components/typography/Heading"
import { responsiveCss } from "../ui/helpers/css"
import { ReviewsCarousel } from "../ui/components/carousel/ReviewsCarousel"
import { Flex } from "../ui/components/base/Flex"
import { Body } from "../ui/components/typography/Body"
import { Button } from "../ui/components/buttons/Button"
import { Icon } from "../ui/components/visual/Icon"
import { Box } from "../ui/components/base/Box"
import { useReviews } from "../client"
import { responsiveSectionSpacing } from "../ui/theme"

/**
 * @icon ui-message-heart-square
 */
function PromotedReviews(section: {
    /**
     * Heading for the reviews section.
     * @default '{"en": "Reviews"}'
     */
    heading: Localized<string>

    /**
     * Text to show below the heading.
     */
    text?: Localized<string>

    /**
     * Show button with a link to more reviews.
     */
    cta?: {
        text: Localized<string>
        url: Url
    }

    /**
     * How fast the carousel scrolls. Given in pixels per second.
     *
     * @default 30
     */
    carouselSpeed: number
}) {
    const localize = useLocalize()
    const reviews = useReviews()

    dayjs.extend(relativeTime)

    if (reviews.loading) return <></>

    return (
        <div
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                },
                responsiveSectionSpacing()
            )}
        >
            <Flex alignItems="center" justifyContent="space-between">
                <Heading level={2} padding={{ y: 8 }} truncate>
                    {localize(section.heading)}
                </Heading>
            </Flex>
            {section.text && (
                <Body size="lg" color="gray400" margin={{ bottom: "sm" }}>
                    {localize(section.text)}
                </Body>
            )}
            <div style={{ overflow: "hidden", width: "100vw", marginLeft: "calc(50% - 50vw)" }}>
                <ReviewsCarousel
                    reviews={
                        reviews.data?.reviews.map(({ id, rating, ...r }) => ({
                            id: id.valueOf(),
                            rating: rating.valueOf(),
                            name: localize(r.name),
                            title: localize(r.title),
                            text: localize(r.text),
                            timestamp:
                                (Date.now() - new Date(r.timestamp.valueOf()).valueOf()) /
                                    (60 * 60 * 24 * 1000) >
                                7
                                    ? dayjs(r.timestamp.valueOf()).format("DD.MM.YYYY")
                                    : dayjs(r.timestamp.valueOf()).fromNow(),
                        })) ?? []
                    }
                    speed={section.carouselSpeed}
                />
            </div>
            {section.cta ? (
                <Box margin={{ top: "sm" }}>
                    <Button
                        size="md"
                        variant="secondary"
                        iconEnd="arrowRight"
                        css={responsiveCss("max", "xs", { width: "100%" })}
                        as="a"
                        href={section.cta.url.valueOf()}
                    >
                        <div css={css(responsiveCss("max", "xs", { display: "none" }))}>
                            <Icon icon="trustpilot" margin={{ right: 8 }} />
                        </div>
                        {localize(section.cta.text)}
                    </Button>
                </Box>
            ) : null}
        </div>
    )
}

Section(PromotedReviews)
