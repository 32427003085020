import { Component } from "../../../../../../packages/editing/Component"
import { createHeading } from "../../../../../../packages/web-ui/components/typography/Heading"
import { RedoitTheme } from "../../theme"

export const Heading = createHeading<RedoitTheme>()

Component(Heading, {
    name: "Heading",
    gallery: {
        path: "Typography/Heading",
        about: "Using headers to organize and arrange designs in Figma is a great way to visually group components, while providing space for notes and context.",
        items: [
            { title: "Title 1", variants: [{ props: { level: 1, children: "Hello, world!" } }] },
            { title: "Title 2", variants: [{ props: { level: 2, children: "The new wave" } }] },
            {
                title: "Title 3",
                variants: [
                    { props: { level: 3, children: "We take on the complexities of leasing" } },
                ],
            },
        ],
    },
})
